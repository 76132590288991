import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import qs from 'qs';
import { IChemicalProduct } from '../../../../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IChemicalProductUpsert } from '../../../../@types/model/masterData/chemicals/chemicalProduct/chemicalProductUpsert';

export default class ChemicalProductHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataChemicalProductGetList() : AxiosPromise<Array<IChemicalProduct>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ChemicalProduct/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static chemicalProductUpsert = (chemicalProductUpsert : IChemicalProductUpsert, chemicalCategoryIds ?: Array<number>) : AxiosPromise<IChemicalProduct> => {
        return axios.post(`${API_URL}/MasterData/ChemicalProduct/Upsert`, chemicalProductUpsert, {
            params: { chemicalCategoryIds },
            paramsSerializer: qs.stringify,
        });
    }

    public static chemicalProductDelete = (chemicalProductId : number) : AxiosPromise<IChemicalProduct> => {
        return axios.delete(`${API_URL}/MasterData/ChemicalProduct/Delete`, {
            params: { chemicalProductId },
        });
    }
}