import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IPriorityUpsert } from '../../../@types/model/masterData/priority/priorityUpsert';
import { IPriority } from '../../../@types/model/masterData/priority/priority';

export default class PriorityHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataPriorityGetList() : AxiosPromise<Array<IPriority>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/Priority/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static priorityUpsert = (priorityUpsert : IPriorityUpsert) : AxiosPromise<IPriority> => {
        return axios.post(`${API_URL}/MasterData/Priority/Upsert`, priorityUpsert);
    }

    public static priorityDelete = (priorityId : number) : AxiosPromise<IPriority> => {
        return axios.delete(`${API_URL}/MasterData/Priority/Delete`, {
            params: { priorityId },
        });
    }
}