import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IActiveIngredient } from '../../../../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IActiveIngredientUpsert } from '../../../../@types/model/masterData/chemicals/activeIngredient/activeIngredientUpsert';

export default class ActiveIngredientHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataActiveIngredientGetList() : AxiosPromise<Array<IActiveIngredient>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ActiveIngredient/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static activeIngredientUpsert = (activeIngredientUpsert : IActiveIngredientUpsert) : AxiosPromise<IActiveIngredient> => {
        return axios.post(`${API_URL}/MasterData/ActiveIngredient/Upsert`, activeIngredientUpsert);
    }

    public static activeIngredientDelete = (activeIngredientId : number) : AxiosPromise<IActiveIngredient> => {
        return axios.delete(`${API_URL}/MasterData/ActiveIngredient/Delete`, {
            params: { activeIngredientId },
        });
    }
}