import * as Yup from 'yup';
import { YupSchema } from '../../yupHelper';
import { IFreshServiceTicketFormValues } from './freshServiceTicketFormValues';
import { IFreshServiceTicketUpsert } from './freshServiceTicketUpsert';
import { FreshServicePriority, FreshServiceStatus } from '../../../appConstants';
import { IUser } from '../auth/user/user';

type YupShape = Record<keyof IFreshServiceTicketFormValues, YupSchema>

export default class FreshServiceTicketModelHelper {
    public static createFormValues = (location : string, currentUser ?: IUser | null, status ?: number, priority ?: number) : IFreshServiceTicketFormValues => {
        return {
            name: currentUser ? currentUser.name : '',
            email: currentUser ? currentUser.email : '',
            subject: location,
            description: '',
            priority: priority ?? FreshServicePriority.Low,
            status: status ?? FreshServiceStatus.Open,
        };
    };

    public static createUpsert = (freshServiceTicketFormValues : IFreshServiceTicketFormValues) : IFreshServiceTicketUpsert => {
        return {
            name: freshServiceTicketFormValues.name,
            email: freshServiceTicketFormValues.email,
            subject: freshServiceTicketFormValues.subject,
            description: freshServiceTicketFormValues.description,
            status: freshServiceTicketFormValues.status,
            priority: freshServiceTicketFormValues.priority,
        };
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = () : Yup.ObjectSchema<YupShape> => Yup.object<YupShape>({
        subject: Yup.string().required('required'),
        description: Yup.string().required('Cannot be blank'),
        name: Yup.string().required('required'),
        email: Yup.string().required('required'),
        status: Yup.number().nullable(),
        priority: Yup.number().nullable(),
    });
}
