import { openDb, UpgradeDB } from 'idb';
import { IUserData } from '../@types/model/auth/user/userData';
import { IUserToken } from '../@types/model/auth/userToken/userToken';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IDivision } from '../@types/model/masterData/division/division';
import { IField } from '../@types/model/masterData/field/field';
import { IProductionUnit } from '../@types/model/masterData/productionUnit/productionUnit';
import { ISubdivision } from '../@types/model/masterData/subdivision/subdivision';
import moment from 'moment';
import {
    ACTIVE_INGREDIENT_TABLE_NAME,
    ACTIVITY_TABLE_NAME,
    ACTIVITY_TYPE_TABLE_NAME,
    BLOCK_TABLE_NAME,
    CHEMICAL_CATEGORY_TABLE_NAME,
    CHEMICAL_ELEMENT_TABLE_NAME,
    CHEMICAL_FORMULATION_TABLE_NAME,
    CHEMICAL_PRODUCT_TABLE_NAME,
    COMMODITY_TABLE_NAME,
    DEPARTMENT_TABLE_NAME,
    DIVISION_TABLE_NAME,
    FIELD_TABLE_NAME,
    NURSERY_TABLE_NAME,
    PLANTS_PER_HECTARE_TABLE_NAME,
    PRODUCTION_UNIT_TABLE_NAME,
    PROJECT_TABLE_NAME,
    QC_CONFIG_TABLE_NAME,
    QC_PROCESS_TABLE_NAME,
    QC_QUALIFIER_TABLE_NAME,
    QC_QUALIFIER_TYPE_TABLE_NAME,
    ROOT_STOCK_TABLE_NAME,
    SAFETY_PRECAUTION_PPE_TABLE_NAME,
    SAFETY_PRECAUTION_TABLE_NAME,
    SEED_TRAY_SIZE_TABLE_NAME,
    SPRAY_METHOD_TABLE_NAME,
    SUBDIVISION_TABLE_NAME,
    SYSTEM_TABLE_NAME,
    PRIORITY_TABLE_NAME,
    UNIT_OF_MEASURE_TABLE_NAME,
    VARIETY_TABLE_NAME } from './masterDataSyncService';
import { IBlock } from '../@types/model/masterData/block/block';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { INursery } from '../@types/model/masterData/nursery/nursery';
import { IRootStock } from '../@types/model/masterData/rootStock/rootStock';
import { IProject } from '../@types/model/masterData/project/project';
import { IPlantsPerHectare } from '../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import { ISafetyPrecaution } from '../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from '../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ISeedTraySize } from '../@types/model/masterData/seedTraySize/seedTraySize';
import { ISprayMethod } from '../@types/model/masterData/sprayMethod/splayMethod';
import { IUnitOfMeasure } from '../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IActiveIngredient } from '../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from '../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from '../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from '../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from '../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IConfig } from '../@types/model/qcMasterData/config/config';
import { IProcess } from '../@types/model/qcMasterData/process/process';
import { IQualifier } from '../@types/model/qcMasterData/qualifier/qualifier';
import { IQualifierType } from '../@types/model/qcMasterData/qualifierType/qualifierType';
import { IActivity } from '../@types/model/masterData/activity/activity';
import { ISystem } from '../@types/model/masterData/system/system';
import { IPriority } from '../@types/model/masterData/priority/priority';
import { IActivityType } from '../@types/model/masterData/activityType/activityType';
import { IOptionType } from '@zz2/zz2-ui';

const SESSION_NAME = 'zz2-farm-session';
const SESSION_KEY = 'zz2-farm-session-token';
const USER_DATA_NAME = 'zz2-farm-user-data';
const USER_DATA_KEY = 'zz2-farm-user-data-key';

const MASTER_DATA_LAST_SYNC_DATE = 'farm-master-data-last-sync-date';

const SELECTED_USER_DIVISIONS_KEY = 'selected-user-divisions';
const SELECTED_USER_DEPARTMENTS_KEY = 'selected-user-departments';

let sessionCallback : (userToken : IUserToken | null) => void;
let userDataCallback : (userData : IUserData | null) => void;

export async function getLocalStorageSession() : Promise<IUserToken | null> {
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        session = await getSessionIndexedDB();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        session = getSessionLocalStorage();
    }

    if (session) return session;

    return null;
}

export async function getLocalStorageUserData() : Promise<IUserData | null> {
    let userData : IUserData | null = null;
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        userData = await getUserDataIndexedDB();
        session = await getSessionIndexedDB();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        userData = getUserDataLocalStorage();
        session = getSessionLocalStorage();
    }

    if (userData?.userId === session?.userId) return userData;

    return null;
}

export async function setLocalStorageSession(userToken : IUserToken | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setSessionIndexedDB(userToken);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setSessionLocalStorage(userToken);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sessionCallback) {
        sessionCallback(userToken);
    }
}

export async function setLocalStorageUserData(userData : IUserData | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setUserDataIndexedDB(userData);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setUserDataLocalStorage(userData);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (userDataCallback) {
        userDataCallback(userData);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) : void {
    if (userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function setUserDataLocalStorage(userData : IUserData | null) : void {
    if (userData) {
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    } else {
        localStorage.removeItem(USER_DATA_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) return JSON.parse(session);

    return null;
}

function getUserDataLocalStorage() : IUserData | null {
    const userData = localStorage.getItem(USER_DATA_KEY);

    if (userData) return JSON.parse(userData);

    return null;
}

/**
 * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
 * 0, 1 and 2.
 * @param db
 */
export function upgradeDb(db : UpgradeDB) : void {
    if (!db.objectStoreNames.contains(SESSION_NAME)) {
        db.createObjectStore<IUserToken, string>(SESSION_NAME);
    }
    if (!db.objectStoreNames.contains(USER_DATA_NAME)) {
        db.createObjectStore<IUserData, string>(USER_DATA_NAME);
    }
    if (!db.objectStoreNames.contains(DIVISION_TABLE_NAME)) {
        db.createObjectStore<IDivision, string>(DIVISION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SUBDIVISION_TABLE_NAME)) {
        db.createObjectStore<ISubdivision, string>(SUBDIVISION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(DEPARTMENT_TABLE_NAME)) {
        db.createObjectStore<IDepartment, string>(DEPARTMENT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PRODUCTION_UNIT_TABLE_NAME)) {
        db.createObjectStore<IProductionUnit, string>(PRODUCTION_UNIT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(FIELD_TABLE_NAME)) {
        db.createObjectStore<IField, string>(FIELD_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(BLOCK_TABLE_NAME)) {
        db.createObjectStore<IBlock, string>(BLOCK_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(COMMODITY_TABLE_NAME)) {
        db.createObjectStore<ICommodity, string>(COMMODITY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(VARIETY_TABLE_NAME)) {
        db.createObjectStore<IVariety, string>(VARIETY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(NURSERY_TABLE_NAME)) {
        db.createObjectStore<INursery, string>(NURSERY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(ROOT_STOCK_TABLE_NAME)) {
        db.createObjectStore<IRootStock, string>(ROOT_STOCK_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PROJECT_TABLE_NAME)) {
        db.createObjectStore<IProject, string>(PROJECT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PLANTS_PER_HECTARE_TABLE_NAME)) {
        db.createObjectStore<IPlantsPerHectare, string>(PLANTS_PER_HECTARE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SAFETY_PRECAUTION_TABLE_NAME)) {
        db.createObjectStore<ISafetyPrecaution, string>(SAFETY_PRECAUTION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SAFETY_PRECAUTION_PPE_TABLE_NAME)) {
        db.createObjectStore<ISafetyPrecautionPpe, string>(SAFETY_PRECAUTION_PPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SEED_TRAY_SIZE_TABLE_NAME)) {
        db.createObjectStore<ISeedTraySize, string>(SEED_TRAY_SIZE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SPRAY_METHOD_TABLE_NAME)) {
        db.createObjectStore<ISprayMethod, string>(SPRAY_METHOD_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(UNIT_OF_MEASURE_TABLE_NAME)) {
        db.createObjectStore<IUnitOfMeasure, string>(UNIT_OF_MEASURE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(ACTIVE_INGREDIENT_TABLE_NAME)) {
        db.createObjectStore<IActiveIngredient, string>(ACTIVE_INGREDIENT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(CHEMICAL_CATEGORY_TABLE_NAME)) {
        db.createObjectStore<IChemicalCategory, string>(CHEMICAL_CATEGORY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(CHEMICAL_ELEMENT_TABLE_NAME)) {
        db.createObjectStore<IChemicalElement, string>(CHEMICAL_ELEMENT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(CHEMICAL_FORMULATION_TABLE_NAME)) {
        db.createObjectStore<IChemicalFormulation, string>(CHEMICAL_FORMULATION_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(CHEMICAL_PRODUCT_TABLE_NAME)) {
        db.createObjectStore<IChemicalProduct, string>(CHEMICAL_PRODUCT_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(QC_CONFIG_TABLE_NAME)) {
        db.createObjectStore<IConfig, string>(QC_CONFIG_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(QC_PROCESS_TABLE_NAME)) {
        db.createObjectStore<IProcess, string>(QC_PROCESS_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(QC_QUALIFIER_TABLE_NAME)) {
        db.createObjectStore<IQualifier, string>(QC_QUALIFIER_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(QC_QUALIFIER_TYPE_TABLE_NAME)) {
        db.createObjectStore<IQualifierType, string>(QC_QUALIFIER_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(ACTIVITY_TYPE_TABLE_NAME)) {
        db.createObjectStore<IActivityType, string>(ACTIVITY_TYPE_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(ACTIVITY_TABLE_NAME)) {
        db.createObjectStore<IActivity, string>(ACTIVITY_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(SYSTEM_TABLE_NAME)) {
        db.createObjectStore<ISystem, string>(SYSTEM_TABLE_NAME);
    }
    if (!db.objectStoreNames.contains(PRIORITY_TABLE_NAME)) {
        db.createObjectStore<IPriority, string>(PRIORITY_TABLE_NAME);
    }
}

/**
 * Sets the auth session. If no session is specified, deletes the existing entry.
 * @param userToken The session.
 */
async function setSessionIndexedDB(userToken : IUserToken | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readwrite');

    const store = tx.objectStore(SESSION_NAME);

    await store.delete(SESSION_KEY);
    if (userToken) {
        await store.add(userToken, SESSION_KEY);
    }
    await tx.complete;
}

/**
 * Sets the user data. If no user data is specified, deletes the existing entry.
 * @param userData The user data.
 */
async function setUserDataIndexedDB(userData : IUserData | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(USER_DATA_NAME, 'readwrite');

    const store = tx.objectStore(USER_DATA_NAME);

    await store.delete(USER_DATA_KEY);
    if (userData) {
        await store.add(userData, USER_DATA_KEY);
    }
    await tx.complete;
}

/**
 * Opens the DB and retrieves the current auth session.
 */
async function getSessionIndexedDB() : Promise<IUserToken> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readonly');

    const result = tx.objectStore<IUserToken>(SESSION_NAME).get(SESSION_KEY);

    await tx.complete;

    return result;
}

/**
 * Opens the DB and retrieves the current user data.
 */
async function getUserDataIndexedDB() : Promise<IUserData> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(USER_DATA_NAME, 'readonly');

    const result = tx.objectStore<IUserData>(USER_DATA_NAME).get(USER_DATA_KEY);

    await tx.complete;

    return result;
}

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 * @param callback
 */
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) : Promise<void> {
    sessionCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBSessionChange();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const session = getSessionLocalStorage();
        sessionCallback(session);
    }
}

/**
 * Specifies the callback that will be fired whenever the user data undergoes a change.
 * @param callback
 */
export async function onUserDataChanged(callback : (userData : IUserData | null) => void) : Promise<void> {
    userDataCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBUserDataChange();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const userData = getUserDataLocalStorage();
        userDataCallback(userData);
    }
}

/**
 * Retrieves auth session, and once done fires the session callback.
 */
function indexedDBSessionChange() : void {
    getSessionIndexedDB().then((res) => {
        sessionCallback(res);
    }, () => {
        sessionCallback(null);
    });
}

/**
 * Retrieves user data, and once done fires the user data callback.
 */
function indexedDBUserDataChange() : void {
    getUserDataIndexedDB().then((res) => {
        userDataCallback(res);
    }, () => {
        userDataCallback(null);
    });
}

///////////////////

/**
 * Stores master data last sync date
 */
export function setMasterDataLastSyncDateLocalStorage(lastSyncDate ?: moment.Moment) : void {
    if (lastSyncDate) {
        localStorage.setItem(MASTER_DATA_LAST_SYNC_DATE, JSON.stringify(lastSyncDate));
    } else {
        localStorage.removeItem(MASTER_DATA_LAST_SYNC_DATE);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getMasterDataLastSyncDateLocalStorage() : moment.Moment | null {
    const lastSyncDate = localStorage.getItem(MASTER_DATA_LAST_SYNC_DATE);

    if (lastSyncDate) return JSON.parse(lastSyncDate);

    return null;
}

/**
 * Stores User Selected Division Ids
 */
export function setUserSelectedDivisionsLocalStorage(divisionIds ?: Array<IOptionType>) : void {
    if (divisionIds) {
        localStorage.setItem(SELECTED_USER_DIVISIONS_KEY, JSON.stringify(divisionIds));
    } else {
        localStorage.removeItem(SELECTED_USER_DIVISIONS_KEY);
    }
}
/**
 * Retrieves User Selected Division Ids
 */
export function getUserSelectedDivisionsLocalStorage() : Array<IOptionType> {
    const divisionIds = localStorage.getItem(SELECTED_USER_DIVISIONS_KEY);

    if (divisionIds) return JSON.parse(divisionIds);

    return [];
}

/**
 * Stores User Selected Department Ids
 */
export function setUserSelectedDepartmentsLocalStorage(departmentIds ?: Array<IOptionType>) : void {
    if (departmentIds) {
        localStorage.setItem(SELECTED_USER_DEPARTMENTS_KEY, JSON.stringify(departmentIds));
    } else {
        localStorage.removeItem(SELECTED_USER_DEPARTMENTS_KEY);
    }
}

/**
 * Retrieves User Selected Department Ids
 */
export function getUserSelectedDepartmentsLocalStorage() : Array<IOptionType> {
    const departmentIds = localStorage.getItem(SELECTED_USER_DEPARTMENTS_KEY);

    if (departmentIds) return JSON.parse(departmentIds);

    return [];
}
