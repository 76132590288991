import { OptionsObject } from 'notistack';
import { IAuthState } from '../store/auth/reducer';
import { IGeneralState } from '../store/general/reducer';
import { IDataState } from '../store/data/reducer';
import { ICropState } from '../store/crops/reducer';
import { ISensorState } from '../store/sensor/reducer';
import { IRightState } from '../store/right/reducer';
import { IQcMasterDataState } from '../store/qcMasterData/reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IIrrigationCycleTaskState } from '../store/transaction/irrigationCycleTask/reducer';
import { IFrequentlyAskedQuestionState } from '../store/frequentlyAskedQuestions/reducer';
import { Draft } from '@reduxjs/toolkit';
import store, { createRootReducer } from '../store';
import { IFreshServiceState } from '../store/freshService/reducer';
import { ILogState } from '../store/log/reducer';
import { IPackmanState } from '../store/packman/reducer';
import { ISyncState } from '../store/syncing/reducer';

export type DispatchCall<P> = (payload : P) => void;
export type DispatchCallEmpty = () => void;

export interface INotification {
    message : string | React.ReactNode;
    options ?: Draft<OptionsObject>;
}

export interface ISnackbarNotification extends INotification {
    key : number;
}

export type RootState = ReturnType<typeof createRootReducer>;

export interface IRootState {
    auth : IAuthState;
    general : IGeneralState;
    data : IDataState;
    crop : ICropState;
    qcMasterData : IQcMasterDataState;
    right : IRightState;
    sensor : ISensorState;
    transaction : IIrrigationCycleTaskState;
    frequentlyAskedQuestions : IFrequentlyAskedQuestionState;
    freshService : IFreshServiceState;
    log : ILogState;
    packman : IPackmanState;
    sync : ISyncState;
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector : TypedUseSelectorHook<RootState> = useSelector;

export type ThunkApi = {
    dispatch : AppDispatch;
    state : RootState;
};
