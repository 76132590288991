export enum RIGHT_GRANTS {
    View = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
}

export const SIKLUS_LIST : Array<{ label : string; value : number }> = [
    { label: 'Siklus 1', value: 1 },
    { label: 'Siklus 2', value: 2 },
    { label: 'Siklus 3', value: 3 },
    { label: 'Siklus 4', value: 4 },
    { label: 'Siklus 5', value: 5 },
    { label: 'Siklus 6', value: 6 },
];

// Always make sure that order is correct
export const QUALIFIER_DATA_TYPES : {[key : number] : string} = {
    1: 'YesNo',
    2: 'Int',
    3: 'Double',
    4: 'String',
    5: 'Photo',
    6: 'Signature',
    7: 'DateTime',
};

export const FARM_MAP_CENTER_POSITION : { lat : number; long : number } = {
    lat: -23.5792,
    long: 30.1424,
};

export const RIGHT_GRANT_LEVELS : Array<{ label : string; value : number }> = [
    { label: 'VIEW', value: 0 },
    { label: 'ADD', value: 1 },
    { label: 'EDIT', value: 2 },
    { label: 'DELETE', value: 3 },
];

/*ZZFresh service */

export enum FreshServicePriority {
    Na = 0,
    Low,
    Medium,
    High,
    Urgent,
}

export enum FreshServiceSourceType {
    Na = 0,
    Email,
    Portal,
    Phone,
    Chat,
    FeedbackWidget,
    Yammer,
    AwasCloudwatch,
    PageDuty,
    Walkup,
    Slack,
}

export enum FreshServiceStatus {
    Na = 1,
    Open,
    Pending,
    Resolved,
    Closed,
}

// Always use white space betweet date and time.
export const DATE_FORMAT_DEFAULT_NO_SECONDS = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const DATE_FORMAT_NO_SECONDS = 'YYYYMMDD HH:mm';
