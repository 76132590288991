import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ISubdivision } from '../../../@types/model/masterData/subdivision/subdivision';
import { ISubdivisionUpsert } from '../../../@types/model/masterData/subdivision/subdivisionUpsert';
import qs from 'qs';

export default class SubdivisionHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataSubdivisionByDivisionGetList(divisionIds : Array<number>) : AxiosPromise<Array<ISubdivision>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/Subdivision/GetList`, {
            params: { divisionIds },
            paramsSerializer: qs.stringify,
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static subdivisionUpsert = (subdivisionUpsert : ISubdivisionUpsert) : AxiosPromise<ISubdivision> => {
        return axios.post(`${API_URL}/MasterData/Subdivision/Upsert`, subdivisionUpsert);
    }

    public static subdivisionDelete = (subdivisionId : number) : AxiosPromise<ISubdivision> => {
        return axios.delete(`${API_URL}/MasterData/Subdivision/Delete`, {
            params: { subdivisionId },
        });
    }
}