import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import AuthHttpService from '../../../service/http/authHttpService';
import * as localStorageService from '../../../service/localStorageService';
import GeneralThunk from '../../../store/general/thunk';
import { useAppDispatch } from '../../../@types/redux';

interface IEmployeeNrDialogProps {
    open : boolean;
}

const EmployeeNrDialog = (props : IEmployeeNrDialogProps) : React.ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [employeeNr, setEmployeeNr] = useState<string>('');
    const dispatch = useAppDispatch();

    const handleChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmployeeNr(event.currentTarget.value.toLocaleUpperCase());
    };

    const handleRefresh = async () => {
        setIsLoading(true);

        const result = await AuthHttpService.getSession();
        localStorageService.setLocalStorageSession(result.data);

        setIsLoading(false);
    };

    const handleClose = async () => {
        setIsLoading(true);
        try {
            const result = await AuthHttpService.setEmployeeNr(employeeNr);
            localStorageService.setLocalStorageSession(result.data);
            dispatch(GeneralThunk.showSuccessSnackbar('Employee number has been set.'));
        } catch (ex) {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Invalid employee number.', e: ex }));
        }
        setIsLoading(false);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby='employee-dialog-title'
            >
                <DialogTitle id='employee-dialog-title'>Enter Employee Number</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your Employee Number.
                    </DialogContentText>
                    <FormControl>
                        <TextField
                            required
                            error={!employeeNr}
                            autoFocus
                            margin='dense'
                            id='employeeNr'
                            label='Employee Number'
                            type='text'
                            fullWidth
                            value={employeeNr}
                            onChange={handleChange}
                        />
                        {
                            !employeeNr &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={handleRefresh}>
                        {isLoading && <RefreshIcon className={'mr10'}></RefreshIcon>}
                        {isLoading && <CircularProgress color='primary' size={24} className={'mr10'}></CircularProgress>}
                        REFRESH
                    </Button>
                    <Button color='primary' disabled={isLoading} onClick={handleClose}>
                        {isLoading && <SaveIcon className={'mr10'}></SaveIcon>}
                        {isLoading && <CircularProgress color='primary' size={24} className={'mr10'}></CircularProgress>}
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EmployeeNrDialog;
