import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';

export default class AuthHttpService {
    public static logIn = (code : string) : AxiosPromise<IUserToken> => {
        return axios.get(`${API_URL}/Authorisation/GoogleAuth`, {
            params: {
                code,
            },
        });
    }

    public static logInManual = (emailOrUsername : string, password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Authorisation/LogIn`, {
            emailOrUsername,
            password,
        });
    }

    public static getSession = () : AxiosPromise<IUserToken> => {
        return axios.get(`${API_URL}/Authorisation/GetSession`);
    }

    public static setEmployeeNr = (employeeNr : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Rights/User/SetEmployeeNr`, null, {
            params: {
                employeeNr,
            },
        });
    }

    public static setPassword = (password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Rights/User/SetPassword`, null, {
            params: {
                password,
            },
        });
    }

    public static logout = () : AxiosPromise => {
        return axios.get(`${API_URL}/Authorisation/LogOut`);
    }

    public static logoutUsers = (userIds : Array<number>) : AxiosPromise => {
        return axios.post(`${API_URL}/Authorisation/LogoutUsers`, userIds);
    }

    public static requestForgottenPasswordEmail(emailOrEmpNumber : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/Rights/User/RequestForgottenPasswordEmail`, null, {
            params: {
                emailOrEmpNumber 
            }
        });
    }

    public static resetPassword(recoveryCode : string, email : string, newPassword : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/Rights/User/ResetPassword`, {
            recoveryCode,
            newPassword,
            email,
        });
    }
}
