import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IProject } from '../../../@types/model/masterData/project/project';
import { IProjectUpsert } from '../../../@types/model/masterData/project/projectUpsert';
import { IProjectBlockBridgeUpsert } from '../../../@types/model/masterData/project/projectBlockBridgeUpsert';

export default class ProjectHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataProjectGetList() : AxiosPromise<Array<IProject>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/Project/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static projectUpsert = (projectUpsert : IProjectUpsert) : AxiosPromise<IProject> => {
        return axios.post(`${API_URL}/MasterData/Project/Upsert`, projectUpsert);
    };

    public static projectBlockBridgeUpsert = (projectBlockBridgeUpsert : IProjectBlockBridgeUpsert) : AxiosPromise<IProject> => {
        return axios.post(`${API_URL}/MasterData/Project/UpsertProjectBlockBridge`, projectBlockBridgeUpsert);
    };

    public static projectDelete = (projectId : number) : AxiosPromise<IProject> => {
        return axios.delete(`${API_URL}/MasterData/Project/Delete`, {
            params: { projectId },
        });
    };
}