import { createReducer } from '@reduxjs/toolkit';
import { IConfig } from '../../@types/model/qcMasterData/config/config';
import { IProcess } from '../../@types/model/qcMasterData/process/process';
import { IQcAction } from '../../@types/model/qcMasterData/qcAction/qcAction';
import { IQcSite } from '../../@types/model/qcMasterData/qcSites/qcSite';
import { IQualifier } from '../../@types/model/qcMasterData/qualifier/qualifier';
import { IQualifierType } from '../../@types/model/qcMasterData/qualifierType/qualifierType';
import QcMasterDataActions from './actions';

export interface IQcMasterDataState {
    isLoading : boolean;
    configData : Array<IConfig> | null;
    qualifierTypeData : Array<IQualifierType> | null;
    qualifierData : Array<IQualifier> | null;
    processData : Array<IProcess> | null;
    qcActionData : Array<IQcAction> | null;
    qcSiteData : Array<IQcSite> | null;

    selectedConfig : IConfig | null;
    selectedProcess : IProcess | null;
}

const initialState = {
    isLoading: false,
    configData: null,
    qualifierTypeData: null,
    qualifierData: null,
    processData: null,
    qcActionData: null,
    qcSiteData: null,

    selectedConfig: null,
    selectedProcess: null,
};

const qcMasterDataReducer = createReducer<IQcMasterDataState>(initialState, (builder) => {
    builder.addCase(QcMasterDataActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(QcMasterDataActions.setConfigData, (state, action) => {
        state.configData = action.payload;
    });
    builder.addCase(QcMasterDataActions.setProcessData, (state, action) => {
        state.processData = action.payload;
    });
    builder.addCase(QcMasterDataActions.setQualifierTypeData, (state, action) => {
        state.qualifierTypeData = action.payload;
    });
    builder.addCase(QcMasterDataActions.setQualifierData, (state, action) => {
        state.qualifierData = action.payload;
    });
    builder.addCase(QcMasterDataActions.setQcSiteData, (state, action) => {
        state.qcSiteData = action.payload;
    });
    builder.addCase(QcMasterDataActions.setQcActionData, (state, action) => {
        state.qcActionData = action.payload;
    });
    builder.addCase(QcMasterDataActions.setSelectedConfig, (state, action) => {
        state.selectedConfig = action.payload;
    });
    builder.addCase(QcMasterDataActions.setSelectedProcess, (state, action) => {
        state.selectedProcess = action.payload;
    });
    builder.addCase(QcMasterDataActions.setSyncedQCMasterData, (state, action) => {
        state.configData = action.payload.configs;
        state.processData = action.payload.processes;
        state.qualifierData = action.payload.qualifiers;
        state.qualifierTypeData = action.payload.qualifierTypes;
    });
    builder.addCase(QcMasterDataActions.reset, () => {
        return initialState;
    });
});

export default qcMasterDataReducer;
