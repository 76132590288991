import { openDb } from 'idb';
import { upgradeDb } from './localStorageService';
import { IMasterDataSync } from '../@types/model/sync/masterDataSync';
import { IDivision } from '../@types/model/masterData/division/division';
import { ISubdivision } from '../@types/model/masterData/subdivision/subdivision';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IProductionUnit } from '../@types/model/masterData/productionUnit/productionUnit';
import { IField } from '../@types/model/masterData/field/field';
import { IBlock } from '../@types/model/masterData/block/block';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { INursery } from '../@types/model/masterData/nursery/nursery';
import { IRootStock } from '../@types/model/masterData/rootStock/rootStock';
import { IProject } from '../@types/model/masterData/project/project';
import { IPlantsPerHectare } from '../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import { ISafetyPrecaution } from '../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from '../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ISeedTraySize } from '../@types/model/masterData/seedTraySize/seedTraySize';
import { ISprayMethod } from '../@types/model/masterData/sprayMethod/splayMethod';
import { IUnitOfMeasure } from '../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IActiveIngredient } from '../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from '../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from '../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from '../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from '../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IConfig } from '../@types/model/qcMasterData/config/config';
import { IProcess } from '../@types/model/qcMasterData/process/process';
import { IQualifier } from '../@types/model/qcMasterData/qualifier/qualifier';
import { IQualifierType } from '../@types/model/qcMasterData/qualifierType/qualifierType';
import { IActivity } from '../@types/model/masterData/activity/activity';
import { ISystem } from '../@types/model/masterData/system/system';
import { IActivityType } from '../@types/model/masterData/activityType/activityType';
import { IPriority } from '../@types/model/masterData/priority/priority';

// IndexedDB Master Data Object Store(table) names
export const DIVISION_TABLE_NAME = 'division-table';
export const SUBDIVISION_TABLE_NAME = 'subdivision-table';
export const DEPARTMENT_TABLE_NAME = 'department-table';
export const PRODUCTION_UNIT_TABLE_NAME = 'production-unit-table';
export const FIELD_TABLE_NAME = 'field-table';
export const BLOCK_TABLE_NAME = 'block-table';
export const COMMODITY_TABLE_NAME = 'commodity-table';
export const VARIETY_TABLE_NAME = 'variety-table';
export const NURSERY_TABLE_NAME = 'nursery-table';
export const ROOT_STOCK_TABLE_NAME = 'root-stock-table';
export const PROJECT_TABLE_NAME = 'project-table';
export const PLANTS_PER_HECTARE_TABLE_NAME = 'plants-per-hectare-table';
export const SAFETY_PRECAUTION_TABLE_NAME = 'safety-precaution-table';
export const SAFETY_PRECAUTION_PPE_TABLE_NAME = 'safety-precaution-ppe-table';
export const SEED_TRAY_SIZE_TABLE_NAME = 'seed-tray-size-table';
export const SPRAY_METHOD_TABLE_NAME = 'spray-method-table';
export const UNIT_OF_MEASURE_TABLE_NAME = 'unit-of-measure-table';
export const ACTIVE_INGREDIENT_TABLE_NAME = 'active-ingredient-table';
export const CHEMICAL_CATEGORY_TABLE_NAME = 'chemical-category-table';
export const CHEMICAL_ELEMENT_TABLE_NAME = 'chemical-element-table';
export const CHEMICAL_FORMULATION_TABLE_NAME = 'chemical-formulation-table';
export const CHEMICAL_PRODUCT_TABLE_NAME = 'chemical-product-table';
export const ACTIVITY_TYPE_TABLE_NAME = 'activity-type-table';
export const ACTIVITY_TABLE_NAME = 'activity-table';
export const SYSTEM_TABLE_NAME = 'system-table';
export const PRIORITY_TABLE_NAME = 'priority-table';

export const QC_CONFIG_TABLE_NAME = 'qc-config-table';
export const QC_PROCESS_TABLE_NAME = 'qc-process-table';
export const QC_QUALIFIER_TABLE_NAME = 'qc-qualifier-table';
export const QC_QUALIFIER_TYPE_TABLE_NAME = 'qc-qualifier-type-table';

/* ****************************************************************************** */
/* Start of Master Data IndexedDB storage */

export async function clearIndexedDBObjectStores() : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    // Gets transaction containing all master data object stores
    const tx = db.transaction([
        DIVISION_TABLE_NAME,
        SUBDIVISION_TABLE_NAME,
        DEPARTMENT_TABLE_NAME,
        PRODUCTION_UNIT_TABLE_NAME,
        FIELD_TABLE_NAME,
        BLOCK_TABLE_NAME,
        COMMODITY_TABLE_NAME,
        VARIETY_TABLE_NAME,
        NURSERY_TABLE_NAME,
        ROOT_STOCK_TABLE_NAME,
        PROJECT_TABLE_NAME,
        PLANTS_PER_HECTARE_TABLE_NAME,
        SAFETY_PRECAUTION_TABLE_NAME,
        SAFETY_PRECAUTION_PPE_TABLE_NAME,
        SEED_TRAY_SIZE_TABLE_NAME,
        SPRAY_METHOD_TABLE_NAME,
        UNIT_OF_MEASURE_TABLE_NAME,
        ACTIVE_INGREDIENT_TABLE_NAME,
        CHEMICAL_CATEGORY_TABLE_NAME,
        CHEMICAL_ELEMENT_TABLE_NAME,
        CHEMICAL_FORMULATION_TABLE_NAME,
        CHEMICAL_PRODUCT_TABLE_NAME,
        ACTIVITY_TYPE_TABLE_NAME,
        ACTIVITY_TABLE_NAME,
        SYSTEM_TABLE_NAME,
        PRIORITY_TABLE_NAME,
        QC_CONFIG_TABLE_NAME,
        QC_PROCESS_TABLE_NAME,
        QC_QUALIFIER_TABLE_NAME,
        QC_QUALIFIER_TYPE_TABLE_NAME,
    ], 'readwrite');

    // Gets each object store from the above transaction
    const divisionStore = tx.objectStore(DIVISION_TABLE_NAME);
    const subdivisionStore = tx.objectStore(SUBDIVISION_TABLE_NAME);
    const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
    const productionUnitStore = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    const fieldStore = tx.objectStore(FIELD_TABLE_NAME);
    const blockStore = tx.objectStore(BLOCK_TABLE_NAME);
    const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
    const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
    const nurseryStore = tx.objectStore(NURSERY_TABLE_NAME);
    const rootStockStore = tx.objectStore(ROOT_STOCK_TABLE_NAME);
    const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
    const plantsPerHectareStore = tx.objectStore(PLANTS_PER_HECTARE_TABLE_NAME);
    const safetyPrecautionStore = tx.objectStore(SAFETY_PRECAUTION_TABLE_NAME);
    const safetyPrecautionPpeStore = tx.objectStore(SAFETY_PRECAUTION_PPE_TABLE_NAME);
    const seedTraySizeStore = tx.objectStore(SEED_TRAY_SIZE_TABLE_NAME);
    const SprayMethodStore = tx.objectStore(SPRAY_METHOD_TABLE_NAME);
    const unitOfMeasureStore = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);
    const activeIngredientStore = tx.objectStore(ACTIVE_INGREDIENT_TABLE_NAME);
    const chemicalCategoryStore = tx.objectStore(CHEMICAL_CATEGORY_TABLE_NAME);
    const chemicalElementStore = tx.objectStore(CHEMICAL_ELEMENT_TABLE_NAME);
    const chemicalFormulationStore = tx.objectStore(CHEMICAL_FORMULATION_TABLE_NAME);
    const chemicalProductStore = tx.objectStore(CHEMICAL_PRODUCT_TABLE_NAME);
    const activityTypeStore = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);
    const activityStore = tx.objectStore(ACTIVITY_TABLE_NAME);
    const systemStore = tx.objectStore(SYSTEM_TABLE_NAME);
    const priorityStore = tx.objectStore(PRIORITY_TABLE_NAME);
    const qcConfigStore = tx.objectStore(QC_CONFIG_TABLE_NAME);
    const qcProcessStore = tx.objectStore(QC_PROCESS_TABLE_NAME);
    const qcQualifierStore = tx.objectStore(QC_QUALIFIER_TABLE_NAME);
    const qcQualifierTypeStore = tx.objectStore(QC_QUALIFIER_TYPE_TABLE_NAME);

    await Promise.all([
        divisionStore.clear(),
        subdivisionStore.clear(),
        departmentStore.clear(),
        productionUnitStore.clear(),
        fieldStore.clear(),
        blockStore.clear(),
        commodityStore.clear(),
        varietyStore.clear(),
        nurseryStore.clear(),
        rootStockStore.clear(),
        projectStore.clear(),
        plantsPerHectareStore.clear(),
        safetyPrecautionStore.clear(),
        safetyPrecautionPpeStore.clear(),
        seedTraySizeStore.clear(),
        SprayMethodStore.clear(),
        unitOfMeasureStore.clear(),
        activeIngredientStore.clear(),
        chemicalCategoryStore.clear(),
        chemicalElementStore.clear(),
        chemicalFormulationStore.clear(),
        chemicalProductStore.clear(),
        activityTypeStore.clear(),
        activityStore.clear(),
        systemStore.clear(),
        priorityStore.clear(),
        qcConfigStore.clear(),
        qcProcessStore.clear(),
        qcQualifierStore.clear(),
        qcQualifierTypeStore.clear(),
    ]);

    await tx.complete;
}

/**
 * Updates all master data object stores in indexedDB with data returned from master data sync api call
 *
 */
export async function setIndexedDBMasterData(masterData : IMasterDataSync) : Promise<IMasterDataSync> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    // Gets transaction containing all master data object stores
    const tx = db.transaction([
        DIVISION_TABLE_NAME,
        SUBDIVISION_TABLE_NAME,
        DEPARTMENT_TABLE_NAME,
        PRODUCTION_UNIT_TABLE_NAME,
        FIELD_TABLE_NAME,
        BLOCK_TABLE_NAME,
        COMMODITY_TABLE_NAME,
        VARIETY_TABLE_NAME,
        NURSERY_TABLE_NAME,
        ROOT_STOCK_TABLE_NAME,
        PROJECT_TABLE_NAME,
        PLANTS_PER_HECTARE_TABLE_NAME,
        SAFETY_PRECAUTION_TABLE_NAME,
        SAFETY_PRECAUTION_PPE_TABLE_NAME,
        SEED_TRAY_SIZE_TABLE_NAME,
        SPRAY_METHOD_TABLE_NAME,
        UNIT_OF_MEASURE_TABLE_NAME,
        ACTIVE_INGREDIENT_TABLE_NAME,
        CHEMICAL_CATEGORY_TABLE_NAME,
        CHEMICAL_ELEMENT_TABLE_NAME,
        CHEMICAL_FORMULATION_TABLE_NAME,
        CHEMICAL_PRODUCT_TABLE_NAME,
        ACTIVITY_TYPE_TABLE_NAME,
        ACTIVITY_TABLE_NAME,
        SYSTEM_TABLE_NAME,
        PRIORITY_TABLE_NAME,
        QC_CONFIG_TABLE_NAME,
        QC_PROCESS_TABLE_NAME,
        QC_QUALIFIER_TABLE_NAME,
        QC_QUALIFIER_TYPE_TABLE_NAME,
    ], 'readwrite');

    // Gets each object store from the above transaction
    const divisionStore = tx.objectStore(DIVISION_TABLE_NAME);
    const subdivisionStore = tx.objectStore(SUBDIVISION_TABLE_NAME);
    const departmentStore = tx.objectStore(DEPARTMENT_TABLE_NAME);
    const productionUnitStore = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);
    const fieldStore = tx.objectStore(FIELD_TABLE_NAME);
    const blockStore = tx.objectStore(BLOCK_TABLE_NAME);
    const commodityStore = tx.objectStore(COMMODITY_TABLE_NAME);
    const varietyStore = tx.objectStore(VARIETY_TABLE_NAME);
    const nurseryStore = tx.objectStore(NURSERY_TABLE_NAME);
    const rootStockStore = tx.objectStore(ROOT_STOCK_TABLE_NAME);
    const projectStore = tx.objectStore(PROJECT_TABLE_NAME);
    const plantsPerHectareStore = tx.objectStore(PLANTS_PER_HECTARE_TABLE_NAME);
    const safetyPrecautionStore = tx.objectStore(SAFETY_PRECAUTION_TABLE_NAME);
    const safetyPrecautionPpeStore = tx.objectStore(SAFETY_PRECAUTION_PPE_TABLE_NAME);
    const seedTraySizeStore = tx.objectStore(SEED_TRAY_SIZE_TABLE_NAME);
    const SprayMethodStore = tx.objectStore(SPRAY_METHOD_TABLE_NAME);
    const unitOfMeasureStore = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);
    const activeIngredientStore = tx.objectStore(ACTIVE_INGREDIENT_TABLE_NAME);
    const chemicalCategoryStore = tx.objectStore(CHEMICAL_CATEGORY_TABLE_NAME);
    const chemicalElementStore = tx.objectStore(CHEMICAL_ELEMENT_TABLE_NAME);
    const chemicalFormulationStore = tx.objectStore(CHEMICAL_FORMULATION_TABLE_NAME);
    const chemicalProductStore = tx.objectStore(CHEMICAL_PRODUCT_TABLE_NAME);
    const activityTypeStore = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);
    const activityStore = tx.objectStore(ACTIVITY_TABLE_NAME);
    const systemStore = tx.objectStore(SYSTEM_TABLE_NAME);
    const priorityStore = tx.objectStore(PRIORITY_TABLE_NAME);
    const qcConfigStore = tx.objectStore(QC_CONFIG_TABLE_NAME);
    const qcProcessStore = tx.objectStore(QC_PROCESS_TABLE_NAME);
    const qcQualifierStore = tx.objectStore(QC_QUALIFIER_TABLE_NAME);
    const qcQualifierTypeStore = tx.objectStore(QC_QUALIFIER_TYPE_TABLE_NAME);

    /**
     * Updates each specific object store. Adding new entry if it does not exist
     * otherwise updating the existing one.
     */
    masterData.divisions.forEach((x) => {
        divisionStore.put(x, x.guid);
    });

    masterData.subdivisions.forEach((x) => {
        subdivisionStore.put(x, x.guid);
    });

    masterData.departments.forEach((x) => {
        departmentStore.put(x, x.guid);
    });

    masterData.productionUnits.forEach((x) => {
        productionUnitStore.put(x, x.guid);
    });

    masterData.fields.forEach((x) => {
        fieldStore.put(x, x.guid);
    });

    masterData.blocks.forEach((x) => {
        blockStore.put(x, x.guid);
    });

    masterData.commodities.forEach((x) => {
        commodityStore.put(x, x.guid);
    });

    masterData.varieties.forEach((x) => {
        varietyStore.put(x, x.guid);
    });

    masterData.nurseries.forEach((x) => {
        nurseryStore.put(x, x.guid);
    });

    masterData.rootStocks.forEach((x) => {
        rootStockStore.put(x, x.guid);
    });

    masterData.projects.forEach((x) => {
        projectStore.put(x, x.guid);
    });

    masterData.plantsPerHectares.forEach((x) => {
        plantsPerHectareStore.put(x, x.guid);
    });

    masterData.safetyPrecautions.forEach((x) => {
        safetyPrecautionStore.put(x, x.guid);
    });

    masterData.safetyPrecautionPpes.forEach((x) => {
        safetyPrecautionPpeStore.put(x, x.guid);
    });

    masterData.seedTraySizes.forEach((x) => {
        seedTraySizeStore.put(x, x.guid);
    });

    masterData.sprayMethods.forEach((x) => {
        SprayMethodStore.put(x, x.guid);
    });

    masterData.unitOfMeasures.forEach((x) => {
        unitOfMeasureStore.put(x, x.guid);
    });

    masterData.activeIngredients.forEach((x) => {
        activeIngredientStore.put(x, x.guid);
    });

    masterData.chemicalCategories.forEach((x) => {
        chemicalCategoryStore.put(x, x.guid);
    });

    masterData.chemicalElements.forEach((x) => {
        chemicalElementStore.put(x, x.guid);
    });

    masterData.chemicalFormulations.forEach((x) => {
        chemicalFormulationStore.put(x, x.guid);
    });

    masterData.chemicalProducts.forEach((x) => {
        chemicalProductStore.put(x, x.guid);
    });

    masterData.activityTypes.forEach((x) => {
        activityTypeStore.put(x, x.guid);
    });

    masterData.activities.forEach((x) => {
        activityStore.put(x, x.guid);
    });
    
    masterData.systems.forEach((x) => {
        systemStore.put(x, x.guid);
    });

    masterData.priorities.forEach((x) => {
        priorityStore.put(x, x.guid);
    });

    masterData.configs.forEach((x) => {
        qcConfigStore.put(x, x.guid);
    });

    masterData.processes.forEach((x) => {
        qcProcessStore.put(x, x.guid);
    });

    masterData.qualifiers.forEach((x) => {
        qcQualifierStore.put(x, x.guid);
    });

    masterData.qualifierTypes.forEach((x) => {
        qcQualifierTypeStore.put(x, x.guid);
    });

    // Retrieving the new updated list
    const updatedDivisions = await tx.objectStore<IDivision>(DIVISION_TABLE_NAME).getAll();
    const updatedSubdivisions = await tx.objectStore<ISubdivision>(SUBDIVISION_TABLE_NAME).getAll();
    const updatedDepartments = await tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();
    const updatedProductionUnits = await tx.objectStore<IProductionUnit>(PRODUCTION_UNIT_TABLE_NAME).getAll();
    const updatedFields = await tx.objectStore<IField>(FIELD_TABLE_NAME).getAll();
    const updatedBlocks = await tx.objectStore<IBlock>(BLOCK_TABLE_NAME).getAll();
    const updatedCommodities = await tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();
    const updatedVarieties = await tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();
    const updatedNurseries = await tx.objectStore<INursery>(NURSERY_TABLE_NAME).getAll();
    const updatedRootStocks = await tx.objectStore<IRootStock>(ROOT_STOCK_TABLE_NAME).getAll();
    const updatedProjects = await tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();
    const updatedPlantsPerHectares = await tx.objectStore<IPlantsPerHectare>(PLANTS_PER_HECTARE_TABLE_NAME).getAll();
    const updatedSafetyPrecautions = await tx.objectStore<ISafetyPrecaution>(SAFETY_PRECAUTION_TABLE_NAME).getAll();
    const updatedSafetyPrecautionPpes = await tx.objectStore<ISafetyPrecautionPpe>(SAFETY_PRECAUTION_PPE_TABLE_NAME).getAll();
    const updatedSeedTraySizes = await tx.objectStore<ISeedTraySize>(SEED_TRAY_SIZE_TABLE_NAME).getAll();
    const updatedSprayMethods = await tx.objectStore<ISprayMethod>(SPRAY_METHOD_TABLE_NAME).getAll();
    const updatedUnitOfMeasures = await tx.objectStore<IUnitOfMeasure>(UNIT_OF_MEASURE_TABLE_NAME).getAll();
    const updatedActiveIngredients = await tx.objectStore<IActiveIngredient>(ACTIVE_INGREDIENT_TABLE_NAME).getAll();
    const updatedChemicalCategories = await tx.objectStore<IChemicalCategory>(CHEMICAL_CATEGORY_TABLE_NAME).getAll();
    const updatedChemicalElements = await tx.objectStore<IChemicalElement>(CHEMICAL_ELEMENT_TABLE_NAME).getAll();
    const updatedChemicalFormulations = await tx.objectStore<IChemicalFormulation>(CHEMICAL_FORMULATION_TABLE_NAME).getAll();
    const updatedChemicalProducts = await tx.objectStore<IChemicalProduct>(CHEMICAL_PRODUCT_TABLE_NAME).getAll();
    const updatedActivityTypes = await tx.objectStore<IActivityType>(ACTIVITY_TYPE_TABLE_NAME).getAll();
    const updatedActivities = await tx.objectStore<IActivity>(ACTIVITY_TABLE_NAME).getAll();
    const updatedSystems = await tx.objectStore<ISystem>(SYSTEM_TABLE_NAME).getAll();
    const updatedPriorities = await tx.objectStore<IPriority>(PRIORITY_TABLE_NAME).getAll();
    const updatedQcConfigs = await tx.objectStore<IConfig>(QC_CONFIG_TABLE_NAME).getAll();
    const updatedQcProcesses = await tx.objectStore<IProcess>(QC_PROCESS_TABLE_NAME).getAll();
    const updatedQcQualifiers = await tx.objectStore<IQualifier>(QC_QUALIFIER_TABLE_NAME).getAll();
    const updatedQcQualifierTypes = await tx.objectStore<IQualifierType>(QC_QUALIFIER_TYPE_TABLE_NAME).getAll();

    // Creating new master data object with new updated lists to be returned so that redux can be updated.
    const updatedMasterData : IMasterDataSync = {
        divisions: updatedDivisions,
        subdivisions: updatedSubdivisions,
        departments: updatedDepartments,
        productionUnits: updatedProductionUnits,
        fields: updatedFields,
        blocks: updatedBlocks,
        commodities: updatedCommodities,
        varieties: updatedVarieties,
        nurseries: updatedNurseries,
        rootStocks: updatedRootStocks,
        projects: updatedProjects,
        plantsPerHectares: updatedPlantsPerHectares,
        safetyPrecautions: updatedSafetyPrecautions,
        safetyPrecautionPpes: updatedSafetyPrecautionPpes,
        seedTraySizes: updatedSeedTraySizes,
        sprayMethods: updatedSprayMethods,
        unitOfMeasures: updatedUnitOfMeasures,
        activeIngredients: updatedActiveIngredients,
        chemicalCategories: updatedChemicalCategories,
        chemicalElements: updatedChemicalElements,
        chemicalFormulations: updatedChemicalFormulations,
        chemicalProducts: updatedChemicalProducts,
        activityTypes: updatedActivityTypes,
        activities: updatedActivities,
        systems: updatedSystems,
        priorities: updatedPriorities,
        configs: updatedQcConfigs,
        processes: updatedQcProcesses,
        qualifiers: updatedQcQualifiers,
        qualifierTypes: updatedQcQualifierTypes,
    };

    await tx.complete;

    return updatedMasterData;
}

/*================================================================================================================
 *                                   Master Data IndexedDB Getters And Setters
 * ==============================================================================================================*/

/*---------------------------------------------Farm Master Data------------------------------------------------- */

/**
 * Popuplates division object store. Adding new record or updating if the record already exists
 *
 */
export async function setDivisionMasterDataIndexedDB(divisions : Array<IDivision>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DIVISION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(DIVISION_TABLE_NAME);

        divisions.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves divisions.
 */
export async function getDivisionMasterDataIndexedDB() : Promise<Array<IDivision> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DIVISION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IDivision>(DIVISION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates subdivision object store. Adding new record or updating if the record already exists
 *
 */
export async function setSubdivisionMasterDataIndexedDB(subdivisions : Array<ISubdivision>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SUBDIVISION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SUBDIVISION_TABLE_NAME);

        subdivisions.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves subdivisions.
 */
export async function getSubdivisionMasterDataIndexedDB() : Promise<Array<ISubdivision> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SUBDIVISION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISubdivision>(SUBDIVISION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates department object store. Adding new record or updating if the record already exists
 *
 */
export async function setDepartmentMasterDataIndexedDB(departments : Array<IDepartment>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEPARTMENT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(DEPARTMENT_TABLE_NAME);

        departments.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves departments.
 */
export async function getDepartmentMasterDataIndexedDB() : Promise<Array<IDepartment> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(DEPARTMENT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IDepartment>(DEPARTMENT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates production unit object store. Adding new record or updating if the record already exists
 *
 */
export async function setProductionUnitMasterDataIndexedDB(productionUnits : Array<IProductionUnit>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRODUCTION_UNIT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PRODUCTION_UNIT_TABLE_NAME);

        productionUnits.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves production units.
 */
export async function getProductionUnitMasterDataIndexedDB() : Promise<Array<IProductionUnit> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRODUCTION_UNIT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IProductionUnit>(PRODUCTION_UNIT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates field object store. Adding new record or updating if the record already exists
 *
 */
export async function setFieldMasterDataIndexedDB(fields : Array<IField>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FIELD_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(FIELD_TABLE_NAME);

        fields.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves fields.
 */
export async function getFieldMasterDataIndexedDB() : Promise<Array<IField> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(FIELD_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IField>(FIELD_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates block object store. Adding new record or updating if the record already exists
 *
 */
export async function setBlockMasterDataIndexedDB(blocks : Array<IBlock>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BLOCK_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(BLOCK_TABLE_NAME);

        blocks.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves blocks.
 */
export async function getBlockMasterDataIndexedDB() : Promise<Array<IBlock> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(BLOCK_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IBlock>(BLOCK_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates commodity object store. Adding new record or updating if the record already exists
 *
 */
export async function setCommodityMasterDataIndexedDB(commodities : Array<ICommodity>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(COMMODITY_TABLE_NAME);

        commodities.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves commodities.
 */
export async function getCommodityMasterDataIndexedDB() : Promise<Array<ICommodity> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(COMMODITY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ICommodity>(COMMODITY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates variety object store. Adding new record or updating if the record already exists
 *
 */
export async function setVarietyMasterDataIndexedDB(varieties : Array<IVariety>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(VARIETY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(VARIETY_TABLE_NAME);

        varieties.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves varieties.
 */
export async function getVarietyMasterDataIndexedDB() : Promise<Array<IVariety> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(VARIETY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IVariety>(VARIETY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates nursery object store. Adding new record or updating if the record already exists
 *
 */
export async function setNurseryMasterDataIndexedDB(nurseries : Array<INursery>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(NURSERY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(NURSERY_TABLE_NAME);

        nurseries.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves nurseries.
 */
export async function getNurseryMasterDataIndexedDB() : Promise<Array<INursery> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(NURSERY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<INursery>(NURSERY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates root stock object store. Adding new record or updating if the record already exists
 *
 */
export async function setRootStockMasterDataIndexedDB(rootStocks : Array<IRootStock>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ROOT_STOCK_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ROOT_STOCK_TABLE_NAME);

        rootStocks.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves root stocks.
 */
export async function getRootStockMasterDataIndexedDB() : Promise<Array<IRootStock> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ROOT_STOCK_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IRootStock>(ROOT_STOCK_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates project object store. Adding new record or updating if the record already exists
 *
 */
export async function setProjectMasterDataIndexedDB(projects : Array<IProject>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PROJECT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PROJECT_TABLE_NAME);

        projects.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves projects.
 */
export async function getProjectMasterDataIndexedDB() : Promise<Array<IProject> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PROJECT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IProject>(PROJECT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates plants per hectare object store. Adding new record or updating if the record already exists
 *
 */
export async function setPlantsPerHectareMasterDataIndexedDB(plantsPerHectares : Array<IPlantsPerHectare>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PLANTS_PER_HECTARE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PLANTS_PER_HECTARE_TABLE_NAME);

        plantsPerHectares.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves plants per hectares.
 */
export async function getPlantsPerHectareMasterDataIndexedDB() : Promise<Array<IPlantsPerHectare> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PLANTS_PER_HECTARE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPlantsPerHectare>(PLANTS_PER_HECTARE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates safety precaution object store. Adding new record or updating if the record already exists
 *
 */
export async function setSafetyPrecautionMasterDataIndexedDB(safetyPrecautions : Array<ISafetyPrecaution>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SAFETY_PRECAUTION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SAFETY_PRECAUTION_TABLE_NAME);

        safetyPrecautions.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves safety precautions.
 */
export async function getSafetyPrecautionMasterDataIndexedDB() : Promise<Array<ISafetyPrecaution> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SAFETY_PRECAUTION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISafetyPrecaution>(SAFETY_PRECAUTION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates safety precaution PPE object store. Adding new record or updating if the record already exists
 *
 */
export async function setSafetyPrecautionPpeMasterDataIndexedDB(safetyPrecautionPpes : Array<ISafetyPrecautionPpe>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SAFETY_PRECAUTION_PPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SAFETY_PRECAUTION_PPE_TABLE_NAME);

        safetyPrecautionPpes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves safety precaution PPEs.
 */
export async function getSafetyPrecautionPpeMasterDataIndexedDB() : Promise<Array<ISafetyPrecautionPpe> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SAFETY_PRECAUTION_PPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISafetyPrecautionPpe>(SAFETY_PRECAUTION_PPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates seed tray size object store. Adding new record or updating if the record already exists
 *
 */
export async function setSeedTraySizeMasterDataIndexedDB(seedTraySizes : Array<ISeedTraySize>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SEED_TRAY_SIZE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SEED_TRAY_SIZE_TABLE_NAME);

        seedTraySizes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves seed tray sizes.
 */
export async function getSeedTraySizeMasterDataIndexedDB() : Promise<Array<ISeedTraySize> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SEED_TRAY_SIZE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISeedTraySize>(SEED_TRAY_SIZE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates spray method object store. Adding new record or updating if the record already exists
 *
 */
export async function setSprayMethodMasterDataIndexedDB(sprayMethods : Array<ISprayMethod>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SPRAY_METHOD_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SPRAY_METHOD_TABLE_NAME);

        sprayMethods.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves spray methods.
 */
export async function getSprayMethodMasterDataIndexedDB() : Promise<Array<ISprayMethod> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SPRAY_METHOD_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISprayMethod>(SPRAY_METHOD_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates unit of measure object store. Adding new record or updating if the record already exists
 *
 */
export async function setUnitOfMeasureMasterDataIndexedDB(unitOfMeasures : Array<IUnitOfMeasure>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(UNIT_OF_MEASURE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(UNIT_OF_MEASURE_TABLE_NAME);

        unitOfMeasures.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves unit of measures.
 */
export async function getUnitOfMeasureMasterDataIndexedDB() : Promise<Array<IUnitOfMeasure> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(UNIT_OF_MEASURE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IUnitOfMeasure>(UNIT_OF_MEASURE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates active ingredient object store. Adding new record or updating if the record already exists
 *
 */
export async function setActiveIngredientMasterDataIndexedDB(activeIngredients : Array<IActiveIngredient>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVE_INGREDIENT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ACTIVE_INGREDIENT_TABLE_NAME);

        activeIngredients.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves active ingredients.
 */
export async function getActiveIngredientMasterDataIndexedDB() : Promise<Array<IActiveIngredient> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVE_INGREDIENT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IActiveIngredient>(ACTIVE_INGREDIENT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates chemical category object store. Adding new record or updating if the record already exists
 *
 */
export async function setChemicalCategoryMasterDataIndexedDB(chemicalCategories : Array<IChemicalCategory>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_CATEGORY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(CHEMICAL_CATEGORY_TABLE_NAME);

        chemicalCategories.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves chemical categories.
 */
export async function getChemicalCategoryMasterDataIndexedDB() : Promise<Array<IChemicalCategory> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_CATEGORY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IChemicalCategory>(CHEMICAL_CATEGORY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates chemical element object store. Adding new record or updating if the record already exists
 *
 */
export async function setChemicalElementMasterDataIndexedDB(chemicalElements : Array<IChemicalElement>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_ELEMENT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(CHEMICAL_ELEMENT_TABLE_NAME);

        chemicalElements.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves chemical elements.
 */
export async function getChemicalElementMasterDataIndexedDB() : Promise<Array<IChemicalElement> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_ELEMENT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IChemicalElement>(CHEMICAL_ELEMENT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates chemical formulation object store. Adding new record or updating if the record already exists
 *
 */
export async function setChemicalFormulationMasterDataIndexedDB(chemicalFormulations : Array<IChemicalFormulation>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_FORMULATION_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(CHEMICAL_FORMULATION_TABLE_NAME);

        chemicalFormulations.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves chemical formulations.
 */
export async function getChemicalFormulationMasterDataIndexedDB() : Promise<Array<IChemicalFormulation> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_FORMULATION_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IChemicalFormulation>(CHEMICAL_FORMULATION_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates chemical product object store. Adding new record or updating if the record already exists
 *
 */
export async function setChemicalProductMasterDataIndexedDB(chemicalProducts : Array<IChemicalProduct>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_PRODUCT_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(CHEMICAL_PRODUCT_TABLE_NAME);

        chemicalProducts.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves chemical products.
 */
export async function getChemicalProductMasterDataIndexedDB() : Promise<Array<IChemicalProduct> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(CHEMICAL_PRODUCT_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IChemicalProduct>(CHEMICAL_PRODUCT_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates activity type object store. Adding new record or updating if the record already exists
 *
 */
export async function setActivityTypeMasterDataIndexedDB(activityTypes : Array<IActivityType>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ACTIVITY_TYPE_TABLE_NAME);

        activityTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves activity type.
 */
export async function getActivityTypeMasterDataIndexedDB() : Promise<Array<IActivityType> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IActivityType>(ACTIVITY_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates activity object store. Adding new record or updating if the record already exists
 *
 */
export async function setActivityMasterDataIndexedDB(activities : Array<IActivity>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(ACTIVITY_TABLE_NAME);

        activities.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves activities.
 */
export async function getActivityMasterDataIndexedDB() : Promise<Array<IActivity> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(ACTIVITY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IActivity>(ACTIVITY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates system object store. Adding new record or updating if the record already exists
 *
 */
export async function setSystemMasterDataIndexedDB(systems : Array<ISystem>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SYSTEM_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(SYSTEM_TABLE_NAME);

        systems.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves the systems.
 */
export async function getSystemMasterDataIndexedDB() : Promise<Array<ISystem> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(SYSTEM_TABLE_NAME, 'readonly');

        const result = tx.objectStore<ISystem>(SYSTEM_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates priority object store. Adding new record or updating if the record already exists
 *
 */
export async function setPriorityMasterDataIndexedDB(priorities : Array<IPriority>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRIORITY_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(PRIORITY_TABLE_NAME);

        priorities.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves the systems.
 */
export async function getPriorityMasterDataIndexedDB() : Promise<Array<IPriority> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(PRIORITY_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IPriority>(PRIORITY_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/*---------------------------------------------QC Master Data------------------------------------------------- */

/**
 * Popuplates QC config object store. Adding new record or updating if the record already exists
 *
 */
export async function setQcConfigMasterDataIndexedDB(configs : Array<IConfig>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_CONFIG_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(QC_CONFIG_TABLE_NAME);

        configs.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves QC configs.
 */
export async function getQcConfigMasterDataIndexedDB() : Promise<Array<IConfig> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_CONFIG_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IConfig>(QC_CONFIG_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates QC process object store. Adding new record or updating if the record already exists
 *
 */
export async function setQcProcessMasterDataIndexedDB(processes : Array<IProcess>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_PROCESS_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(QC_PROCESS_TABLE_NAME);

        processes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves QC processes.
 */
export async function getQcProcessMasterDataIndexedDB() : Promise<Array<IProcess> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_PROCESS_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IProcess>(QC_PROCESS_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates QC qualifier object store. Adding new record or updating if the record already exists
 *
 */
export async function setQcQualifierMasterDataIndexedDB(qualifiers : Array<IQualifier>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_QUALIFIER_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(QC_QUALIFIER_TABLE_NAME);

        qualifiers.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves QC qualifiers.
 */
export async function getQcQualifierMasterDataIndexedDB() : Promise<Array<IQualifier> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_QUALIFIER_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IQualifier>(QC_QUALIFIER_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}

/**
 * Popuplates QC qualifier type object store. Adding new record or updating if the record already exists
 *
 */
export async function setQcQualifierTypeMasterDataIndexedDB(qualifierTypes : Array<IQualifierType>) : Promise<void> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_QUALIFIER_TYPE_TABLE_NAME, 'readwrite');

        const store = tx.objectStore(QC_QUALIFIER_TYPE_TABLE_NAME);

        qualifierTypes.forEach((x) => {
            store.put(x, x.guid);
        });

        await tx.complete;
    }
}

/**
 * Opens the DB and retrieves QC qualifier types.
 */
export async function getQcQualifierTypeMasterDataIndexedDB() : Promise<Array<IQualifierType> | undefined> {
    // checks if indexedDB is available.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

        const tx = db.transaction(QC_QUALIFIER_TYPE_TABLE_NAME, 'readonly');

        const result = tx.objectStore<IQualifierType>(QC_QUALIFIER_TYPE_TABLE_NAME).getAll();

        await tx.complete;

        return result;
    }
}
