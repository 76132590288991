import * as Yup from 'yup';
import { IPasswordRecoveryFormValues } from '../../../@types/model/auth/password/passwordRecoveryFormValues';
import { YupSchema } from '../../../@types/yupHelper';

type YupShape = Record<keyof IPasswordRecoveryFormValues, YupSchema>

export default class PasswordRecoveryModelHelper {
    public static createFormValues = () : IPasswordRecoveryFormValues => {
        return {
            password: '',
            confirmPassword: '',
        };
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match.').required('Required'),
    });
}