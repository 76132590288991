import { createReducer } from '@reduxjs/toolkit';
import DataActions from './actions';
import { IBlock } from '../../@types/model/masterData/block/block';
import { IBlockHomeView } from '../../@types/model/masterData/block/blockHomeView';
import { IActiveIngredient } from '../../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from '../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from '../../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from '../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from '../../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IChemicalProductActiveIngredientBridge } from '../../@types/model/masterData/chemicals/chemicalProductActiveIngredient/chemicalProductActiveIngredient';
import { IChemicalProductElementBridge } from '../../@types/model/masterData/chemicals/chemicalProductElement/chemicalProductElement';
import { ISafetyPrecaution } from '../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from '../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDivision } from '../../@types/model/masterData/division/division';
import { IField } from '../../@types/model/masterData/field/field';
import { IIrrigationCycle } from '../../@types/model/masterData/irrigationCycle/irrigationCycle';
import { ICommodityNurseryBridge } from '../../@types/model/masterData/nursery/commodityNurseryBridge';
import { INursery } from '../../@types/model/masterData/nursery/nursery';
import { IProductionUnit } from '../../@types/model/masterData/productionUnit/productionUnit';
import { IProject } from '../../@types/model/masterData/project/project';
import { IRootStock } from '../../@types/model/masterData/rootStock/rootStock';
import { ISubdivision } from '../../@types/model/masterData/subdivision/subdivision';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPlantsPerHectare } from '../../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import { ISeedTraySize } from '../../@types/model/masterData/seedTraySize/seedTraySize';
import { ISprayMethod } from '../../@types/model/masterData/sprayMethod/splayMethod';
import { IActivity } from '../../@types/model/masterData/activity/activity';
import { ISystem } from '../../@types/model/masterData/system/system';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IPriority } from '../../@types/model/masterData/priority/priority';

export interface IDataState {
    masterDataSyncIsLoading : boolean;
    isLoading : boolean;
    divisionData : Array<IDivision> | null;
    subdivisionData : Array<ISubdivision> | null;
    departmentData : Array<IDepartment> | null;
    productionUnitData : Array<IProductionUnit> | null;
    fieldData : Array<IField> | null;
    blockData : Array<IBlock> | null;
    commodityData : Array<ICommodity> | null;
    varietyData : Array<IVariety> | null;
    projectData : Array<IProject> | null;
    nurseryData : Array<INursery> | null;
    rootStockData : Array<IRootStock> | null;
    unitOfMeasureData : Array<IUnitOfMeasure> | null;
    chemicalElementData : Array<IChemicalElement> | null;
    chemicalProductData : Array<IChemicalProduct> | null;
    chemicalFormulationData : Array<IChemicalFormulation> | null;
    chemicalCategoryData : Array<IChemicalCategory> | null;
    activeIngredientData : Array<IActiveIngredient> | null;
    safetyPrecautionData : Array<ISafetyPrecaution> | null;
    safetyPrecautionPpeData : Array<ISafetyPrecautionPpe> | null;
    irrigationCycleData : Array<IIrrigationCycle> | null;
    commodityNurseryBridgeData : Array<ICommodityNurseryBridge> | null;
    chemicalProductElementBridgeData : Array<IChemicalProductElementBridge> | null;
    chemicalProductActiveIngredientBridgeData : Array<IChemicalProductActiveIngredientBridge> | null;
    plantsPerHectareData : Array<IPlantsPerHectare> | null;
    seedTraySizeData : Array<ISeedTraySize> | null;
    sprayMethodData : Array<ISprayMethod> | null;
    activityTypeData : Array<IActivityType> | null;
    activityData : Array<IActivity> | null;
    systemData : Array<ISystem> | null;
    priorityData : Array<IPriority> | null;

    blockHomeViews : Array<IBlockHomeView> | null;

    selectedBlock : IBlock | null;
    selectedBlockView : IBlockHomeView | null;
    selectedField : IField | null;
    selectedCommodity : ICommodity | null;
    selectedChemicalProduct : IChemicalProduct | null;

    selectedUserDivisionIds : Array<number> | null;
    selectedUserDepartmentIds : Array<number> | null;
}

const initialState = {
    masterDataSyncIsLoading: false,
    isLoading: false,
    divisionData: null,
    subdivisionData: null,
    departmentData: null,
    productionUnitData: null,
    fieldData: null,
    blockData: null,
    commodityData: null,
    varietyData: null,
    projectData: null,
    rootStockData: null,
    unitOfMeasureData: null,
    nurseryData: null,
    commodityNurseryBridgeData: null,
    chemicalElementData: null,
    chemicalProductData: null,
    chemicalFormulationData: null,
    chemicalCategoryData: null,
    activeIngredientData: null,
    safetyPrecautionData: null,
    safetyPrecautionPpeData: null,
    irrigationCycleData: null,
    chemicalProductElementBridgeData: null,
    chemicalProductActiveIngredientBridgeData: null,
    plantsPerHectareData: null,
    seedTraySizeData: null,
    sprayMethodData: null,
    activityTypeData: null,
    activityData: null,
    systemData: null,
    priorityData: null,

    blockHomeViews: null,

    selectedBlock: null,
    selectedBlockView: null,
    selectedField: null,
    selectedCommodity: null,
    selectedChemicalProduct: null,

    selectedUserDivisionIds: null,
    selectedUserDepartmentIds: null,
};

const dataReducer = createReducer<IDataState>(initialState, (builder) => {
    builder.addCase(DataActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(DataActions.setDivisionData, (state, action) => {
        state.divisionData = action.payload;
    });
    builder.addCase(DataActions.setSubdivisionData, (state, action) => {
        state.subdivisionData = action.payload;
    });
    builder.addCase(DataActions.setDepartmentData, (state, action) => {
        state.departmentData = action.payload;
    });
    builder.addCase(DataActions.setProductionUnitData, (state, action) => {
        state.productionUnitData = action.payload;
    });
    builder.addCase(DataActions.setFieldData, (state, action) => {
        state.fieldData = action.payload;
    });
    builder.addCase(DataActions.setBlockData, (state, action) => {
        state.blockData = action.payload;
    });
    builder.addCase(DataActions.setCommodityData, (state, action) => {
        state.commodityData = action.payload;
    });
    builder.addCase(DataActions.setVarietyData, (state, action) => {
        state.varietyData = action.payload;
    });
    builder.addCase(DataActions.setProjectData, (state, action) => {
        state.projectData = action.payload;
    });
    builder.addCase(DataActions.setNurseryData, (state, action) => {
        state.nurseryData = action.payload;
    });
    builder.addCase(DataActions.setRootStockData, (state, action) => {
        state.rootStockData = action.payload;
    });
    builder.addCase(DataActions.setUnitOfMeasureData, (state, action) => {
        state.unitOfMeasureData = action.payload;
    });
    builder.addCase(DataActions.setChemicalElementData, (state, action) => {
        state.chemicalElementData = action.payload;
    });
    builder.addCase(DataActions.setChemicalCategoryData, (state, action) => {
        state.chemicalCategoryData = action.payload;
    });
    builder.addCase(DataActions.setChemicalFormulationData, (state, action) => {
        state.chemicalFormulationData = action.payload;
    });
    builder.addCase(DataActions.setChemicalProductData, (state, action) => {
        state.chemicalProductData = action.payload;
    });
    builder.addCase(DataActions.setChemicalProductElementBridgeData, (state, action) => {
        state.chemicalProductElementBridgeData = action.payload;
    });
    builder.addCase(DataActions.setChemicalProductActiveIngredientBridgeData, (state, action) => {
        state.chemicalProductActiveIngredientBridgeData = action.payload;
    });
    builder.addCase(DataActions.setActiveIngredientData, (state, action) => {
        state.activeIngredientData = action.payload;
    });
    builder.addCase(DataActions.setSafetyPrecautionData, (state, action) => {
        state.safetyPrecautionData = action.payload;
    });
    builder.addCase(DataActions.setSafetyPrecautionPpeData, (state, action) => {
        state.safetyPrecautionPpeData = action.payload;
    });
    builder.addCase(DataActions.setCommodityNurseryBridgeData, (state, action) => {
        state.commodityNurseryBridgeData = action.payload;
    });
    builder.addCase(DataActions.setIrrigationCycleData, (state, action) => {
        state.irrigationCycleData = action.payload;
    });
    builder.addCase(DataActions.setBlockHomeViewData, (state, action) => {
        state.blockHomeViews = action.payload;
    });
    builder.addCase(DataActions.setSelectedBlock, (state, action) => {
        state.selectedBlock = action.payload;
    });
    builder.addCase(DataActions.setSelectedBlockView, (state, action) => {
        state.selectedBlockView = action.payload;
    });
    builder.addCase(DataActions.setSelectedField, (state, action) => {
        state.selectedField = action.payload;
    });
    builder.addCase(DataActions.setSelectedCommodity, (state, action) => {
        state.selectedCommodity = action.payload;
    });
    builder.addCase(DataActions.setSelectedChemicalProduct, (state, action) => {
        state.selectedChemicalProduct = action.payload;
    });
    builder.addCase(DataActions.setPlantsPerHectareData, (state, action) => {
        state.plantsPerHectareData = action.payload;
    });
    builder.addCase(DataActions.setSeedTraySizeData, (state, action) => {
        state.seedTraySizeData = action.payload;
    });
    builder.addCase(DataActions.setSprayMethodData, (state, action) => {
        state.sprayMethodData = action.payload;
    });
    builder.addCase(DataActions.setIsMasterDataSyncRunning, (state, action) => {
        state.masterDataSyncIsLoading = action.payload;
    });
    builder.addCase(DataActions.setActivityData, (state, action) => {
        state.activityData = action.payload;
    });
    builder.addCase(DataActions.setActivityTypeData, (state, action) => {
        state.activityTypeData = action.payload;
    });
    builder.addCase(DataActions.setSystemData, (state, action) => {
        state.systemData = action.payload;
    });
    builder.addCase(DataActions.setPriorityData, (state, action) => {
        state.priorityData = action.payload;
    });
    builder.addCase(DataActions.setSyncedMasterData, (state, action) => {
        state.divisionData = action.payload.divisions;
        state.subdivisionData = action.payload.subdivisions;
        state.departmentData = action.payload.departments;
        state.productionUnitData = action.payload.productionUnits;
        state.fieldData = action.payload.fields;
        state.blockData = action.payload.blocks;
        state.commodityData = action.payload.commodities;
        state.varietyData = action.payload.varieties;
        state.nurseryData = action.payload.nurseries;
        state.rootStockData = action.payload.rootStocks;
        state.projectData = action.payload.projects;
        state.plantsPerHectareData = action.payload.plantsPerHectares;
        state.safetyPrecautionData = action.payload.safetyPrecautions;
        state.safetyPrecautionPpeData = action.payload.safetyPrecautionPpes;
        state.seedTraySizeData = action.payload.seedTraySizes;
        state.sprayMethodData = action.payload.sprayMethods;
        state.unitOfMeasureData = action.payload.unitOfMeasures;
        state.activeIngredientData = action.payload.activeIngredients;
        state.chemicalCategoryData = action.payload.chemicalCategories;
        state.chemicalElementData = action.payload.chemicalElements;
        state.chemicalFormulationData = action.payload.chemicalFormulations;
        state.chemicalProductData = action.payload.chemicalProducts;
        state.activityTypeData = action.payload.activityTypes;
        state.activityData = action.payload.activities;
        state.systemData = action.payload.systems;
        state.priorityData = action.payload.priorities;
    });
    builder.addCase(DataActions.setSelectedUserDivisionIds, (state, action) => {
        state.selectedUserDivisionIds = action.payload;
    });
    builder.addCase(DataActions.setSelectedUserDepartmentIds, (state, action) => {
        state.selectedUserDepartmentIds = action.payload;
    });
    builder.addCase(DataActions.reset, () => {
        return initialState;
    });
});

export default dataReducer;
