import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IChemicalElement } from '../../../../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalElementUpsert } from '../../../../@types/model/masterData/chemicals/chemicalElement/chemicalElementUpsert';

export default class ChemicalElementHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataChemicalElementGetList() : AxiosPromise<Array<IChemicalElement>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ChemicalElement/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static chemicalElementUpsert = (chemicalElementUpsert : IChemicalElementUpsert) : AxiosPromise<IChemicalElement> => {
        return axios.post(`${API_URL}/MasterData/ChemicalElement/Upsert`, chemicalElementUpsert);
    }

    public static chemicalElementDelete = (chemicalElementId : number) : AxiosPromise<IChemicalElement> => {
        return axios.delete(`${API_URL}/MasterData/ChemicalElement/Delete`, {
            params: { chemicalElementId },
        });
    }
}