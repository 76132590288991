import { createReducer } from '@reduxjs/toolkit';
import FreshServiceActions from './actions';

export interface IFreshServiceState {
    isLoading : boolean;
}

const initialState = {
    isLoading: false,
};

const freshServiceReducer = createReducer<IFreshServiceState>(initialState, (builder) => {
    builder.addCase(FreshServiceActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(FreshServiceActions.reset, () => {
        return initialState;
    });
});

export default freshServiceReducer;
