import React, { useEffect, useMemo, useState } from 'react';
import { MuiAutocompleteSelect, ProjectDialog, PillButton } from '@zz2/zz2-ui';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { IUser } from '../../@types/model/auth/user/user';
import { getMasterDataLastSyncDateLocalStorage, getUserSelectedDepartmentsLocalStorage, getUserSelectedDivisionsLocalStorage, setUserSelectedDepartmentsLocalStorage, setUserSelectedDivisionsLocalStorage } from '../../service/localStorageService';
import DataActions from '../../store/data/actions';
import moment from 'moment';
import { Button, Tooltip, Typography } from '@mui/material';
import GeneralThunk from '../../store/general/thunk';
import DataThunks from '../../store/data/thunk';
import { OptionType } from '../../@types/model/optionType';
import { IOptionType } from '@zz2/zz2-ui';

interface IUserSettingsProps {
    isOpen : boolean;
    setIsUserDialogOpenCallback : (state : boolean) => void;
    currentUser : IUser | undefined;
    onClose : () => void;
    signOut : () => void;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(x => x.data.isLoading);
    const divisions = useAppSelector(x => x.data.divisionData);
    const subdivisions = useAppSelector(x => x.data.subdivisionData);
    const departments = useAppSelector(x => x.data.departmentData);

    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = getUserSelectedDepartmentsLocalStorage();


    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState<Array<IOptionType>>([]);
    const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<Array<IOptionType>>([]);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (props.currentUser) {
            dispatch(DataThunks.getDivisionList(props.currentUser.departmentIds as Array<number>));
            dispatch(DataThunks.getDepartmentListByIds(props.currentUser.departmentIds as Array<number>));
        }
        loadData();
    }, [props.isOpen]);


    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    
    const loadData = () : void => {
        if (props.currentUser?.divisionIds && props.currentUser.divisionIds.length > 0) {
            const selectedDivisionOptions = divisions?.filter(div => selectedDivisions.some(localSelectedDiv => div.id === localSelectedDiv.value))
                .map(OptionType.fromDivision) ?? [];

            if (selectedDivisions.length < 1) return;

            setSelectedDivisionOptions(selectedDivisionOptions);
            dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisions.map(x => x.value)));
        } else {
            setUserSelectedDivisionsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDivisionIds(null));
        }

        if (props.currentUser?.departmentIds && props.currentUser.departmentIds.length > 0) {
            const selectedDepartmentOptions = departments?.filter(dept => selectedDepartments.some(localSelectedDept => dept.id === localSelectedDept.value))
                .map(OptionType.fromDepartment) ?? [];

            if (selectedDepartments.length < 1) return;

            setSelectedDepartmentOptions(selectedDepartmentOptions);
            dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartments.map(x => x.value)));
        } else {
            setUserSelectedDepartmentsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDepartmentIds(null));
        }
    };


    /*================================================================================================================
     *                                                  Handler Methods - App
     * ==============================================================================================================*/


    const onDivisionChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDivisionOptions(selectedOptions);
        setSelectedDepartmentOptions([]);
    };

    const onDepartmentChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDepartmentOptions(selectedOptions);
    };

    const onDivisionSelectAll = () : void => {
        if (divisionOptions.length !== selectedDivisionOptions.length) {
            onDivisionChange(divisionOptions);
        } else {
            onDivisionChange([]);
            onDepartmentChange([]);
        }
    };

    const onDepartmentSelectAll = () : void => {
        if (departmentOptions.length !== selectedDepartmentOptions.length) {
            onDepartmentChange(departmentOptions);
        } else {
            onDepartmentChange([]);
        }
    };

    const onMasterDataResyncClick = async () : Promise<void> => {
        props.setIsUserDialogOpenCallback(false);
        dispatch(DataActions.setIsMasterDataSyncRunning(true));
        dispatch(GeneralThunk.showInfoSnackbar('Master data sync in progress.'));
        await dispatch(DataThunks.manualSyncAllMasterData());
        dispatch(GeneralThunk.showSuccessSnackbar('Master data sync completed.'));
        dispatch(DataActions.setIsMasterDataSyncRunning(false));
    };

    const onCloseUserSettings = () : void => {
        if (selectedDivisionOptions.length < 1 || selectedDepartmentOptions.length < 1) {
            dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'Select at least one division and department.' }));
            return;
        }

        setUserSelectedDivisionsLocalStorage(selectedDivisionOptions);
        dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisionOptions.map(x => x.value)));
        setUserSelectedDepartmentsLocalStorage(selectedDepartmentOptions);
        dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartmentOptions.map(x => x.value)));
        props.onClose();
    };

    const getMasterDataLastSyncDate = () : string => {
        const lastSyncDate : moment.Moment | null = getMasterDataLastSyncDateLocalStorage();

        return lastSyncDate ? moment(lastSyncDate).format('DD/MM/YYYY HH:mm:ss') : '';
    };


    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/


    const divisionOptions = useMemo(() => {
        if (!divisions || !props.currentUser || !props.currentUser.divisionIds) return [];

        return divisions
            .filter(div => div.isActive && (props.currentUser?.divisionIds?.some(divId => div.id === divId)))
            .map(OptionType.fromDivision);
    }, [divisions, props.currentUser]);

    const departmentOptions = useMemo(() => {
        if (!departments || !props.currentUser || !props.currentUser.departmentIds) return [];

        const filteredSubdivisions = subdivisions?.filter(subdivision => selectedDivisionOptions.some(div => subdivision.divisionId === div.value)) ?? [];

        return departments
            .filter(dept => dept.isActive
                && (props.currentUser?.departmentIds?.some(deptId => dept.id === deptId))
                && (filteredSubdivisions.some(subdivision => dept.subdivisionId === subdivision.id)))
            .map(OptionType.fromDepartment);
    }, [subdivisions, departments, props.currentUser, selectedDivisionOptions]);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <ProjectDialog
            title={'User Settings'}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'xs'}
            isLoadingCircular={isLoading}
            onClose={onCloseUserSettings}>
            <div className={'flx1 fdc aic jcc m10'}>
                <MuiAutocompleteSelect
                    isMulti
                    className={'w300 mt30'}
                    name={'divisions'}
                    label={'Divisions'}
                    options={divisionOptions}
                    value={selectedDivisionOptions}
                    onChange={onDivisionChange}
                />
                <Button size={'small'} onClick={onDivisionSelectAll} disabled={divisionOptions.length < 1}>
                    {(divisionOptions.length > 0 && divisionOptions.length === selectedDivisionOptions.length) ? 'Unselect All' : 'Select All'}
                </Button>
                <MuiAutocompleteSelect
                    isMulti
                    className={'w300 mt30 mxh200 oya'}
                    name={'departments'}
                    label={'Departments'}
                    options={departmentOptions}
                    value={selectedDepartmentOptions}
                    onChange={onDepartmentChange}
                />
                <Button size={'small'} onClick={onDepartmentSelectAll} disabled={departmentOptions.length < 1}>
                    {(departmentOptions.length > 0 && departmentOptions.length === selectedDepartmentOptions.length) ? 'Unselect All' : 'Select All'}
                </Button>
                <div className={'fdc mt10 mb10 aic jcc'}>
                    <Typography variant={'body2'}>{`Last sync date: ${getMasterDataLastSyncDate()}`}</Typography>
                    <Tooltip className='aic jcc' title={'Does a full resyncing of the master data'}>
                        <Button variant={'text'} onClick={onMasterDataResyncClick}>Resync Master Data</Button>
                    </Tooltip>
                </div>
                <div className={'fdr aic pb10'}>
                    <PillButton
                        className={'h35 w150'}
                        text={'LOGOUT'}
                        color={'secondary'}
                        onClick={props.signOut}
                    />
                </div>
            </div>
        </ProjectDialog >
    );
};

export default UserSettings;