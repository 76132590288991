
import IrrigationCycleTaskActions from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { IIrrigationCycleTask } from '../../../@types/model/irrigationCycleTask/irrigationCycleTask';

export interface IIrrigationCycleTaskState {
    isLoading : boolean;
    irrigationCycleTaskData : Array<IIrrigationCycleTask> | null;
}

const initialState = {
    isLoading: false,
    irrigationCycleTaskData: null,
};

const irrigationCycleTaskReducer = createReducer<IIrrigationCycleTaskState>(initialState, (builder) => {
    builder.addCase(IrrigationCycleTaskActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(IrrigationCycleTaskActions.setIrrigationCycleTaskData, (state, action) => {
        state.irrigationCycleTaskData = action.payload;
    });
    builder.addCase(IrrigationCycleTaskActions.reset, () => {
        return initialState;
    });
});

export default irrigationCycleTaskReducer;
