import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAppDispatch } from '../../../@types/redux';
import GeneralThunk from '../../../store/general/thunk';
import * as localStorageService from '../../../service/localStorageService';
import AuthHttpService from '../../../service/http/authHttpService';
import { Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

interface IPasswordDialogProps {
    open : boolean;
}

type PasswordDialogPropsType = IPasswordDialogProps & FormikProps<{ password : string; confirmPassword : string }>;

const PasswordDialog = (props : PasswordDialogPropsType) : React.ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const dispatch = useAppDispatch();

    const handleRefresh = async () => {
        setIsLoading(true);

        const result = await AuthHttpService.getSession();
        localStorageService.setLocalStorageSession(result.data);

        setIsLoading(false);
    };

    const handleClose = async () => {
        setIsLoading(true);

        const result = await AuthHttpService.setPassword(password);
        dispatch(GeneralThunk.showSuccessSnackbar('Password has been set successfully.'));
        localStorageService.setLocalStorageSession(result.data);

        setIsLoading(false);
    };

    const handlePasswordChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(event.currentTarget.value);
    };

    const handleConfirmPasswordChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPassword(event.currentTarget.value);
    };

    return (
        <Form>
            <Dialog
                open={props.open}
                aria-labelledby='password-dialog-title'
            >
                <DialogTitle id='password-dialog-title'>Create Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You need to create a password for your account.
                    </DialogContentText>
                    <FormControl>
                        <TextField
                            required
                            error={!password}
                            autoFocus
                            margin='dense'
                            id='password'
                            label='Password'
                            type='password'
                            fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <TextField
                            required
                            error={!confirmPassword}
                            margin='dense'
                            id='confirmPassword'
                            label='Confirm Password'
                            type='password'
                            fullWidth
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        {
                            confirmPassword !== password &&
                            <FormHelperText error>Does not match password</FormHelperText>
                        }
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={handleRefresh}>
                        {isLoading && <RefreshIcon className={'mr10'}></RefreshIcon>}
                        {isLoading && <CircularProgress color='primary' size={24} className={'mr10'}></CircularProgress>}
                        REFRESH
                    </Button>
                    <Button color='primary' disabled={isLoading || (password !== confirmPassword)} onClick={handleClose}>
                        {isLoading && <SaveIcon className={'mr10'}></SaveIcon>}
                        {isLoading && <CircularProgress color='primary' size={24} className={'mr10'}></CircularProgress>}
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        </Form>
    );
};

// Validation schema used by formik. https://github.com/jquense/yup
const formSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string().required('Required'),
});

export default withFormik<IPasswordDialogProps, { password : string; confirmPassword : string }>({
    handleSubmit: () => null,
    enableReinitialize: true,
    validationSchema: formSchema,
})(PasswordDialog);
