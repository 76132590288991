import DataActions from './actions';
import GeneralThunk from '../general/thunk';
import lodash from 'lodash';
import moment from 'moment';
import { IBlock } from '../../@types/model/masterData/block/block';
import { IBlockHomeView } from '../../@types/model/masterData/block/blockHomeView';
import { IActiveIngredient } from '../../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from '../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from '../../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from '../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from '../../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IChemicalProductActiveIngredientBridge } from '../../@types/model/masterData/chemicals/chemicalProductActiveIngredient/chemicalProductActiveIngredient';
import { IChemicalProductElementBridge } from '../../@types/model/masterData/chemicals/chemicalProductElement/chemicalProductElement';
import { ISafetyPrecaution } from '../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from '../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDivision } from '../../@types/model/masterData/division/division';
import { IField } from '../../@types/model/masterData/field/field';
import { IIrrigationCycle } from '../../@types/model/masterData/irrigationCycle/irrigationCycle';
import { INursery } from '../../@types/model/masterData/nursery/nursery';
import { IProductionUnit } from '../../@types/model/masterData/productionUnit/productionUnit';
import { IProject } from '../../@types/model/masterData/project/project';
import { IRootStock } from '../../@types/model/masterData/rootStock/rootStock';
import { ISubdivision } from '../../@types/model/masterData/subdivision/subdivision';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import DivisionHttpService from '../../service/http/masterData/divisionHttpService';
import SubdivisionHttpService from '../../service/http/masterData/subdivisionHttpService';
import ProductionUnitHttpService from '../../service/http/masterData/productionUnitHttpService';
import FieldHttpService from '../../service/http/masterData/fieldHttpService';
import BlockHttpService from '../../service/http/masterData/blockHttpService';
import CommodityHttpService from '../../service/http/masterData/commodityHttpService';
import VarietyHttpService from '../../service/http/masterData/varietyHttpService';
import NurseryHttpService from '../../service/http/masterData/nurseryHttpService';
import RootStockHttpService from '../../service/http/masterData/rootStockHttpService';
import UnitOfMeasureHttpService from '../../service/http/masterData/unitOfMeasureHttpService';
import ChemicalProductHttpService from '../../service/http/masterData/chemicals/chemicalProductHttpService';
import ChemicalElementHttpService from '../../service/http/masterData/chemicals/chemicalElementHttpService';
import ChemicalProductElementHttpService from '../../service/http/masterData/chemicals/chemicalProductActiveIngredientHttpService';
import ActiveIngredientHttpService from '../../service/http/masterData/chemicals/activeIngredientHttpService';
import ChemicalCategoryHttpService from '../../service/http/masterData/chemicals/chemicalCategoryHttpService';
import ChemicalFormulationHttpService from '../../service/http/masterData/chemicals/chemicalFormulationHttpService';
import ChemicalProductActiveIngredientHttpService from '../../service/http/masterData/chemicals/chemicalProductElementHttpService';
import SafetyPrecautionHttpService from '../../service/http/masterData/chemicals/safetyPrecautionHttpService';
import SafetyPrecautionPpeHttpService from '../../service/http/masterData/chemicals/safetyPrecautionPpeHttpService';
import IrrigationCycleHttpService from '../../service/http/masterData/irrigationCycleHttpService';
import ProjectHttpService from '../../service/http/masterData/projectHttpService';
import DepartmentHttpService from '../../service/http/masterData/departmentHttpService';
import { IDivisionUpsert } from '../../@types/model/masterData/division/divisionUpsert';
import { ISubdivisionUpsert } from '../../@types/model/masterData/subdivision/subdivisionUpsert';
import { IDepartmentUpsert } from '../../@types/model/masterData/department/departmentUpsert';
import { IProjectUpsert } from '../../@types/model/masterData/project/projectUpsert';
import { IProductionUnitUpsert } from '../../@types/model/masterData/productionUnit/productionUnitUpsert';
import { IFieldUpsert } from '../../@types/model/masterData/field/fieldUpsert';
import { IBlockUpsert } from '../../@types/model/masterData/block/blockUpsert';
import { ICommodityUpsert } from '../../@types/model/masterData/commodity/commodityUpsert';
import { IVarietyUpsert } from '../../@types/model/masterData/variety/varietyUpsert';
import { INurseryUpsert } from '../../@types/model/masterData/nursery/nurseryUpsert';
import { IRootStockUpsert } from '../../@types/model/masterData/rootStock/rootStockUpsert';
import { IUnitOfMeasureUpsert } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasureUpsert';
import { IChemicalProductUpsert } from '../../@types/model/masterData/chemicals/chemicalProduct/chemicalProductUpsert';
import { IChemicalElementUpsert } from '../../@types/model/masterData/chemicals/chemicalElement/chemicalElementUpsert';
import { IChemicalCategoryUpsert } from '../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategoryUpsert';
import { IChemicalFormulationUpsert } from '../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulationUpsert';
import { IChemicalProductElementBridgeUpsert } from '../../@types/model/masterData/chemicals/chemicalProductElement/chemicalProductElementUpsert';
import { IActiveIngredientUpsert } from '../../@types/model/masterData/chemicals/activeIngredient/activeIngredientUpsert';
import { IChemicalProductActiveIngredientBridgeUpsert } from '../../@types/model/masterData/chemicals/chemicalProductActiveIngredient/chemicalProductActiveIngredientUpsert';
import { ISafetyPrecautionUpsert } from '../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecautionUpsert';
import { ISafetyPrecautionPpeUpsert } from '../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpeUpsert';
import { IIrrigationCycleUpsert } from '../../@types/model/masterData/irrigationCycle/irrigationCycleUpsert';
import { IPlantsPerHectareUpsert } from '../../@types/model/masterData/plantsPerHectare/plantsPerHectareUpsert';
import { IPlantsPerHectare } from '../../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import PlantsPerHectareHttpService from '../../service/http/masterData/plantsPerHectareHttpService';
import { ISeedTraySize } from '../../@types/model/masterData/seedTraySize/seedTraySize';
import { ISeedTraySizeUpsert } from '../../@types/model/masterData/seedTraySize/seedTraySizeUpsert';
import SeedTraySizeHttpService from '../../service/http/masterData/seedTraySizeHttpService';
import { ISprayMethodUpsert } from '../../@types/model/masterData/sprayMethod/sprayMethodUpsert';
import { ISprayMethod } from '../../@types/model/masterData/sprayMethod/splayMethod';
import SprayMethodHttpService from '../../service/http/masterData/sprayMethodHttpService';
import { IProjectBlockBridgeUpsert } from '../../@types/model/masterData/project/projectBlockBridgeUpsert';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';
import SyncHttpService from '../../service/http/masterData/syncHttpService';
import { getMasterDataLastSyncDateLocalStorage, setMasterDataLastSyncDateLocalStorage } from '../../service/localStorageService';
import { clearIndexedDBObjectStores, setActiveIngredientMasterDataIndexedDB, setActivityMasterDataIndexedDB, setActivityTypeMasterDataIndexedDB, setBlockMasterDataIndexedDB, setChemicalCategoryMasterDataIndexedDB, setChemicalElementMasterDataIndexedDB, setChemicalFormulationMasterDataIndexedDB, setChemicalProductMasterDataIndexedDB, setCommodityMasterDataIndexedDB, setDepartmentMasterDataIndexedDB, setDivisionMasterDataIndexedDB, setFieldMasterDataIndexedDB, setIndexedDBMasterData, setNurseryMasterDataIndexedDB, setPlantsPerHectareMasterDataIndexedDB, setPriorityMasterDataIndexedDB, setProductionUnitMasterDataIndexedDB, setProjectMasterDataIndexedDB, setRootStockMasterDataIndexedDB, setSafetyPrecautionMasterDataIndexedDB, setSafetyPrecautionPpeMasterDataIndexedDB, setSeedTraySizeMasterDataIndexedDB, setSprayMethodMasterDataIndexedDB, setSubdivisionMasterDataIndexedDB, setSystemMasterDataIndexedDB, setUnitOfMeasureMasterDataIndexedDB, setVarietyMasterDataIndexedDB } from '../../service/masterDataSyncService';
import QcMasterDataActions from '../qcMasterData/actions';
import { ISystemUpsert } from '../../@types/model/masterData/system/systemUpsert';
import { ISystem } from '../../@types/model/masterData/system/system';
import SystemHttpService from '../../service/http/masterData/systemHttpService';
import { IActivity } from '../../@types/model/masterData/activity/activity';
import { IActivityUpsert } from '../../@types/model/masterData/activity/activityUpsert';
import ActivityHttpService from '../../service/http/masterData/activityHttpService';
import ActivityTypeHttpService from '../../service/http/masterData/activityTypeHttpService';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IActivityTypeUpsert } from '../../@types/model/masterData/activityType/activityTypeUpsert';
import { ArrayHelper } from '@zz2/zz2-ui';
import { IPriority } from '../../@types/model/masterData/priority/priority';
import { IPriorityUpsert } from '../../@types/model/masterData/priority/priorityUpsert';
import PriorityHttpService from '../../service/http/masterData/priorityHttpService';

export default class DataThunks {
    /**
     * Sync all master data filtered by last sync date from the API,
     * Updating the redux state and indexedDB object stores once complete.
     *
     * @returns {IMasterDataSync | null}
     */
    public static syncMasterData = createAsyncThunk<
        IMasterDataSync | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_SYNC_ALL',
            async (params, thunkApi) => {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                let result : IMasterDataSync | null = null;

                try {
                    // Retrieved last synced value from local storage
                    const lastSyncDate : moment.Moment | null = getMasterDataLastSyncDateLocalStorage();
                
                    const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');
                    
                    // Update last sync value to new value
                    setMasterDataLastSyncDateLocalStorage(newMasterDataLastSyncDate);
    
                    const lastSyncDateUnix = lastSyncDate ? moment(lastSyncDate).unix() * 1000 : null;

                    const res = await SyncHttpService.masterDataSyncAll(lastSyncDateUnix);

                    result = res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying to load master data.', e }));
                        thunkApi.dispatch(DataActions.setIsLoading(false));
                    }
                    return null;
                }

                try {
                    // Update indexedDB master data objects stores with new values
                    const updatedMasterData = await setIndexedDBMasterData(result);

                    // Update redux with new master data
                    thunkApi.dispatch(DataActions.setSyncedMasterData(updatedMasterData));
                    thunkApi.dispatch(QcMasterDataActions.setSyncedQCMasterData(updatedMasterData));

                    return updatedMasterData;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying update master data.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Sync all master data from the API,
     * Updating the redux state and indexedDB object stores once complete.
     *
     * @returns {IMasterDataSync | null}
     */
    public static manualSyncAllMasterData = createAsyncThunk<
        IMasterDataSync | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_MANUAL_SYNC_ALL',
            async (params, thunkApi) => {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                let result : IMasterDataSync | null = null;

                try {
                    const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');
                    
                    // Update last sync value to new value
                    setMasterDataLastSyncDateLocalStorage(newMasterDataLastSyncDate);

                    const res = await SyncHttpService.masterDataSyncAll(null);

                    result = res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying to load master data.', e }));
                        thunkApi.dispatch(DataActions.setIsLoading(false));
                    }
                    return null;
                }

                try {
                    // clears all indexedDB object stores before updating
                    await clearIndexedDBObjectStores();

                    // Update indexedDB master data objects stores with new values
                    const updatedMasterData = await setIndexedDBMasterData(result);

                    // Update redux with new master data
                    thunkApi.dispatch(DataActions.setSyncedMasterData(updatedMasterData));
                    thunkApi.dispatch(QcMasterDataActions.setSyncedQCMasterData(updatedMasterData));

                    return updatedMasterData;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying update master data.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of divisions from the API, updating the redux state once complete. Performs no
     * action if no divisions have been retrieved.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<IDivision> | null}
     */
    public static getDivisionList = createAsyncThunk<
        Array<IDivision> | null,
        Array<number>,
        ThunkApi>(
            'MASTER_DATA_LOAD_DIVISIONS',
            async (divisionIds, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DivisionHttpService.masterDataDivisionGetList(divisionIds);

                    thunkApi.dispatch(DataActions.setDivisionData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading divisions.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of subdivisions filtered on the division ids from the API, updating the redux state once complete. Performs no
     * action if no subdivisions have been retrieved.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<ISubdivision> | null}
     */

    public static getSubdivisionListByDivision = createAsyncThunk<
        Array<ISubdivision> | null,
        Array<number>,
        ThunkApi>(
            'MASTER_DATA_LOAD_SUBDIVISIONS_BY_DIVISION',
            async (divisionIds, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await SubdivisionHttpService.masterDataSubdivisionByDivisionGetList(divisionIds);

                    thunkApi.dispatch(DataActions.setSubdivisionData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading subdivisions.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of production units from the API, updating the redux state once complete. Performs no
     * action if no production units have been retrieved.
     *
     * @returns {Array<IProductionUnit> | null}
     */
    public static getProductionUnitList = createAsyncThunk<
        Array<IProductionUnit> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_PRODUCTION_UNITS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ProductionUnitHttpService.masterDataProductionUnitGetList();

                    thunkApi.dispatch(DataActions.setProductionUnitData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading production units.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of production units filtered on subdivision id from the API, updating the redux state once complete. Performs no
     * action if no production units have been retrieved.
     *
     * @param {number} subdivisionId
     * @returns {Array<IProductionUnit> | null}
     */
    public static getProductionUnitListBySubdivision = createAsyncThunk<
        Array<IProductionUnit> | null,
        number,
        ThunkApi>(
            'MASTER_DATA_LOAD_PRODUCTION_UNITS_BY_SUBDIVISION',
            async (subdivisionId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ProductionUnitHttpService.masterDataProductionUnitBySubdivisionGetList(subdivisionId);

                    thunkApi.dispatch(DataActions.setProductionUnitData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading production units.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of field from the API, updating the redux state once complete. Performs no
     * action if no field have been retrieved.
     *
     * @param {Array<number> | undefined} departmentIds
     * @param {Array<number> | undefined} productionUnitIds
     * @returns {Array<IField> | null}
     */
    public static getFieldList = createAsyncThunk<
        Array<IField> | null,
        {
            departmentIds ?: Array<number>;
            productionUnitIds ?: Array<number>;
        } | undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_FIELDS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await FieldHttpService.masterDataFieldGetList(params?.departmentIds, params?.productionUnitIds);

                    thunkApi.dispatch(DataActions.setFieldData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading fields.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of blocks from the API, updating the redux state once complete. Performs no
     * action if no blocks have been retrieved.
     *
     * @param {Array<number>} departmentIds
     * @param {number | undefined} divisionId
     * @param {number | undefined} productionUnitId
     * @param {number | undefined} fieldId
     * @param {number | undefined} projectId
     * @returns {Array<IBlock> | null}
     */
    public static getBlockList = createAsyncThunk<
        Array<IBlock> | null,
        {
            departmentIds : Array<number>;
            divisionId ?: number;
            departmentId ?: number;
            productionUnitId ?: number;
            fieldId ?: number;
            projectId ?: number;
        },
        ThunkApi>(
            'MASTER_DATA_LOAD_BLOCKS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await BlockHttpService.masterDataBlockGetList(params.departmentIds, params.divisionId, params.productionUnitId, params.fieldId, params.projectId);

                    thunkApi.dispatch(DataActions.setBlockData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading blocks.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of block views from the API, updating the redux state once complete. Performs no
     * action if no block views have been retrieved.
     *
     * @returns {Array<IBlockHomeView> | null}
     */
    public static getBlockHomeViewList = createAsyncThunk<
        Array<IBlockHomeView> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_BLOCKS_VIEWS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await BlockHttpService.masterDataBlockGetListFiltered();

                    thunkApi.dispatch(DataActions.setBlockHomeViewData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading home screen data.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the block matching the block id from the API, updating the redux state once complete. Performs no
     * action if the block have been retrieved.
     *
     * @param {number} blockId
     * @returns {IBlock | null}
     */
    public static getBlock = createAsyncThunk<
        IBlock | null,
        number,
        ThunkApi>(
            'MASTER_DATA_LOAD_BLOCK',
            async (blockId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await BlockHttpService.masterDataGetBlock(blockId);

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading specified block.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of commodities from the API, updating the redux state once complete. Performs no
     * action if no commodities have been retrieved.
     *
     * @returns {Array<ICommodity> | null}
     */
    public static getCommodityList = createAsyncThunk<
        Array<ICommodity> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_COMMODITIES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await CommodityHttpService.masterDataCommodityGetList();

                    thunkApi.dispatch(DataActions.setCommodityData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading commodities.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of varieties from the API, updating the redux state once complete. Performs no
     * action if no varieties have been retrieved.
     *
     * @returns {Array<IVariety> | null}
     */
    public static getVarietyList = createAsyncThunk<
        Array<IVariety> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_VARIETIES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await VarietyHttpService.masterDataVarietyGetList();

                    thunkApi.dispatch(DataActions.setVarietyData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading varieties.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of nurseries from the API, updating the redux state once complete. Performs no
     * action if no nurseries have been retrieved.
     *
     * @returns {Array<INursery> | null}
     */
    public static getNurseryList = createAsyncThunk<
        Array<INursery> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_NURSERIES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await NurseryHttpService.masterDataNurseryGetList();

                    thunkApi.dispatch(DataActions.setNurseryData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading nurseries.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the root stocks from the API, updating the redux state once complete. Performs no
     * action if no root stocks have been retrieved.
     *
     * @returns {Array<IRootStock> | null}
     */
    public static getRootStockList = createAsyncThunk<
        Array<IRootStock> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_ROOT_STOCKS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await RootStockHttpService.masterDataRootStockGetList();

                    thunkApi.dispatch(DataActions.setRootStockData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading root stocks.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of unit Of Measures from the API, updating the redux state once complete. Performs no
     * action if no unit Of Measures have been retrieved.
     *
     * @returns {Array<IUnitOfMeasure> | null}
     */
    public static getUnitOfMeasureList = createAsyncThunk<
        Array<IUnitOfMeasure> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_UNIT_OF_MEASURES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await UnitOfMeasureHttpService.masterDataUnitOfMeasureGetList();

                    thunkApi.dispatch(DataActions.setUnitOfMeasureData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading Unit Of Measures.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the chemical products from the API, updating the redux state once complete. Performs no
     * action if no chemical products have been retrieved.
     *
     * @returns {Array<IChemicalProduct> | null}
     */
    public static getChemicalProductList = createAsyncThunk<
        Array<IChemicalProduct> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_CHEMICAL_PRODUCTS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalProductHttpService.masterDataChemicalProductGetList();

                    thunkApi.dispatch(DataActions.setChemicalProductData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading chemical products.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the chemical elements from the API, updating the redux state once complete. Performs no
     * action if no chemical elements have been retrieved.
     *
     * @returns {Array<IChemicalElement> | null}
     */
    public static getChemicalElementList = createAsyncThunk<
        Array<IChemicalElement> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_CHEMICAL_ELEMENTS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalElementHttpService.masterDataChemicalElementGetList();

                    thunkApi.dispatch(DataActions.setChemicalElementData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading chemical elements.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the chemical product element bridges from the API, updating the redux state once complete. Performs no
     * action if no chemical product element bridges have been retrieved.
     *
     * @returns {Array<IChemicalProductElementBridge> | null}
     */
    public static getChemicalProductElementBridgeList = createAsyncThunk<
        Array<IChemicalProductElementBridge> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_CHEMICAL_PRODUCT_ELEMENT_BRIDGES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalProductElementHttpService.masterDataChemicalProductElementBridgeGetList();

                    thunkApi.dispatch(DataActions.setChemicalProductElementBridgeData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading chemical product element bridges.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the active ingredients from the API, updating the redux state once complete. Performs no
     * action if no active ingredients have been retrieved.
     *
     * @returns {Array<IActiveIngredient> | null}
     */
    public static getActiveIngredientList = createAsyncThunk<
        Array<IActiveIngredient> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_ACTIVE_INGREDIENTS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ActiveIngredientHttpService.masterDataActiveIngredientGetList();

                    thunkApi.dispatch(DataActions.setActiveIngredientData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading active ingredients.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the chemical categories from the API, updating the redux state once complete. Performs no
     * action if no chemical categories have been retrieved.
     *
     * @returns {Array<IChemicalCategory> | null}
     */
    public static getChemicalCategoryList = createAsyncThunk<
        Array<IChemicalCategory> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_CHEMICAL_CATEGORIES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalCategoryHttpService.masterDataChemicalCategoryGetList();

                    thunkApi.dispatch(DataActions.setChemicalCategoryData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading chemical categories.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the chemical formulation from the API, updating the redux state once complete. Performs no
     * action if no chemical formulation have been retrieved.
     *
     * @returns {Array<IChemicalFormulation> | null}
     */
    public static getChemicalFormulationList = createAsyncThunk<
        Array<IChemicalFormulation> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_CHEMICAL_FORMULATIONS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalFormulationHttpService.masterDataChemicalFormulationGetList();

                    thunkApi.dispatch(DataActions.setChemicalFormulationData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading chemical formulation.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the chemical product active ingredient bridges from the API, updating the redux state once complete. Performs no
     * action if no chemical product active ingredient bridges have been retrieved.
     *
     * @returns {Array<IChemicalProductActiveIngredientBridge> | null}
     */
    public static getChemicalProductActiveIngredientBridgeList = createAsyncThunk<
        Array<IChemicalProductActiveIngredientBridge> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_CHEMICAL_PRODUCT_ACTIVE_INGREDIENT_BRIDGES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalProductActiveIngredientHttpService.masterDataChemicalProductActiveIngredientBridgeGetList();

                    thunkApi.dispatch(DataActions.setChemicalProductActiveIngredientBridgeData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading chemical product active ingredient bridges.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the safety precautions from the API, updating the redux state once complete. Performs no
     * action if no safety precautions have been retrieved.
     *
     * @returns {Array<ISafetyPrecaution> | null}
     */
    public static getSafetyPrecautionList = createAsyncThunk<
        Array<ISafetyPrecaution> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_SAFETY_PRECAUTION',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await SafetyPrecautionHttpService.masterDataSafetyPrecautionGetList();

                    thunkApi.dispatch(DataActions.setSafetyPrecautionData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading safety precautions.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the safety precaution ppes from the API, updating the redux state once complete. Performs no
     * action if no safety precaution ppes have been retrieved.
     *
     * @returns {Array<ISafetyPrecautionPpe> | null}
     */
    public static getSafetyPrecautionPpeList = createAsyncThunk<
        Array<ISafetyPrecautionPpe> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_SAFETY_PRECAUTION_PPES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await SafetyPrecautionPpeHttpService.masterDataSafetyPrecautionPpeGetList();

                    thunkApi.dispatch(DataActions.setSafetyPrecautionPpeData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading safety precaution PPEs.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of the irrigation cycles from the API, updating the redux state once complete. Performs no
     * action if no irrigation cycles have been retrieved.
     *
     * @param {List<number>} productionUnitIds
     * @returns {Array<IIrrigationCycle> | null}
     */
    public static getIrrigationCycleListByProductionUnit = createAsyncThunk<
        Array<IIrrigationCycle> | null,
        Array<number>,
        ThunkApi>(
            'MASTER_DATA_LOAD_IRRIGATION_CYCLES_BY_PRODUCTION_UNITS',
            async (productionUnitIds, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await IrrigationCycleHttpService.masterDataIrrigationCycleByProductionUnitGetList(productionUnitIds);

                    thunkApi.dispatch(DataActions.setIrrigationCycleData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading irrigation cycles.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of projects from the API, updating the redux state once complete. Performs no
     * action if no projects have been retrieved.
     *
     * @returns {Array<IProject> | null}
     */
    public static getProjectList = createAsyncThunk<
        Array<IProject> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_PROJECTS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ProjectHttpService.masterDataProjectGetList();

                    thunkApi.dispatch(DataActions.setProjectData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading projects.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of departments filtered on subdivision id from the API, updating the redux state once complete. Performs no
     * action if no departments have been retrieved.
     *
     * @param {number} subdivisionId
     * @returns {Array<IDepartment> | null}
     */
    public static getDepartmentListBySubdivision = createAsyncThunk<
        Array<IDepartment> | null,
        number,
        ThunkApi>(
            'MASTER_DATA_LOAD_DEPARTMENTS_BY_SUBDIVISION',
            async (subdivisionId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DepartmentHttpService.masterDataDepartmentBySubdivisionGetList(subdivisionId);

                    thunkApi.dispatch(DataActions.setDepartmentData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading departments.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of departments filtered given ids from the API, updating the redux state once complete. Performs no
     * action if no departments have been retrieved.
     *
     * @param {Array<number>} departmentIds
     * @returns {Array<IDepartment> | null}
     */
    public static getDepartmentListByIds = createAsyncThunk<
        Array<IDepartment> | null,
        Array<number>,
        ThunkApi>(
            'MASTER_DATA_LOAD_DEPARTMENTS_BY_SUBDIVISION',
            async (departmentIds, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DepartmentHttpService.masterDataDepartmentByIdsGetList(departmentIds);

                    thunkApi.dispatch(DataActions.setDepartmentData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading departments.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of plants per hectare from the API, updating the redux state once complete. Performs no
     * action if no plants per hectare have been retrieved.
     *
     * @returns {Array<IPlantsPerHectare> | null}
     */
    public static getPlantsPerHectareList = createAsyncThunk<
        Array<IPlantsPerHectare> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_PLANTS_PER_HECTARE',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await PlantsPerHectareHttpService.masterDataPlantsPerHectareGetList();

                    thunkApi.dispatch(DataActions.setPlantsPerHectareData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading plants per hectare.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of seed tray sizes from the API, updating the redux state once complete. Performs no
     * action if no seed tray sizes have been retrieved.
     *
     * @returns {Array<ISeedTraySize> | null}
     */
    public static getSeedTraySizeList = createAsyncThunk<
        Array<ISeedTraySize> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_SEED_TRAY_SIZE',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await SeedTraySizeHttpService.masterDataSeedTraySizeGetList();

                    thunkApi.dispatch(DataActions.setSeedTraySizeData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading seed tray size.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of spray methods from the API, updating the redux state once complete. Performs no
     * action if no spray methods have been retrieved.
     *
     * @returns {Array<ISprayMethod> | null}
     */
    public static getSprayMethodList = createAsyncThunk<
        Array<ISprayMethod> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_SPRAY_METHOD',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await SprayMethodHttpService.masterDataSprayMethodGetList();

                    thunkApi.dispatch(DataActions.setSprayMethodData(res.data));

                    return res.data;
                } catch (e) {
                    /**
                     * Only show error if there is actually an error.
                     * Cancelation errors are empty.
                     */
                    if (e) {
                        thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading seed tray size.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of systems from the API, updating the redux state once complete. Performs no
     * action if no systems have been retrieved.
     *
     * @returns {Array<ISystem> | null}
     */
    public static getSystemList = createAsyncThunk<
    Array<ISystem> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_SYSTEMS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await SystemHttpService.masterDataSystemGetList();

                thunkApi.dispatch(DataActions.setSystemData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading the systems.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of priorities from the API, updating the redux state once complete. Performs no
     * action if no priorities have been retrieved.
     *
     * @returns {Array<ISystem> | null}
     */
    public static getPriorityList = createAsyncThunk<
    Array<IPriority> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_PRIORITIES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await PriorityHttpService.masterDataPriorityGetList();

                thunkApi.dispatch(DataActions.setPriorityData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading the priorities.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of activities from the API, updating the redux state once complete. Performs no
     * action if no activities have been retrieved.
     *
     * @returns {Array<IActivity> | null}
     */
    public static getActivityList = createAsyncThunk<
    Array<IActivity> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_ACTIVITIES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityHttpService.masterDataActivityGetList();

                thunkApi.dispatch(DataActions.setActivityData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading the activities.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of activity types from the API, updating the redux state once complete. Performs no
     * action if no activity types have been retrieved.
     *
     * @returns {Array<IActivityType> | null}
     */
    public static getActivityTypeList = createAsyncThunk<
    Array<IActivityType> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_ACTIVITY_TYPE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityTypeHttpService.masterDataActivityTypeGetList();

                thunkApi.dispatch(DataActions.setActivityTypeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading the activity types.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /****************************** UPSERT *********************************/

    /**
     * Inserts a division, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDivisionUpsert} divisionUpsert
     * @returns {IDivision | null}
     */
    public static upsertDivision = createAsyncThunk<
        IDivision | null,
        IDivisionUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_DIVISION',
            async (divisionUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const divisions = state.data.divisionData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DivisionHttpService.divisionUpsert(divisionUpsert);

                    const newList = ArrayHelper.upsertElement(divisions, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setDivisionMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setDivisionData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the division.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a subdivision, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISubdivisionUpsert} subdivisionUpsert
     * @returns {ISubdivision | null}
     */
    public static upsertSubdivision = createAsyncThunk<
        ISubdivision | null,
        ISubdivisionUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_SUBDIVISION',
            async (subdivisionUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const subdivisions = state.data.subdivisionData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await SubdivisionHttpService.subdivisionUpsert(subdivisionUpsert);

                    const newList = ArrayHelper.upsertElement(subdivisions, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setSubdivisionMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setSubdivisionData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the subdivision.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a department, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDepartmentUpsert} departmentUpsert
     * @returns {IDepartment | null}
     */
    public static upsertDepartment = createAsyncThunk<
        IDepartment | null,
        IDepartmentUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_DEPARTMENT',
            async (departmentUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const departments = state.data.departmentData;

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DepartmentHttpService.departmentUpsert(departmentUpsert);

                    const newList = ArrayHelper.upsertElement(departments, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setDepartmentMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setDepartmentData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the department.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a project, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IProjectUpsert} projectUpsert
     * @returns {IProject | null}
     */
    public static upsertProject = createAsyncThunk<
        IProject | null,
        IProjectUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_PROJECTS',
            async (projectUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const projects = state.data.projectData;

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ProjectHttpService.projectUpsert(projectUpsert);

                    const newList = ArrayHelper.upsertElement(projects, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setProjectMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setProjectData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the project.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a project block bridge, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IProjectBlockBridgeUpsert} projectBlockBridgeUpsert
     * @returns {IProject | null}
     */
    public static upsertProjectBlockBridge = createAsyncThunk<
        IProject | null,
        IProjectBlockBridgeUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_PROJECT_BLOCK_BRIDGES',
            async (projectBlockBridgeUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const projects = state.data.projectData;

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ProjectHttpService.projectBlockBridgeUpsert(projectBlockBridgeUpsert);

                    const newList = ArrayHelper.upsertElement(projects, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setProjectMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setProjectData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the project.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a production unit, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IProductionUnitUpsert} productionUnitUpsert
     * @returns {IProductionUnit | null}
     */
    public static upsertProductionUnit = createAsyncThunk<
        IProductionUnit | null,
        IProductionUnitUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_PRODUCTION_UNIT',
            async (productionUnitUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const productionUnits = state.data.productionUnitData;

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ProductionUnitHttpService.productionUnitUpsert(productionUnitUpsert);

                    const newList = ArrayHelper.upsertElement(productionUnits, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setProductionUnitMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setProductionUnitData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the production unit.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a field, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IFieldUpsert} fieldUpsert
     * @returns {IField | null}
     */
    public static upsertField = createAsyncThunk<
        IField | null,
        IFieldUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_FIELD',
            async (fieldUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const fields = state.data.fieldData;

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await FieldHttpService.fieldUpsert(fieldUpsert);

                    const newList = ArrayHelper.upsertElement(fields, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setFieldMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setFieldData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the field.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a list whether it be a single block or bulk, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {Array<IBlockUpsert>} blockUpsertList
     * @param {number} fieldId
     * @returns {Array<IBlock> | null}
     */
    public static upsertBlocks = createAsyncThunk<
        Array<IBlock> | null,
        {
            blocks : Array<IBlockUpsert>;
            fieldId : number;
        },
        ThunkApi>(
            'MASTER_DATA_UPSERT_BLOCKS',
            async (params, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const blockList = lodash.map(state.data.blockData, x => x);

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await BlockHttpService.blockUpsert(params.blocks, params.fieldId);

                    const newList = ArrayHelper.upsertElements(blockList, res.data, (a, b) => a.id === b.id);

                    if (newList)
                        await setBlockMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setBlockData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entries saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the block(s).', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a commodity, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ICommodity} commodityUpsert
     * @returns {Promise<ICommodity | null>}
     */
    public static upsertCommodity = createAsyncThunk<
        ICommodity | null,
        ICommodityUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_COMMODITY',
            async (commodityUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const commodities = state.data.commodityData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await CommodityHttpService.commodityUpsert(commodityUpsert);

                    const newList = ArrayHelper.upsertElement(commodities, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setCommodityMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setCommodityData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the commodity.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a variety, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IVarietyUpsert} varietyUpsert
     * @returns {IVariety | null}
     */
    public static upsertVariety = createAsyncThunk<
        IVariety | null,
        IVarietyUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_VARIETY',
            async (varietyUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const varieties = state.data.varietyData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await VarietyHttpService.varietyUpsert(varietyUpsert);

                    const newList = ArrayHelper.upsertElement(varieties, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setVarietyMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setVarietyData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the variety.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upserts a nursery, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {INurseryUpsert} nurseryUpsert
     * @param {Array<number>} commodityIds
     * @returns {INursery | null}
     */
    public static upsertNursery = createAsyncThunk<
        INursery | null,
        {
            nurseryUpsert : INurseryUpsert;
            commodityIds : Array<number>;
        },
        ThunkApi>(
            'MASTER_DATA_UPSERT_NURSERY',
            async (params, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const nurseries = state.data.nurseryData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await NurseryHttpService.nurseryUpsert(params.nurseryUpsert, params.commodityIds);

                    const newList = ArrayHelper.upsertElement(nurseries, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setNurseryMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setNurseryData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the nursery.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a root stock, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IRootStockUpsert} rootStockUpsert
     * @returns {IRootStock | null}
     */
    public static upsertRootStock = createAsyncThunk<
        IRootStock | null,
        IRootStockUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_ROOT_STOCK',
            async (rootStockUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const rootStocks = state.data.rootStockData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await RootStockHttpService.rootStockUpsert(rootStockUpsert);

                    const newList = ArrayHelper.upsertElement(rootStocks, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setRootStockMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setRootStockData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the root stock.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a unit of measure, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IUnitOfMeasureUpsert} unitOfMeasureUpsert
     * @returns {IUnitOfMeasure | null}
     */
    public static upsertUnitOfMeasure = createAsyncThunk<
        IUnitOfMeasure | null,
        IUnitOfMeasureUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_UNIT_OF_MEASURE',
            async (unitOfMeasureUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const unitOfMeasures = state.data.unitOfMeasureData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await UnitOfMeasureHttpService.unitOfMeasureUpsert(unitOfMeasureUpsert);

                    const newList = ArrayHelper.upsertElement(unitOfMeasures, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setUnitOfMeasureMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setUnitOfMeasureData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the unit of measure.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a chemical product, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IChemicalProductUpsert} chemicalProductUpsert
     * @param {Array<number>} chemicalCategoryIds
     * @returns {IChemicalProduct | null}
     */
    public static upsertChemicalProduct = createAsyncThunk<
        IChemicalProduct | null,
        {
            chemicalProductUpsert : IChemicalProductUpsert;
            chemicalCategoryIds : Array<number>;
        },
        ThunkApi>(
            'MASTER_DATA_UPSERT_CHEMICAL_PRODUCT',
            async (params, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const chemicalProducts = state.data.chemicalProductData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalProductHttpService.chemicalProductUpsert(params.chemicalProductUpsert, params.chemicalCategoryIds);

                    const newList = ArrayHelper.upsertElement(chemicalProducts, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setChemicalProductMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setChemicalProductData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the chemical product.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a chemical element, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IChemicalElementUpsert} chemicalElementUpsert
     * @returns {IChemicalElement | null}
     */
    public static upsertChemicalElement = createAsyncThunk<
        IChemicalElement | null,
        IChemicalElementUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_CHEMICAL_ELEMENT',
            async (chemicalElementUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const chemicalElements = state.data.chemicalElementData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalElementHttpService.chemicalElementUpsert(chemicalElementUpsert);

                    const newList = ArrayHelper.upsertElement(chemicalElements, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setChemicalElementMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setChemicalElementData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the chemical element.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a chemical category, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IChemicalCategoryUpsert} data
     * @returns {IChemicalCategory | null}
     */
    public static upsertChemicalCategory = createAsyncThunk<
        IChemicalCategory | null,
        IChemicalCategoryUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_CHEMICAL_CATEGORY',
            async (chemicalCategoryUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const chemicalCategories = state.data.chemicalCategoryData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalCategoryHttpService.chemicalCategoryUpsert(chemicalCategoryUpsert);

                    const newList = ArrayHelper.upsertElement(chemicalCategories, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setChemicalCategoryMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setChemicalCategoryData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the chemical category.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a chemical formulation, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IChemicalFormulationUpsert} chemicalFormulationUpsert
     * @returns {IChemicalFormulation | null}
     */
    public static upsertChemicalFormulation = createAsyncThunk<
        IChemicalFormulation | null,
        IChemicalFormulationUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_CHEMICAL_FORMULATION',
            async (chemicalFormulationUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const chemicalFormulations = state.data.chemicalFormulationData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalFormulationHttpService.chemicalFormulationUpsert(chemicalFormulationUpsert);

                    const newList = ArrayHelper.upsertElement(chemicalFormulations, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setChemicalFormulationMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setChemicalFormulationData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the chemical formulation.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a chemical product element, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IChemicalProductElementBridgeUpsert} chemicalProductElementBridgeUpsert
     * @returns {IChemicalProductElementBridge | null}
     */
    public static upsertChemicalProductElementBridge = createAsyncThunk<
        IChemicalProductElementBridge | null,
        IChemicalProductElementBridgeUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_CHEMICAL_PRODUCT_ELEMENT_BRIDGE',
            async (chemicalProductElementBridgeUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const chemicalProductElementBridges = state.data.chemicalProductElementBridgeData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await ChemicalProductElementHttpService.chemicalProductElementBridgeUpsert(chemicalProductElementBridgeUpsert);

                    const newList = ArrayHelper.upsertElement(chemicalProductElementBridges, res.data, a => a.id === res.data.id);
                    thunkApi.dispatch(DataActions.setChemicalProductElementBridgeData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the chemical product element.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a active ingredient, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActiveIngredientUpsert} activeIngredientUpsert
     * @returns {IActiveIngredient | null}
     */
    public static upsertActiveIngredient = createAsyncThunk<
        IActiveIngredient | null,
        IActiveIngredientUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_ACTIVE_INGREDIENT',
            async (activeIngredientUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const activeIngredients = state.data.activeIngredientData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    const res = await ActiveIngredientHttpService.activeIngredientUpsert(activeIngredientUpsert);

                    const newList = ArrayHelper.upsertElement(activeIngredients, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setActiveIngredientMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setActiveIngredientData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the active ingredient.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a chemical product active ingredient, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IChemicalProductActiveIngredientBridgeUpsert} chemicalProductActiveIngredientBridgeUpsert
     * @returns {IChemicalProductActiveIngredientBridge | null}
     */
    public static upsertChemicalProductActiveIngredientBridge = createAsyncThunk<
        IChemicalProductActiveIngredientBridge | null,
        IChemicalProductActiveIngredientBridgeUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_CHEMICAL_PRODUCT_ACTIVE_INGREDIENT_BRIDGE',
            async (chemicalProductActiveIngredientBridgeUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const chemicalProductActiveIngredientBridges = state.data.chemicalProductActiveIngredientBridgeData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    const res = await ChemicalProductActiveIngredientHttpService.chemicalProductActiveIngredientBridgeUpsert(chemicalProductActiveIngredientBridgeUpsert);

                    const newList = ArrayHelper.upsertElement(chemicalProductActiveIngredientBridges, res.data, a => a.id === res.data.id);
                    thunkApi.dispatch(DataActions.setChemicalProductActiveIngredientBridgeData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the chemical product active ingredient.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a safety precaution, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISafetyPrecautionUpsert} safetyPrecautionUpsert
     * @param {List<number>} ppeIds
     * @returns {ISafetyPrecaution | null}
     */
    public static upsertSafetyPrecaution = createAsyncThunk<
        ISafetyPrecaution | null,
        {
            safetyPrecautionUpsert : ISafetyPrecautionUpsert;
            ppeIds : Array<number>;
        },
        ThunkApi>(
            'MASTER_DATA_UPSERT_SAFETY_PRECAUTION',
            async (params, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const safetyPrecautions = state.data.safetyPrecautionData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    const res = await SafetyPrecautionHttpService.safetyPrecautionUpsert(params.safetyPrecautionUpsert, params.ppeIds);

                    const newList = ArrayHelper.upsertElement(safetyPrecautions, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setSafetyPrecautionMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setSafetyPrecautionData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the safety precaution.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Upsert a safety precaution ppe, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISafetyPrecautionPpeUpsert} safetyPrecautionPpeUpsert
     * @returns {ISafetyPrecautionPpe | null}
     */
    public static upsertSafetyPrecautionPpe = createAsyncThunk<
        ISafetyPrecautionPpe | null,
        ISafetyPrecautionPpeUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_SAFETY_PRECAUTION_PPE',
            async (safetyPrecautionPpeUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const safetyPrecautionPpes = state.data.safetyPrecautionPpeData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    const res = await SafetyPrecautionPpeHttpService.safetyPrecautionPpeUpsert(safetyPrecautionPpeUpsert);

                    const newList = ArrayHelper.upsertElement(safetyPrecautionPpes, res.data, a => a.id === res.data.id);

                    if (newList)
                        await setSafetyPrecautionPpeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setSafetyPrecautionPpeData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the safety precaution PPE.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a irrigation cycle, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IIrrigationCycleUpsert} irrigationCycleUpsert
     * @returns {IIrrigationCycle | null}
     */
    public static upsertIrrigationCycle = createAsyncThunk<
        IIrrigationCycle | null,
        IIrrigationCycleUpsert,
        ThunkApi>(
            'MASTER_DATA_UPSERT_IRRIGATION_CYCLE',
            async (irrigationCycleUpsert, thunkApi) => {
                try {
                    const state = thunkApi.getState();
                    const irrigationCycles = state.data.irrigationCycleData ?? [];

                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    const res = await IrrigationCycleHttpService.irrigationCycleUpsert(irrigationCycleUpsert);

                    const newList = ArrayHelper.upsertElement(irrigationCycles, res.data, a => a.id === res.data.id);
                    thunkApi.dispatch(DataActions.setIrrigationCycleData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the irrigation cycle.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Inserts a plants per hectare item, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IPlantsPerHectareUpsert} plantsPerHectareUpsert
     * @returns {IPlantsPerHectare | null}
     */
    public static upsertPlantsPerHectare = createAsyncThunk<
    IPlantsPerHectare | null,
    IPlantsPerHectareUpsert,
    ThunkApi>(
        'MASTER_DATA_UPSERT_PLANTS_PER_HECTARE',
        async (plantsPerHectareUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const plantsPerHectare = state.data.plantsPerHectareData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                const res = await PlantsPerHectareHttpService.plantsPerHectareUpsert(plantsPerHectareUpsert);

                const newList = ArrayHelper.upsertElement(plantsPerHectare, res.data, a => a.id === res.data.id);

                if (newList)
                    await setPlantsPerHectareMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setPlantsPerHectareData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving plants per hectare.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a spray method item, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISprayMethodUpsert} sprayMethodUpsert
     * @returns {ISprayMethod | null}
     */
    public static upsertSprayMethod = createAsyncThunk<
     ISprayMethod | null,
     ISprayMethodUpsert,
     ThunkApi>(
         'MASTER_DATA_UPSERT_SPRAY_METHOD',
         async (sprayMethodUpsert, thunkApi) => {
             try {
                 const state = thunkApi.getState();
                 const sprayMethod = state.data.sprayMethodData ?? [];
 
                 thunkApi.dispatch(DataActions.setIsLoading(true)); 
                 
                 const res = await SprayMethodHttpService.sprayMethodUpsert(sprayMethodUpsert);
 
                 const newList = ArrayHelper.upsertElement(sprayMethod, res.data, a => a.id === res.data.id);

                 if (newList)
                     await setSprayMethodMasterDataIndexedDB(newList);

                 thunkApi.dispatch(DataActions.setSprayMethodData(newList));
 
                 thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
 
                 return res.data;
             } catch (e) {
                 thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the spray method.', e }));
                 return null;
             } finally {
                 thunkApi.dispatch(DataActions.setIsLoading(false));
             }
         },
     );

    /**
     * Inserts a seed tray size item, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISeedTraySizeUpsert} seedTraySizeUpsert
     * @returns {ISeedTraySize | null}
    */
    public static upsertSeedTraySize = createAsyncThunk<
     ISeedTraySize | null,
     ISeedTraySizeUpsert,
     ThunkApi>(
         'MASTER_DATA_UPSERT_SEED_TRAY_SIZE',
         async (seedTraySizeUpsert, thunkApi) => {
             try {
                 const state = thunkApi.getState();
                 const seedTraySize = state.data.seedTraySizeData ?? [];
 
                 thunkApi.dispatch(DataActions.setIsLoading(true)); 
                 
                 const res = await SeedTraySizeHttpService.seedTraySizeUpsert(seedTraySizeUpsert);
 
                 const newList = ArrayHelper.upsertElement(seedTraySize, res.data, a => a.id === res.data.id);

                 if (newList)
                     await setSeedTraySizeMasterDataIndexedDB(newList);

                 thunkApi.dispatch(DataActions.setSeedTraySizeData(newList));
 
                 thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
 
                 return res.data;
             } catch (e) {
                 thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving seed tray size.', e }));
                 return null;
             } finally {
                 thunkApi.dispatch(DataActions.setIsLoading(false));
             }
         },
     );

    /**
     * Inserts a system, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISystemUpsert} systemUpsert
     * @returns {ISystem | null}
    */
    public static upsertSystem = createAsyncThunk<
    ISystem | null,
    ISystemUpsert,
    ThunkApi>(
        'MASTER_DATA_UPSERT_SYSTEM',
        async (systemUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const systems = state.data.systemData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                const res = await SystemHttpService.systemUpsert(systemUpsert);

                const newList = ArrayHelper.upsertElement(systems, res.data, a => a.id === res.data.id);

                if (newList)
                    await setSystemMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setSystemData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the system.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a priority, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IPriorityUpsert} priorityUpsert
     * @returns {IPriority | null}
    */
    public static upsertPriority = createAsyncThunk<
    IPriority | null,
    IPriorityUpsert,
    ThunkApi>(
        'MASTER_DATA_UPSERT_PRIORITY',
        async (priorityUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const priorities = state.data.priorityData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                const res = await PriorityHttpService.priorityUpsert(priorityUpsert);

                const newList = ArrayHelper.upsertElement(priorities, res.data, a => a.id === res.data.id);

                if (newList)
                    await setPriorityMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setPriorityData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the priority.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts an activity, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActivityUpsert} activityUpsert
     * @returns {IActivity | null}
    */
    public static upsertActivity = createAsyncThunk<
    IActivity | null,
    IActivityUpsert,
    ThunkApi>(
        'MASTER_DATA_UPSERT_ACTIVITY',
        async (activityUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const activities = state.data.activityData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                const res = await ActivityHttpService.activityUpsert(activityUpsert);

                const newList = ArrayHelper.upsertElement(activities, res.data, a => a.id === res.data.id);

                if (newList)
                    await setActivityMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setActivityData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the activity.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts an activity type, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActivityTypeUpsert} activityUpsert
     * @returns {IActivityType | null}
    */
    public static upsertActivityType = createAsyncThunk<
    IActivityType | null,
    IActivityTypeUpsert,
    ThunkApi>(
        'MASTER_DATA_UPSERT_ACTIVITY_TYPE',
        async (activityUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const activityTypes = state.data.activityTypeData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                const res = await ActivityTypeHttpService.activityTypeUpsert(activityUpsert);

                const newList = ArrayHelper.upsertElement(activityTypes, res.data, a => a.id === res.data.id);

                if (newList)
                    await setActivityTypeMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setActivityTypeData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the activity type.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /****************************** DELETE *********************************/

    /**
     * Deletes a division, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} divisionId
     * @returns {boolean}
    */
    public static deleteDivision = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_DIVISION',
            async (divisionId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await DivisionHttpService.divisionDelete(divisionId);

                    const divisions : Array<IDivision> = thunkApi.getState().data.divisionData ?? [];
                    const deletedDivision = divisions.find(x => x.id === divisionId);

                    if (deletedDivision) {
                        const updatedDivision : IDivision = {
                            ...deletedDivision,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(divisions, updatedDivision, a => a.id === divisionId);

                        if (newList)
                            await setDivisionMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setDivisionData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting division.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a subdivision, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} subdivisionId
     * @returns {boolean}
    */
    public static deleteSubdivision = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_SUBDIVISION',
            async (subdivisionId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await SubdivisionHttpService.subdivisionDelete(subdivisionId);

                    const subdivisions : Array<ISubdivision> = thunkApi.getState().data.subdivisionData ?? [];
                    const deletedSubdivision = subdivisions.find(x => x.id === subdivisionId);

                    if (deletedSubdivision) {
                        const updatedSubdivision : ISubdivision = {
                            ...deletedSubdivision,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(subdivisions, updatedSubdivision, a => a.id === subdivisionId);

                        if (newList)
                            await setSubdivisionMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setSubdivisionData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting subdivision.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a department, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} departmentId
     * @returns {boolean}
     */
    public static deleteDepartment = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_DEPARTMENT',
            async (departmentId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await DepartmentHttpService.departmentDelete(departmentId);

                    const departments : Array<IDepartment> = thunkApi.getState().data.departmentData ?? [];
                    const deletedDepartment = departments.find(x => x.id === departmentId);

                    if (deletedDepartment) {
                        const updatedDepartment : IDepartment = {
                            ...deletedDepartment,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(departments, updatedDepartment, a => a.id === departmentId);

                        if (newList)
                            await setDepartmentMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setDepartmentData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting department.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a project, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} projectId
     * @returns {boolean}
     */
    public static deleteProject = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_PROJECT',
            async (projectId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ProjectHttpService.projectDelete(projectId);

                    const projects : Array<IProject> = thunkApi.getState().data.projectData ?? [];
                    const deletedProject = projects.find(x => x.id === projectId);

                    if (deletedProject) {
                        const updatedProject : IProject = {
                            ...deletedProject, 
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(projects, updatedProject, a => a.id === projectId);

                        if (newList)
                            await setProjectMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setProjectData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting project.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a production unit, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} productionUnitId
     * @returns {boolean}
     */
    public static deleteProductionUnit = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_PRODUCTION_UNIT',
            async (productionUnitId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ProductionUnitHttpService.productionUnitDelete(productionUnitId);

                    const productionUnits : Array<IProductionUnit> = thunkApi.getState().data.productionUnitData ?? [];
                    const deletedProductionUnit = productionUnits.find(x => x.id === productionUnitId);

                    if (deletedProductionUnit) {
                        const updatedProductionUnit : IProductionUnit = {
                            ...deletedProductionUnit,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(productionUnits, updatedProductionUnit, a => a.id === productionUnitId);

                        if (newList)
                            await setProductionUnitMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setProductionUnitData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting production unit.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a field, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} fieldId
     * @returns {boolean}
     */
    public static deleteField = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_FIELD',
            async (fieldId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await FieldHttpService.fieldDelete(fieldId);

                    const fields : Array<IField> = thunkApi.getState().data.fieldData ?? [];
                    const deletedField = fields.find(x => x.id === fieldId);

                    if (deletedField) {
                        const updatedField : IField = {
                            ...deletedField,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(fields, updatedField, a => a.id === fieldId);

                        if (newList)
                            await setFieldMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setFieldData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting field.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a block, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} blockId
     * @returns {boolean}
     */
    public static deleteBlock = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_BLOCK',
            async (blockId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await BlockHttpService.blockDelete(blockId);

                    const blocks : Array<IBlock> = thunkApi.getState().data.blockData ?? [];
                    const deletedBlock = blocks.find(x => x.id === blockId);

                    if (deletedBlock) {
                        const updatedBlock : IBlock = {
                            ...deletedBlock, 
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(blocks, updatedBlock, a => a.id === blockId);

                        if (newList)
                            await setBlockMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setBlockData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting block.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a commodity, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} commodityId
     * @returns {boolean}
     */
    public static deleteCommodity = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_COMMODITY',
            async (commodityId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await CommodityHttpService.commodityDelete(commodityId);

                    const commodities : Array<ICommodity> = thunkApi.getState().data.commodityData ?? [];
                    const deletedCommodity = commodities.find(x => x.id === commodityId);

                    if (deletedCommodity) {
                        const updatedCommodity : ICommodity = {
                            ...deletedCommodity,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(commodities, updatedCommodity, a => a.id === commodityId);

                        if (newList)
                            await setCommodityMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setCommodityData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting commodity.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a variety, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} varietyId
     * @returns {boolean}
     */
    public static deleteVariety = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_VARIETY',
            async (varietyId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await VarietyHttpService.varietyDelete(varietyId);

                    const varieties : Array<IVariety> = thunkApi.getState().data.varietyData ?? [];
                    const deletedVariety = varieties.find(x => x.id === varietyId);

                    if (deletedVariety) {
                        const updatedVariety : IVariety = {
                            ...deletedVariety,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(varieties, updatedVariety, a => a.id === varietyId);

                        if (newList)
                            await setVarietyMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setVarietyData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting variety.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a nursery, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} nurseryId
     * @returns {boolean}
     */
    public static deleteNursery = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_NURSERY',
            async (nurseryId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await NurseryHttpService.nurseryDelete(nurseryId);

                    const nurseries : Array<INursery> = thunkApi.getState().data.nurseryData ?? [];
                    const deletedNursery = nurseries.find(x => x.id === nurseryId);

                    if (deletedNursery) {
                        const updatedNursery : INursery = {
                            ...deletedNursery,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(nurseries, updatedNursery, a => a.id === nurseryId);

                        if (newList)
                            await setNurseryMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setNurseryData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting nursery.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a root stock, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} rootStockId
     * @returns {boolean}
     */
    public static deleteRootStock = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_ROOT_STOCK',
            async (rootStockId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await RootStockHttpService.rootStockDelete(rootStockId);

                    const rootstocks : Array<IRootStock> = thunkApi.getState().data.rootStockData ?? [];
                    const deletedRootStock = rootstocks.find(x => x.id === rootStockId);

                    if (deletedRootStock) {
                        const updatedRootStock : IRootStock = {
                            ...deletedRootStock,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(rootstocks, updatedRootStock, a => a.id === rootStockId);

                        if (newList)
                            await setRootStockMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setRootStockData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting root stock.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a unit of measure, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} unitOfMeasureId
     * @returns {boolean}
     */
    public static deleteUnitOfMeasure = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_UNIT_OF_MEASURE',
            async (unitOfMeasureId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await UnitOfMeasureHttpService.unitOfMeasureDelete(unitOfMeasureId);

                    const unitOfMeasures : Array<IUnitOfMeasure> = thunkApi.getState().data.unitOfMeasureData ?? [];
                    const deletedUnitOfMeasure = unitOfMeasures.find(x => x.id === unitOfMeasureId);

                    if (deletedUnitOfMeasure) {
                        const updatedUnitOfMeasure : IUnitOfMeasure = {
                            ...deletedUnitOfMeasure,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(unitOfMeasures, updatedUnitOfMeasure, a => a.id === unitOfMeasureId);

                        if (newList)
                            await setUnitOfMeasureMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setUnitOfMeasureData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting unit of measure.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a chemical product, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} chemicalProductId
     * @returns {boolean}
     */
    public static deleteChemicalProduct = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_CHEMICAL_PRODUCT',
            async (chemicalProductId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ChemicalProductHttpService.chemicalProductDelete(chemicalProductId);

                    const chemicalProducts : Array<IChemicalProduct> = thunkApi.getState().data.chemicalProductData ?? [];
                    const deletedChemicalProduct = chemicalProducts.find(x => x.id === chemicalProductId);

                    if (deletedChemicalProduct) {
                        const updatedChemicalProduct : IChemicalProduct = {
                            ...deletedChemicalProduct,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(chemicalProducts, updatedChemicalProduct, a => a.id === chemicalProductId);

                        if (newList)
                            await setChemicalProductMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setChemicalProductData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting chemical product.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a chemical element, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} chemicalElementId
     * @returns {boolean}
     */
    public static deleteChemicalElement = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_CHEMICAL_ELEMENT',
            async (chemicalElementId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ChemicalElementHttpService.chemicalElementDelete(chemicalElementId);

                    const chemicalElements : Array<IChemicalElement> = thunkApi.getState().data.chemicalElementData ?? [];
                    const deletedChemicalElement = chemicalElements.find(x => x.id === chemicalElementId);

                    if (deletedChemicalElement) {
                        const updatedChemicalElement : IChemicalElement = {
                            ...deletedChemicalElement,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(chemicalElements, updatedChemicalElement, a => a.id === chemicalElementId);

                        if (newList)
                            await setChemicalElementMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setChemicalElementData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting chemical element.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a chemical category, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} chemicalCategoryId
     * @returns {boolean}
     */
    public static deleteChemicalCategory = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_CHEMICAL_CATEGORY',
            async (chemicalCategoryId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ChemicalCategoryHttpService.chemicalCategoryDelete(chemicalCategoryId);

                    const chemicalCategories : Array<IChemicalCategory> = thunkApi.getState().data.chemicalCategoryData ?? [];
                    const deletedChemicalCategory = chemicalCategories.find(x => x.id === chemicalCategoryId);

                    if (deletedChemicalCategory) {
                        const updatedChemicalCategory : IChemicalCategory = {
                            ...deletedChemicalCategory,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(chemicalCategories, updatedChemicalCategory, a => a.id === chemicalCategoryId);

                        if (newList)
                            await setChemicalCategoryMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setChemicalCategoryData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting chemical category.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a chemical formulation, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} chemicalFormulationId
     * @returns {boolean}
     */
    public static deleteChemicalFormulation = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_CHEMICAL_FORMULATION',
            async (chemicalFormulationId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ChemicalFormulationHttpService.chemicalFormulationDelete(chemicalFormulationId);

                    const chemicalFormulations : Array<IChemicalFormulation> = thunkApi.getState().data.chemicalFormulationData ?? [];
                    const deletedChemicalFormulation = chemicalFormulations.find(x => x.id === chemicalFormulationId);

                    if (deletedChemicalFormulation) {
                        const updatedChemicalFormulation : IChemicalFormulation = {
                            ...deletedChemicalFormulation,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(chemicalFormulations, updatedChemicalFormulation, a => a.id === chemicalFormulationId);

                        if (newList)
                            await setChemicalFormulationMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setChemicalFormulationData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting chemical formulation.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a chemical product element, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} chemicalProductElementBridgeId
     * @returns {boolean}
     */
    public static deleteChemicalProductElementBridge = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_CHEMICAL_PRODUCT_ELEMENT_BRIDGE',
            async (chemicalProductElementBridgeId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ChemicalProductElementHttpService.chemicalProductElementBridgeDelete(chemicalProductElementBridgeId);

                    const chemicalProductElementBridges : Array<IChemicalProductElementBridge> = thunkApi.getState().data.chemicalProductElementBridgeData ?? [];
                    const deletedChemicalProductElementBridge = chemicalProductElementBridges.find(x => x.id === chemicalProductElementBridgeId);

                    if (deletedChemicalProductElementBridge) {
                        const updatedChemicalProductElementBridge : IChemicalProductElementBridge = {
                            ...deletedChemicalProductElementBridge,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(chemicalProductElementBridges, updatedChemicalProductElementBridge, a => a.id === chemicalProductElementBridgeId);
                        thunkApi.dispatch(DataActions.setChemicalProductElementBridgeData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting chemical product element.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a active ingredient, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} activeIngredientId
     * @returns {boolean}
     */
    public static deleteActiveIngredient = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_ACTIVE_INGREDIENT',
            async (activeIngredientId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ActiveIngredientHttpService.activeIngredientDelete(activeIngredientId);

                    const activeIngredients : Array<IActiveIngredient> = thunkApi.getState().data.activeIngredientData ?? [];
                    const deletedActiveIngredients = activeIngredients.find(x => x.id === activeIngredientId);

                    if (deletedActiveIngredients) {
                        const updatedActiveIngredients : IActiveIngredient = {
                            ...deletedActiveIngredients,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(activeIngredients, updatedActiveIngredients, a => a.id === activeIngredientId);

                        if (newList)
                            await setActiveIngredientMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setActiveIngredientData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting active ingredient.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a chemical product active ingredient, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} chemicalProductActiveIngredientBridgeId
     * @returns {boolean}
     */
    public static deleteChemicalProductActiveIngredientBridge = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_CHEMICAL_PRODUCT_ACTIVE_INGREDIENT_BRIDGE',
            async (chemicalProductActiveIngredientBridgeId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ChemicalProductActiveIngredientHttpService.chemicalProductActiveIngredientBridgeDelete(chemicalProductActiveIngredientBridgeId);

                    const chemicalProductActiveIngredientBridges : Array<IChemicalProductActiveIngredientBridge> = thunkApi.getState().data.chemicalProductActiveIngredientBridgeData ?? [];
                    const deletedChemicalProductActiveIngredientBridge = chemicalProductActiveIngredientBridges.find(x => x.id === chemicalProductActiveIngredientBridgeId);

                    if (deletedChemicalProductActiveIngredientBridge) {
                        const updatedChemicalProductActiveIngredientBridge : IChemicalProductActiveIngredientBridge = {
                            ...deletedChemicalProductActiveIngredientBridge,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(chemicalProductActiveIngredientBridges, updatedChemicalProductActiveIngredientBridge, a => a.id === chemicalProductActiveIngredientBridgeId);
                        thunkApi.dispatch(DataActions.setChemicalProductActiveIngredientBridgeData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting chemical product active ingredient.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a safety precaution, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} safetyPrecautionId
     * @returns {boolean}
     */
    public static deleteSafetyPrecaution = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_SAFETY_PRECAUTION',
            async (safetyPrecautionId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await SafetyPrecautionHttpService.safetyPrecautionDelete(safetyPrecautionId);

                    const safetyPrecautions : Array<ISafetyPrecaution> = thunkApi.getState().data.safetyPrecautionData ?? [];
                    const deletedSafetyPrecaution = safetyPrecautions.find(x => x.id === safetyPrecautionId);

                    if (deletedSafetyPrecaution) {
                        const updatedSafetyPrecaution : ISafetyPrecaution = {
                            ...deletedSafetyPrecaution,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(safetyPrecautions, updatedSafetyPrecaution, a => a.id === safetyPrecautionId);

                        if (newList)
                            await setSafetyPrecautionMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setSafetyPrecautionData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting safety precaution.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a safety precaution ppe, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} safetyPrecautionPpeId
     * @returns {boolean}
     */
    public static deleteSafetyPrecautionPpe = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_SAFETY_PRECAUTION_PPE',
            async (safetyPrecautionPpeId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await SafetyPrecautionPpeHttpService.safetyPrecautionPpeDelete(safetyPrecautionPpeId);

                    const safetyPrecautionPpes : Array<ISafetyPrecautionPpe> = thunkApi.getState().data.safetyPrecautionPpeData ?? [];
                    const deletedSafetyPrecautionPpe = safetyPrecautionPpes.find(x => x.id === safetyPrecautionPpeId);

                    if (deletedSafetyPrecautionPpe) {
                        const updatedSafetyPrecautionPpe : ISafetyPrecautionPpe = {
                            ...deletedSafetyPrecautionPpe,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(safetyPrecautionPpes, updatedSafetyPrecautionPpe, a => a.id === safetyPrecautionPpeId);

                        if (newList)
                            await setSafetyPrecautionPpeMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setSafetyPrecautionPpeData(newList));
                    }

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting safety precaution PPE.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a irrigationCycle, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} irrigationCycleId
     * @returns {boolean}
     */
    public static deleteIrrigationCycle = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_IRRIGATION_CYCLE',
        async (irrigationCycleId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await IrrigationCycleHttpService.irrigationCycleDelete(irrigationCycleId);

                const irrigationCycles : Array<IIrrigationCycle> = thunkApi.getState().data.irrigationCycleData ?? [];
                const deletedIrrigationCycle = irrigationCycles.find(x => x.id === irrigationCycleId);

                if (deletedIrrigationCycle) {
                    const updatedIrrigationCycle : IIrrigationCycle = {
                        ...deletedIrrigationCycle,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(irrigationCycles, updatedIrrigationCycle, a => a.id === irrigationCycleId);
                    thunkApi.dispatch(DataActions.setIrrigationCycleData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting irrigation cycle.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a plants per hectare item, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} plantsPerHectareId
     * @returns {boolean}
    */
    public static deletePlantPerHectare = createAsyncThunk<
     boolean,
     number,
     ThunkApi>(
         'MASTER_DATA_DELETE_PLANT_PER_HECTARE',
         async (plantsPerHectareId, thunkApi) => {
             try {
                 thunkApi.dispatch(DataActions.setIsLoading(true)); 
                 
                 await PlantsPerHectareHttpService.plantsPerHectareDelete(plantsPerHectareId);
 
                 const plantsPerHectare : Array<IPlantsPerHectare> = thunkApi.getState().data.plantsPerHectareData ?? [];
                 const deletedPlantsPerHectare = plantsPerHectare.find(x => x.id === plantsPerHectareId);
 
                 if (deletedPlantsPerHectare) {
                     const updatedPlantsPerHectare : IPlantsPerHectare = {
                         ...deletedPlantsPerHectare,
                         isActive: false,
                     };
                     const newList = ArrayHelper.upsertElement(plantsPerHectare, updatedPlantsPerHectare, a => a.id === plantsPerHectareId);

                     if (newList)
                         await setPlantsPerHectareMasterDataIndexedDB(newList);
 
                     thunkApi.dispatch(DataActions.setPlantsPerHectareData(newList));
                 }
 
                 thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));
 
                 return true;
             } catch (e) {
                 thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting plants per hectare item.', e }));
                 return false;
             } finally {
                 thunkApi.dispatch(DataActions.setIsLoading(false));
             }
         },
     );

    /**
     * Deletes a seed tray size, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} seedTraySizeId
     * @returns {boolean}
    */
    public static deleteSeedTraySize = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_DELETE_SEED_TRAY_SIZE',
        async (seedTraySizeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await SeedTraySizeHttpService.seedTraySizeDelete(seedTraySizeId);

                const seedTraySizes : Array<ISeedTraySize> = thunkApi.getState().data.seedTraySizeData ?? [];
                const deletedSeedTraySize = seedTraySizes.find(x => x.id === seedTraySizeId);

                if (deletedSeedTraySize) {
                    const updatedSeedTraySize : ISeedTraySize = {
                        ...deletedSeedTraySize,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(seedTraySizes, updatedSeedTraySize, a => a.id === seedTraySizeId);

                    if (newList)
                        await setSeedTraySizeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setSeedTraySizeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting seed tray size.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a spray Method, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} sprayMethodId
     * @returns {boolean}
    */
    public static deleteSprayMethod = createAsyncThunk<
     boolean,
     number,
     ThunkApi>(
         'MASTER_DATA_DELETE_SPRAY_METHOD',
         async (sprayMethodId, thunkApi) => {
             try {
                 thunkApi.dispatch(DataActions.setIsLoading(true)); 
                 
                 await SprayMethodHttpService.sprayMethodDelete(sprayMethodId);
 
                 const sprayMethods : Array<ISprayMethod> = thunkApi.getState().data.sprayMethodData ?? [];
                 const deletedSprayMethod = sprayMethods.find(x => x.id === sprayMethodId);
 
                 if (deletedSprayMethod) {
                     const updatedSprayMethod : ISprayMethod = {
                         ...deletedSprayMethod,
                         isActive: false,
                     };
                     const newList = ArrayHelper.upsertElement(sprayMethods, updatedSprayMethod, a => a.id === sprayMethodId);

                     if (newList)
                         await setSprayMethodMasterDataIndexedDB(newList);
 
                     thunkApi.dispatch(DataActions.setSprayMethodData(newList));
                 }
 
                 thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));
 
                 return true;
             } catch (e) {
                 thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting spray method.', e }));
                 return false;
             } finally {
                 thunkApi.dispatch(DataActions.setIsLoading(false));
             }
         },
     );

    /**
     * Deletes a system, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} systemId
     * @returns {boolean}
     */
    public static deleteSystem = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_SYSTEM',
        async (systemId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await SystemHttpService.systemDelete(systemId);

                const systems : Array<ISystem> = thunkApi.getState().data.systemData ?? [];
                const deletedSystem = systems.find(x => x.id === systemId);

                if (deletedSystem) {
                    const updatedSystem : ISystem = {
                        ...deletedSystem,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(systems, updatedSystem, a => a.id === systemId);

                    if (newList)
                        await setSystemMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setSystemData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting the system.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes an activity, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} activityId
     * @returns {boolean}
     */
    public static deleteActivity = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_ACTIVITY',
        async (activityId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ActivityHttpService.activityDelete(activityId);

                const activities : Array<IActivity> = thunkApi.getState().data.activityData ?? [];
                const deletedActivity = activities.find(x => x.id === activityId);

                if (deletedActivity) {
                    const updatedActivity : IActivity = {
                        ...deletedActivity,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(activities, updatedActivity, a => a.id === activityId);

                    if (newList)
                        await setActivityMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setActivityData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting the activity.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes an activity type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} activityTypeId
     * @returns {boolean}
     */
    public static deleteActivityType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_ACTIVITY_TYPE',
        async (activityTypeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ActivityTypeHttpService.activityTypeDelete(activityTypeId);

                const activityTypes : Array<IActivityType> = thunkApi.getState().data.activityTypeData ?? [];
                const deletedActivity = activityTypes.find(x => x.id === activityTypeId);

                if (deletedActivity) {
                    const updatedActivityType : IActivityType = {
                        ...deletedActivity,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(activityTypes, updatedActivityType, a => a.id === activityTypeId);

                    if (newList)
                        await setActivityTypeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setActivityTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting the activity type.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a priority, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} priorityId
     * @returns {boolean}
     */
    public static deletePriority = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_PRIORITY',
        async (priorityId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await PriorityHttpService.priorityDelete(priorityId);

                const priorities : Array<IPriority> = thunkApi.getState().data.priorityData ?? [];
                const deletedPriority = priorities.find(x => x.id === priorityId);

                if (deletedPriority) {
                    const updatedPriority : IPriority = {
                        ...deletedPriority,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(priorities, updatedPriority, a => a.id === priorityId);

                    if (newList)
                        await setPriorityMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setPriorityData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting the activity type.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

}