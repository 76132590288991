import SensorActions from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { IAccumulator } from '../../@types/model/sensor/accumulator';
import { IAccumulatorGroup } from '../../@types/model/sensor/accumulatorGroup';
import { IPlace } from '../../@types/model/masterData/place/place';
import { IAccumulatorGroupDataViewModel } from '../../@types/model/sensor/accumulatorGroupDataView';

export interface ISensorState {
    isLoading : boolean;
    accumulatorList : Array<IAccumulator> | null;
    accumulatorGroupList : Array<IAccumulatorGroup> | null;
    selectedAccumulatorGroup : IAccumulatorGroup | null;
    selectedAccumulator : IAccumulator | null;
    sensorPlaceList : Array<IPlace> | null;
    graphData : IAccumulatorGroupDataViewModel | null;
}

const initialState = {
    isLoading: false,
    accumulatorList: null,
    accumulatorGroupList: null,
    selectedAccumulatorGroup: null,
    selectedAccumulator: null,
    sensorPlaceList: null,
    graphData: null,
};

const sensorReducer = createReducer<ISensorState>(initialState, (builder) => {
    builder.addCase(SensorActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(SensorActions.setAccumulatorList, (state, action) => {
        state.accumulatorList = action.payload;
    });
    builder.addCase(SensorActions.setAccumulatorGroupList, (state, action) => {
        state.accumulatorGroupList = action.payload;
    });
    builder.addCase(SensorActions.setSelectedAccumulatorGroup, (state, action) => {
        state.selectedAccumulatorGroup = action.payload;
    });
    builder.addCase(SensorActions.setSelectedAccumulator, (state, action) => {
        state.selectedAccumulator = action.payload;
    });
    builder.addCase(SensorActions.setSensorPlaceList, (state, action) => {
        state.sensorPlaceList = action.payload;
    });
    builder.addCase(SensorActions.setGraphData, (state, action) => {
        state.graphData = action.payload;
    });
    builder.addCase(SensorActions.reset, () => {
        return initialState;
    });
});

export default sensorReducer;
