import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { List } from 'lodash';
import qs from 'qs';
import { ISafetyPrecaution } from '../../../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionUpsert } from '../../../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecautionUpsert';

export default class SafetyPrecautionHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataSafetyPrecautionGetList() : AxiosPromise<Array<ISafetyPrecaution>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/SafetyPrecaution/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static safetyPrecautionUpsert = (safetyPrecautionUpsert : ISafetyPrecautionUpsert, ppeIds : List<number>) : AxiosPromise<ISafetyPrecaution> => {
        return axios.post(`${API_URL}/MasterData/SafetyPrecaution/Upsert`, safetyPrecautionUpsert, {
            params: { ppeIds },
            paramsSerializer: qs.stringify,
        });
    }

    public static safetyPrecautionDelete = (safetyPrecautionId : number) : AxiosPromise<ISafetyPrecaution> => {
        return axios.delete(`${API_URL}/MasterData/SafetyPrecaution/Delete`, {
            params: { safetyPrecautionId },
        });
    }
}