import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IChemicalFormulation } from '../../../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalFormulationUpsert } from '../../../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulationUpsert';

export default class ChemicalFormulationHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataChemicalFormulationGetList() : AxiosPromise<Array<IChemicalFormulation>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ChemicalFormulation/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static chemicalFormulationUpsert = (chemicalFormulationUpsert : IChemicalFormulationUpsert) : AxiosPromise<IChemicalFormulation> => {
        return axios.post(`${API_URL}/MasterData/ChemicalFormulation/Upsert`, chemicalFormulationUpsert);
    }

    public static chemicalFormulationDelete = (chemicalFormulationId : number) : AxiosPromise<IChemicalFormulation> => {
        return axios.delete(`${API_URL}/MasterData/ChemicalFormulation/Delete`, {
            params: { chemicalFormulationId },
        });
    }
}