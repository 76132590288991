import { createAction } from '@reduxjs/toolkit';
import { IRight } from '../../@types/model/auth/right/right';
import { IRole } from '../../@types/model/auth/role/role';
import { IUser } from '../../@types/model/auth/user/user';
import { withPayloadType } from '@zz2/zz2-ui';

export default class RightActions {
    public static setIsLoading = createAction('RIGHT_SET_LOADING', withPayloadType<boolean>());
    public static setRightData = createAction('RIGHT_SET_LIST', withPayloadType<Array<IRight>>());
    public static setRoleData = createAction('ROLE_SET_LIST', withPayloadType<Array<IRole>>());
    public static setUserData = createAction('USER_SET_LIST', withPayloadType<Array<IUser>>());
    public static reset = createAction('RIGHT_RESET');
}
