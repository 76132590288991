import { IRight } from './auth/right/right';
import { IRole } from './auth/role/role';
import { ICropLine } from './cropData/cropLine/cropLine';
import { IActivity } from './masterData/activity/activity';
import { IActivityType } from './masterData/activityType/activityType';
import { IBlock } from './masterData/block/block';
import { IActiveIngredient } from './masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from './masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from './masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from './masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from './masterData/chemicals/chemicalProduct/chemicalProduct';
import { ISafetyPrecaution } from './masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from './masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ICommodity } from './masterData/commodity/commodity';
import { IDepartment } from './masterData/department/department';
import { IDivision } from './masterData/division/division';
import { IField } from './masterData/field/field';
import { IIrrigationCycle } from './masterData/irrigationCycle/irrigationCycle';
import { INursery } from './masterData/nursery/nursery';
import { IPlantsPerHectare } from './masterData/plantsPerHectare/plantsPerHectare';
import { IPriority } from './masterData/priority/priority';
import { IProductionUnit } from './masterData/productionUnit/productionUnit';
import { IProject } from './masterData/project/project';
import { IRootStock } from './masterData/rootStock/rootStock';
import { ISeedTraySize } from './masterData/seedTraySize/seedTraySize';
import { ISprayMethod } from './masterData/sprayMethod/splayMethod';
import { ISubdivision } from './masterData/subdivision/subdivision';
import { ISystem } from './masterData/system/system';
import { IUnitOfMeasure } from './masterData/unitOfMeasure/unitOfMeasure';
import { IVariety } from './masterData/variety/variety';
import { IConfig } from './qcMasterData/config/config';
import { IProcess } from './qcMasterData/process/process';
import { IQcSite } from './qcMasterData/qcSites/qcSite';
import { IQualifier } from './qcMasterData/qualifier/qualifier';
import { IQualifierType } from './qcMasterData/qualifierType/qualifierType';
import { IOptionType } from '@zz2/zz2-ui';
import { ISeasonProgram } from './seasonData/seasonProgram/seasonProgram';


export class OptionType {

    /*================================================================================================================
     *                                                  Master Data
     * ==============================================================================================================*/

    public static fromDivision = (division : IDivision) : IOptionType => {
        return {
            label: division.name,
            value: division.id,
        };
    };

    public static fromSubdivision = (subdivision : ISubdivision) : IOptionType => {
        return {
            label: subdivision.name,
            value: subdivision.id,
        };
    };

    public static fromDepartment = (department : IDepartment) : IOptionType => {
        return {
            label: department.name,
            value: department.id,
        };
    };

    public static fromProductionUnit = (productionUnit : IProductionUnit) : IOptionType => {
        return {
            label: productionUnit.name,
            value: productionUnit.id,
        };
    };

    public static fromField = (field : IField) : IOptionType => {
        return {
            label: `(${field.id}) ${field.name}`,
            value: field.id,
        };
    };

    public static fromBlock = (block : IBlock) : IOptionType => {
        return {
            label: block.code,
            value: block.id,
        };
    };

    public static fromCommodity = (commodity : ICommodity) : IOptionType => {
        return {
            label: commodity.name,
            value: commodity.id,
        };
    };

    public static fromVariety = (variety : IVariety) : IOptionType => {
        return {
            label: variety.name,
            value: variety.id,
        };
    };

    public static fromProject = (project : IProject) : IOptionType => {
        return {
            label: `(${project.commodity?.name ?? ''}) ${project.number}`,
            value: project.id,
        };
    };

    public static fromNursery = (nursery : INursery) : IOptionType => {
        return {
            label: nursery.name,
            value: nursery.id,
        };
    };

    public static fromRootStock = (rootStock : IRootStock) : IOptionType => {
        return {
            label: rootStock.name,
            value: rootStock.id,
        };
    };

    public static fromUnitOfMeasure = (unitOfMeasure : IUnitOfMeasure) : IOptionType => {
        return {
            label: unitOfMeasure.name,
            value: unitOfMeasure.id,
        };
    };

    public static fromIrrigationCycle = (irrigationCycle : IIrrigationCycle) : IOptionType => {
        return {
            label: irrigationCycle.code,
            value: irrigationCycle.id,
        };
    };

    public static fromPlantsPerHectare = (plantsPerHectare : IPlantsPerHectare) : IOptionType => {
        return {
            label: plantsPerHectare.numberOfPlants.toString(),
            value: plantsPerHectare.id,
        };
    };

    public static fromSeedTraySize = (seedTraySize : ISeedTraySize) : IOptionType => {
        return {
            label: seedTraySize.size,
            value: seedTraySize.id,
        };
    };

    public static fromSprayMethod = (sprayMethod : ISprayMethod) : IOptionType => {
        return {
            label: sprayMethod.name,
            value: sprayMethod.id,
        };
    };

    public static fromActivity = (activity : IActivity) : IOptionType => {
        return {
            label: activity.description,
            value: activity.id,
        };
    };

    public static fromActivityType = (activityType : IActivityType) : IOptionType => {
        return {
            label: activityType.name,
            value: activityType.id,
        };
    };

    public static fromSystem = (system : ISystem) : IOptionType => {
        return {
            label: system.name,
            value: system.id,
        };
    };

    public static fromPriority = (priority : IPriority) : IOptionType => {
        return {
            label: priority.name,
            value: priority.id  
        };
    };

    public static fromCropLine = (cropLine : ICropLine) : IOptionType => {
        return {
            label: `(${cropLine.cropId}) - CropLine`,
            value: cropLine.id,
        };
    };

    /*================================================================================================================
     *                                                  Chemical Master Data
     * ==============================================================================================================*/

    public static fromActiveIngredient = (activeIngredient : IActiveIngredient) : IOptionType => {
        return {
            label: activeIngredient.name,
            value: activeIngredient.id,
        };
    };

    public static fromChemicalCategory = (chemicalCategory : IChemicalCategory) : IOptionType => {
        return {
            label: chemicalCategory.name,
            value: chemicalCategory.id,
        };
    };

    public static fromChemicalElement = (chemicalElement : IChemicalElement) : IOptionType => {
        return {
            label: chemicalElement.name,
            value: chemicalElement.id,
        };
    };

    public static fromChemicalFormulation = (chemicalFormulation : IChemicalFormulation) : IOptionType => {
        return {
            label: chemicalFormulation.code,
            value: chemicalFormulation.id,
        };
    };

    public static fromChemicalProduct = (chemicalProduct : IChemicalProduct) : IOptionType => {
        return {
            label: chemicalProduct.name,
            value: chemicalProduct.id,
        };
    };

    public static fromSafetyPrecaution = (safetyPrecaution : ISafetyPrecaution) : IOptionType => {
        return {
            label: safetyPrecaution.description,
            value: safetyPrecaution.id,
        };
    };

    public static fromSafetyPrecautionPpe = (safetyPrecautionPpe : ISafetyPrecautionPpe) : IOptionType => {
        return {
            label: safetyPrecautionPpe.description,
            value: safetyPrecautionPpe.id,
        };
    };

    /*================================================================================================================
     *                                                  Season Master Data
     * ==============================================================================================================*/

    public static fromSeasonProgram = (seasonProgram : ISeasonProgram) : IOptionType => {
        return {
            label: seasonProgram.name,
            value: seasonProgram.id,
        };
    }

    /*================================================================================================================
     *                                                  QC Master Data
     * ==============================================================================================================*/

    public static fromConfig = (config : IConfig) : IOptionType => {
        return {
            label: config.name,
            value: config.id,
        };
    };

    public static fromProcess = (process : IProcess) : IOptionType => {
        return {
            label: process.name,
            value: process.id,
        };
    };

    public static fromQualifier = (qualifier : IQualifier) : IOptionType => {
        return {
            label: qualifier.name,
            value: qualifier.id,
        };
    };

    public static fromQualifierType = (qualifierType : IQualifierType) : IOptionType => {
        return {
            label: qualifierType.name,
            value: qualifierType.id,
        };
    };

    public static fromQcSite = (site : IQcSite) : IOptionType => {
        return {
            label: `(${site.shortDescription}) ${site.description}`,
            value: site.id,
        };
    };

    /*================================================================================================================
     *                                                  Auth
     * ==============================================================================================================*/

    public static fromRight = (right : IRight) : IOptionType => {
        return {
            label: `(${right.code}) ${right.name}`,
            value: right.id,
        };
    };

    public static fromRole = (role : IRole) : IOptionType => {
        return {
            label: role.name,
            value: role.id,
        };
    };
}
