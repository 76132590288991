import React, { ReactNode, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, Icon } from '@mui/material';

interface ISideMenuItemProps {
    icon : React.ReactElement<any>;
    title : string;
    className ?: string;
    paddingLeft : number;
    boldTitle ?: boolean;
    children ?: React.ReactNode;
}

const SideMenuItem = (props : ISideMenuItemProps) : React.ReactElement => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List className={props.className}>
            <ListItem button onClick={handleClick} className={`${open ? 'bcg0' : 'bcw'} sideMenuButton`} key={props.title}>
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText disableTypography className={`${props.boldTitle ? 'fw550' : ''}`} primary={props.title} title={props.title} />
                <Icon>{open ? 'expand_less' : 'expand_more'}</Icon>
            </ListItem>
            {!!open && <Divider />}
            <Collapse style={{ paddingLeft: props.paddingLeft }} className={props.className} in={open} timeout='auto' unmountOnExit>
                <List disablePadding>
                    {props.children}
                </List>
            </Collapse>
        </List>
    );
};

export default SideMenuItem;
