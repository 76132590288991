import { createAction } from '@reduxjs/toolkit';
import { IPlace } from '../../@types/model/masterData/place/place';
import { IAccumulator } from '../../@types/model/sensor/accumulator';
import { IAccumulatorGroup } from '../../@types/model/sensor/accumulatorGroup';
import { IAccumulatorGroupDataViewModel } from '../../@types/model/sensor/accumulatorGroupDataView';
import { withPayloadType } from '@zz2/zz2-ui';

export default class SensorActions {
    public static setIsLoading = createAction('SENSOR_SET_LOADING', withPayloadType<boolean>());
    public static setAccumulatorList = createAction('SENSOR_ACCUMUALTOR_SET_LIST', withPayloadType<Array<IAccumulator> | null>());
    public static setAccumulatorGroupList = createAction('SENSOR_ACCUMULATOR_SET_GROUP', withPayloadType<Array<IAccumulatorGroup> | null>());
    public static setSelectedAccumulatorGroup = createAction('SENSOR_ACCUMULATOR_GROUP_SET_SELECTED', withPayloadType<IAccumulatorGroup | null>());
    public static setSelectedAccumulator = createAction('SENSOR_ACCUMULATOR_SET_SELECTED', withPayloadType<IAccumulator | null>());
    public static setSensorPlaceList = createAction('SENSOR_SET_SENSOR_PLACE_LIST', withPayloadType<Array<IPlace> | null>());
    public static setGraphData = createAction('SENSOR_SET_GRAPH_DATA', withPayloadType<IAccumulatorGroupDataViewModel | null>());
    public static reset = createAction('QC_RESET');
}
