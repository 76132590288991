import { combineReducers } from 'redux';
import auth from './auth/reducer';
import general from './general/reducer';
import data from './data/reducer';
import crop from './crops/reducer';
import sensor from './sensor/reducer';
import right from './right/reducer';
import qcMasterData from './qcMasterData/reducer';
import season from './season/reducer';
import frequentlyAskedQuestions from './frequentlyAskedQuestions/reducer';
import freshService from './freshService/reducer';
import log from './log/reducer';
import packman from './packman/reducer';
import sync from './syncing/reducer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import irrigationCycleTask from './transaction/irrigationCycleTask/reducer';
import { createHashHistory } from 'history';

export const history = createHashHistory();

export const createRootReducer = combineReducers({
    auth,
    data,
    sensor,
    general,
    crop,
    qcMasterData,
    season,
    right,
    irrigationCycleTask,
    frequentlyAskedQuestions,
    freshService,
    log,
    packman,
    sync
});

export const store = configureStore({
    reducer: createRootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
        // immutableCheck: false
    }),
});

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
