import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import qs from 'qs';
import { List } from 'lodash';
import { IIrrigationCycle } from '../../../@types/model/masterData/irrigationCycle/irrigationCycle';
import { IIrrigationCycleUpsert } from '../../../@types/model/masterData/irrigationCycle/irrigationCycleUpsert';

export default class IrrigationCycleHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataIrrigationCycleByProductionUnitGetList(productionUnitIds : List<number>) : AxiosPromise<Array<IIrrigationCycle>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/IrrigationCycle/GetList`, {
            params: { productionUnitIds },
            paramsSerializer: qs.stringify,
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static irrigationCycleUpsert = (irrigationCycleUpsert : IIrrigationCycleUpsert) : AxiosPromise<IIrrigationCycle> => {
        return axios.post(`${API_URL}/MasterData/IrrigationCycle/Upsert`, irrigationCycleUpsert);
    }

    public static irrigationCycleDelete = (irrigationCycleId : number) : AxiosPromise<IIrrigationCycle> => {
        return axios.delete(`${API_URL}/MasterData/irrigationCycle/Delete`, {
            params: { irrigationCycleId },
        });
    }
}