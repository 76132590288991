import { createReducer } from '@reduxjs/toolkit';
import { ICrop } from '../../@types/model/cropData/crop/crop';
import { IPlannedCrop } from '../../@types/model/cropData/plannedCrop/plannedCrop';
import CropActions from './actions';

export interface ICropState {
    isLoading : boolean;
    plannedCropData : Array<IPlannedCrop> | null;
    cropData : Array<ICrop> | null;
    selectedCrop : ICrop | null;
}

const initialState = {
    isLoading: false,
    plannedCropData: null,
    plannedCropLineData: null,
    cropData: null,
    selectedCrop: null,
};

const cropReducer = createReducer<ICropState>(initialState, (builder) => {
    builder.addCase(CropActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(CropActions.setPlannedCropData, (state, action) => {
        state.plannedCropData = action.payload;
    });
    builder.addCase(CropActions.setCropData, (state, action) => {
        state.cropData = action.payload;
    });
    builder.addCase(CropActions.setSelectedCrop, (state, action) => {
        state.selectedCrop = action.payload;
    });
    builder.addCase(CropActions.reset, () => {
        return initialState;
    });
});

export default cropReducer;
