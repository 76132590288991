import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card, Toolbar, FormControl, CircularProgress, AppBar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import navActions from '../../store/nav/actions';
import { setLocalStorageSession } from '../../service/localStorageService';
import { VERSION } from '../../version';
import AuthThunk from '../../store/auth/thunk';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { withFormik, FormikProps } from 'formik';
import { IPasswordRecoveryFormValues } from '../../@types/model/auth/password/passwordRecoveryFormValues';
import PasswordRecoveryModelHelper from './helper/passwordRecoveryModelHelper';
import { Screen, FormTextInput } from '@zz2/zz2-ui';

interface IPasswordRecoveryProps {
    initialFormValues : IPasswordRecoveryFormValues;
}

type PasswordRecoveryPropsType = IPasswordRecoveryProps & FormikProps<{ password : string; confirmPassword : string }>;

const PasswordRecovery = (props : PasswordRecoveryPropsType) : React.ReactElement => {
    const isLoading = useAppSelector(x => x.right.isLoading);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const search = location.search;
    const recoveryCode = search.split('recoveryCode=')[1]?.split('&')[0];
    const email = search.split('email=')[1];

    /*================================================================================================================
     *                                                Effects
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                             Async Methods
     * ==============================================================================================================*/

    const submitPasswordRecoveryPasswordChange = async () : Promise<void> => {
        const password = props.values.password;
        await dispatch(AuthThunk.passwordReset({ recoveryCode, email, password }));
        await setLocalStorageSession(null);
        navActions.pushUrl('/login');
    };

    /*================================================================================================================
     *                                             Handler Methods
     * ==============================================================================================================*/

    const getEnvironment = () : string => {
        if (ENV_NAME === 'production') {
            return 'ZZ2 FARM';
        } else if (ENV_NAME === 'qa') {
            return 'ZZ2 FARM QA';
        } else if (ENV_NAME === 'dev') {
            return 'ZZ2 FARM DEV';
        } else {
            return 'ZZ2 FARM ENV NOT FOUND';
        }
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const changePasswordEnabled = useMemo<boolean>(() => {
        if (props.values.password != '' && props.values.confirmPassword != '') {
            return props.values.password == props.values.confirmPassword;
        }

        return false;
        
    }, [props.values]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return <Screen isPadded={false} >
        <AppBar position='static' className={'wsnw mxh52 h52 fdr aic'} elevation={0}>
            <img height={42} width={42} src={`${ASSET_BASE}/assets/icons/farm_logo.png`} className={'pl30'}/>
            <Typography color='inherit' variant='h6' className={'pl20'}>
                {getEnvironment()}
            </Typography>
            <span className={'flx1'} />
            <div className={'fdr jcfe aic'}>
                <sub className={'pt5 mr10 pr20'}>v{VERSION.version}</sub>
            </div>
        </AppBar>
        <div className={'flx1 fdr posr'}>
            <img src={`${ASSET_BASE}/assets/images/login_background.jpg`} className={'posa hfill wfill op70'} />
            {isLoading &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/icons/farm_logo.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            {
                <div className={'flx1 fdr aic jcc oppNone'}>
                    <Card className={'w500 fdc jcc zi2'}>
                        <Toolbar className={'bcpd h50'}>
                            <Typography variant='h6' className='flx1 cw jcc'>
                                Password Recovery
                            </Typography>
                        </Toolbar>
                        <div className={'pl20 pr20 pt10 pb10 pb10'}>
                            <FormControl className={'wfill'}>
                                <div className='fdc wfill'>
                                    <div className='fdc wfill'>
                                        <FormTextInput
                                            className={'flx1'}
                                            label={'Password'}
                                            name={'password'}
                                            type={'password'}
                                        />
                                    </div>
                                    <div className='fdc wfill'>
                                        <FormTextInput
                                            className={'flx1'}
                                            label={'Confirm Password'}
                                            name={'confirmPassword'}
                                            type={'password'}
                                        />
                                    </div>
                                </div>
                            </FormControl>
                            <div className={'fdr p5 pt20'}>
                                <Button
                                    className={'flx1 br25'}
                                    variant='contained'
                                    color='secondary'
                                    onClick={submitPasswordRecoveryPasswordChange}
                                    disabled={isLoading || !changePasswordEnabled}
                                >
                                    CHANGE PASSWORD
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            }
        </div>
    </Screen>;

};

export default withFormik<IPasswordRecoveryProps, IPasswordRecoveryFormValues>({
    mapPropsToValues: props => props.initialFormValues,
    handleSubmit: () => null,
    enableReinitialize: true,
    validationSchema: PasswordRecoveryModelHelper.formSchema,
})(PasswordRecovery);

