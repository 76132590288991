import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IChemicalProductElementBridge } from '../../../../@types/model/masterData/chemicals/chemicalProductElement/chemicalProductElement';
import { IChemicalProductElementBridgeUpsert } from '../../../../@types/model/masterData/chemicals/chemicalProductElement/chemicalProductElementUpsert';

export default class ChemicalProductElementHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataChemicalProductElementBridgeGetList() : AxiosPromise<Array<IChemicalProductElementBridge>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ChemicalProductElement/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static chemicalProductElementBridgeUpsert = (chemicalProductElementUpsert : IChemicalProductElementBridgeUpsert) : AxiosPromise<IChemicalProductElementBridge> => {
        return axios.post(`${API_URL}/MasterData/ChemicalProductElement/Upsert`, chemicalProductElementUpsert);
    }

    public static chemicalProductElementBridgeDelete = (chemicalProductElementId : number) : AxiosPromise<IChemicalProductElementBridge> => {
        return axios.delete(`${API_URL}/MasterData/ChemicalProductElement/Delete`, {
            params: { chemicalProductElementId },
        });
    }
}