import { createReducer } from '@reduxjs/toolkit';
import { ISnackbarNotification } from '../../@types/redux';
import GeneralActions from '../../store/general/actions';

export interface IGeneralState {
    company : string;
    notifications : Array<ISnackbarNotification>;
    isNavDrawerOpen : boolean;
}

const initialState = {
    company: '',
    notifications: [],
    isNavDrawerOpen: false,
};

const generalReducer = createReducer<IGeneralState>(initialState, (builder) => {
    builder.addCase(GeneralActions.enqueueSnackbar, (state, action) => {
        state.notifications.push(action.payload);
    });
    builder.addCase(GeneralActions.setNavDrawer, (state, action) => {
        state.isNavDrawerOpen = action.payload;
    });
    builder.addCase(GeneralActions.removeSnackbar, (state, action) => {
        state.notifications.filter(notification => notification.key !== action.payload);
    });
});

export default generalReducer;
