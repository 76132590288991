import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ISystem } from '../../../@types/model/masterData/system/system';
import { ISystemUpsert } from '../../../@types/model/masterData/system/systemUpsert';

export default class SystemHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataSystemGetList() : AxiosPromise<Array<ISystem>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/System/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static systemUpsert = (systemUpsert : ISystemUpsert) : AxiosPromise<ISystem> => {
        return axios.post(`${API_URL}/MasterData/System/Upsert`, systemUpsert);
    }

    public static systemDelete = (systemId : number) : AxiosPromise<ISystem> => {
        return axios.delete(`${API_URL}/MasterData/System/Delete`, {
            params: { systemId },
        });
    }
}