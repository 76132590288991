import { createReducer } from '@reduxjs/toolkit';
import { ISeasonProgram } from '../../@types/model/seasonData/seasonProgram/seasonProgram';
import SeasonActions from './actions';

export interface ISeasonState {
    isLoading : boolean;
    seasonProgramData : Array<ISeasonProgram> | null;
}

const initialState = {
    isLoading: false,
    seasonProgramData: null,
};

const seasonReducer = createReducer<ISeasonState>(initialState, (builder) => {
    builder.addCase(SeasonActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(SeasonActions.setSeasonProgramData, (state, action) => {
        state.seasonProgramData = action.payload;
    });
    builder.addCase(SeasonActions.reset, () => {
        return initialState;
    });
});

export default seasonReducer;
