import { createReducer } from '@reduxjs/toolkit';
import { IPackmanIntakeSummary } from '../../@types/model/packman/PackmanIntakeSummary';
import PackmanActions from '../packman/actions';

export interface IPackmanState {
    isLoading : boolean;
    intakeSummaryData : Array<IPackmanIntakeSummary> | null;
}

const initialState = {
    isLoading: false,
    intakeSummaryData: null,
};

const freshServiceReducer = createReducer<IPackmanState>(initialState, (builder) => {
    builder.addCase(PackmanActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(PackmanActions.setIntakeSummariesData, (state, action) => {
        state.intakeSummaryData = action.payload;
    });
    builder.addCase(PackmanActions.reset, () => {
        return initialState;
    });
});

export default freshServiceReducer;