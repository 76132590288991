import { createAction } from '@reduxjs/toolkit';
import { IBlock } from '../../@types/model/masterData/block/block';
import { IBlockHomeView } from '../../@types/model/masterData/block/blockHomeView';
import { IActiveIngredient } from '../../@types/model/masterData/chemicals/activeIngredient/activeIngredient';
import { IChemicalCategory } from '../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalElement } from '../../@types/model/masterData/chemicals/chemicalElement/chemicalElement';
import { IChemicalFormulation } from '../../@types/model/masterData/chemicals/chemicalFormulation/chemicalFormulation';
import { IChemicalProduct } from '../../@types/model/masterData/chemicals/chemicalProduct/chemicalProduct';
import { IChemicalProductActiveIngredientBridge } from '../../@types/model/masterData/chemicals/chemicalProductActiveIngredient/chemicalProductActiveIngredient';
import { IChemicalProductElementBridge } from '../../@types/model/masterData/chemicals/chemicalProductElement/chemicalProductElement';
import { ISafetyPrecaution } from '../../@types/model/masterData/chemicals/safetyPrecaution/safetyPrecaution';
import { ISafetyPrecautionPpe } from '../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDivision } from '../../@types/model/masterData/division/division';
import { IField } from '../../@types/model/masterData/field/field';
import { IIrrigationCycle } from '../../@types/model/masterData/irrigationCycle/irrigationCycle';
import { ICommodityNurseryBridge } from '../../@types/model/masterData/nursery/commodityNurseryBridge';
import { INursery } from '../../@types/model/masterData/nursery/nursery';
import { IPlantsPerHectare } from '../../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import { IProductionUnit } from '../../@types/model/masterData/productionUnit/productionUnit';
import { IProject } from '../../@types/model/masterData/project/project';
import { IRootStock } from '../../@types/model/masterData/rootStock/rootStock';
import { ISeedTraySize } from '../../@types/model/masterData/seedTraySize/seedTraySize';
import { ISprayMethod } from '../../@types/model/masterData/sprayMethod/splayMethod';
import { ISubdivision } from '../../@types/model/masterData/subdivision/subdivision';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { withPayloadType } from '@zz2/zz2-ui';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';
import { IActivity } from '../../@types/model/masterData/activity/activity';
import { ISystem } from '../../@types/model/masterData/system/system';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IPriority } from '../../@types/model/masterData/priority/priority';

export default class DataActions {
    public static setIsMasterDataSyncRunning = createAction('DATA_SET_MASTER_DATA_SYNC_IS_LOADING', withPayloadType<boolean>());
    public static setIsLoading = createAction('DATA_SET_LOADING', withPayloadType<boolean>());
    public static setDivisionData = createAction('DATA_SET_DIVISION_LIST', withPayloadType<Array<IDivision> | null>());
    public static setSubdivisionData = createAction('DATA_SET_SUBDIVISION_LIST', withPayloadType<Array<ISubdivision> | null>());
    public static setDepartmentData = createAction('DATA_SET_DEPARTMENT_LIST', withPayloadType<Array<IDepartment> | null>());
    public static setProductionUnitData = createAction('DATA_SET_PRODUCTION_UNIT_LIST', withPayloadType<Array<IProductionUnit> | null>());
    public static setFieldData = createAction('DATA_SET_FIELD_LIST', withPayloadType<Array<IField> | null>());
    public static setBlockData = createAction('DATA_SET_BLOCK_LIST', withPayloadType<Array<IBlock> | null>());
    public static setCommodityData = createAction('DATA_SET_COMMODITY_LIST', withPayloadType<Array<ICommodity> | null>());
    public static setVarietyData = createAction('DATA_SET_VARIETY_LIST', withPayloadType<Array<IVariety> | null>());
    public static setProjectData = createAction('DATA_SET_PROJECT_LIST', withPayloadType<Array<IProject> | null>());
    public static setNurseryData = createAction('DATA_SET_NURSERY_LIST', withPayloadType<Array<INursery> | null>());
    public static setRootStockData = createAction('DATA_SET_ROOT_STOCK_LIST', withPayloadType<Array<IRootStock> | null>());
    public static setUnitOfMeasureData = createAction('DATA_SET_UNIT_OF_MEASURE_LIST', withPayloadType<Array<IUnitOfMeasure> | null>());
    public static setChemicalElementData = createAction('DATA_SET_CHEMICAL_ELEMENT_LIST', withPayloadType<Array<IChemicalElement> | null>());
    public static setChemicalCategoryData = createAction('DATA_SET_CHEMICAL_CATEGORY_LIST', withPayloadType<Array<IChemicalCategory> | null>());
    public static setChemicalFormulationData = createAction('DATA_SET_CHEMICAL_FORMULATION_LIST', withPayloadType<Array<IChemicalFormulation> | null>());
    public static setChemicalProductElementBridgeData = createAction('DATA_SET_CHEMICAL_PRODUCT_ELEMENT_BRIDGE_LIST', withPayloadType<Array<IChemicalProductElementBridge> | null>());
    public static setChemicalProductData = createAction('DATA_SET_CHEMICAL_PRODUCT_LIST', withPayloadType<Array<IChemicalProduct> | null>());
    public static setActiveIngredientData = createAction('DATA_SET_ACTIVE_INGREDIENT_LIST', withPayloadType<Array<IActiveIngredient> | null>());
    public static setChemicalProductActiveIngredientBridgeData = createAction('DATA_SET_CHEMICAL_PRODUCT_ACTIVE_INGREDIENT_BRIDGE_LIST', withPayloadType<Array<IChemicalProductActiveIngredientBridge> | null>());
    public static setSafetyPrecautionData = createAction('DATA_SET_SAFETY_PRECAUTION_LIST', withPayloadType<Array<ISafetyPrecaution> | null>());
    public static setSafetyPrecautionPpeData = createAction('DATA_SET_SAFETY_PRECAUTION_PPE_LIST', withPayloadType<Array<ISafetyPrecautionPpe> | null>());
    public static setIrrigationCycleData = createAction('DATA_SET_IRRIGATION_CYCLE_LIST', withPayloadType<Array<IIrrigationCycle> | null>());
    public static setCommodityNurseryBridgeData = createAction('DATA_SET_COMMODITY_NURSERY_BRIDGE_LIST', withPayloadType<Array<ICommodityNurseryBridge> | null>());
    public static setPlantsPerHectareData = createAction('DATA_SET_PLANTS_PER_HECTARE_LIST', withPayloadType<Array<IPlantsPerHectare> | null>());
    public static setSeedTraySizeData = createAction('DATA_SET_SEED_TRAY_SIZE_LIST', withPayloadType<Array<ISeedTraySize> | null>());
    public static setSprayMethodData = createAction('DATA_SET_SPRAY_METHOD_LIST', withPayloadType<Array<ISprayMethod> | null>());
    public static setActivityTypeData = createAction('DATA_SET_ACTIVITY_TYPE_LIST', withPayloadType<Array<IActivityType> | null>());
    public static setActivityData = createAction('DATA_SET_ACTIVITY_LIST', withPayloadType<Array<IActivity> | null>());
    public static setSystemData = createAction('DATA_SET_SYSTEM_LIST', withPayloadType<Array<ISystem> | null>());
    public static setPriorityData = createAction('DATA_SET_PRIORITY_LIST', withPayloadType<Array<IPriority> | null>());

    public static setBlockHomeViewData = createAction('SET_BLOCK_HOME_VIEW_DATA', withPayloadType<Array<IBlockHomeView> | null>());

    public static setSyncedMasterData = createAction('DATA_SET_SYNCED_MASTER_DATA', withPayloadType<IMasterDataSync>());

    public static setSelectedBlock = createAction('DATA_SET_SELECTED_BLOCK', withPayloadType<IBlock | null>());
    public static setSelectedBlockView = createAction('DATA_SET_SELECTED_BLOCK_VIEW', withPayloadType<IBlockHomeView | null>());
    public static setSelectedField = createAction('DATA_SET_SELECTED_FIELD', withPayloadType<IField | null>());
    public static setSelectedCommodity = createAction('DATA_SET_SELECTED_COMMODITY', withPayloadType<ICommodity | null>());
    public static setSelectedChemicalProduct = createAction('DATA_SET_SELECTED_CHEMICAL_PRODUCT', withPayloadType<IChemicalProduct | null>());

    public static setSelectedUserDivisionIds = createAction('DATA_SET_USER_SELECTED_DIVISION_IDS', withPayloadType<Array<number> | null>());
    public static setSelectedUserDepartmentIds = createAction('DATA_SET_USER_SELECTED_DEPARTMENT_IDS', withPayloadType<Array<number> | null>());
    public static reset = createAction('DATA_RESET');
}
