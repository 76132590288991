import { IOptionType } from '@zz2/zz2-ui';
import { IUser } from '../../@types/model/auth/user/user';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDivision } from '../../@types/model/masterData/division/division';
import { OptionType } from '../../@types/model/optionType';

export default class UserHelper {

    public static getUserDivisionHeading = (
        user : IUser | undefined, 
        userSelectedDivisions : Array<IOptionType>, 
        divisions : Array<IDivision> | null
    ) : string => {
        
        const selectedDivisions = userSelectedDivisions;

        const divisionOptions = (!divisions || !user || !user.divisionIds) 
            ? []
            : divisions.filter(div => div.isActive && (user.divisionIds?.some(divId => div.id === divId))).map(OptionType.fromDivision);

        const allDivisionCount = divisionOptions.length;
        const selectedDivisionCount = selectedDivisions.length;
        let availableDivisionCount = user?.divisionIds?.length ?? allDivisionCount;

        if (availableDivisionCount === 0) {
            availableDivisionCount = allDivisionCount;
        }
     
        switch (selectedDivisionCount) {
            case 0:
                return 'NO DIVISION SELECTED';
            case 1:
                return selectedDivisions[0].label;
            case availableDivisionCount:
                return 'ALL DIVISIONS';
            default:
                return 'MULTIPLE DIVISIONS';
        }
    };

    public static getUserDepartmentHeading = (
        user : IUser | undefined, 
        userSelectedDivisions : Array<IOptionType>, 
        userSelectedDepartments : Array<IOptionType>, 
        departments : Array<IDepartment> | null
    ) : string => {

        const selectedDivisionOptions = userSelectedDivisions;
        const selectedDepartmentOptions = userSelectedDepartments;

        const departmentOptions = (!departments || !user || !user.departmentIds) 
            ? []
            : departments.filter(dept => dept.isActive
                    && (user.departmentIds?.some(deptId => dept.id === deptId))
                    && selectedDivisionOptions.some(div => dept.subdivision?.divisionId === div.value))
                .map(OptionType.fromDepartment);

        const allDepartmentCount = departmentOptions.length;
        const selectedDepartmentCount = selectedDepartmentOptions.length;
        let availableDepartmentCount = user?.departmentIds?.length ?? allDepartmentCount;

        if (availableDepartmentCount === 0) {
            availableDepartmentCount = allDepartmentCount;
        }
    
        switch (selectedDepartmentCount) {
            case 0:
                return 'NO DEPARTMENT SELECTED';
            case 1:
                return selectedDepartmentOptions[0].label;
            case availableDepartmentCount:
                return 'ALL DEPARTMENTS';
            default:
                return 'MULTIPLE DEPARTMENTS';
        }
    };
}