import { history } from '../index';

const push = history.push;
const goBack = history.goBack;
const replace = history.replace;

function navBack() : void {
    return goBack();
}

function pushUrl(url : string, state : any = {}) : void {
    return push({
        pathname: url,
        state,
    });
}

function navReplace(pathname : string) : void {
    return replace(pathname);
}

const navActions = {
    navBack,
    pushUrl,
    navReplace
};

export default navActions;
