import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IProductionUnit } from '../../../@types/model/masterData/productionUnit/productionUnit';
import { IProductionUnitUpsert } from '../../../@types/model/masterData/productionUnit/productionUnitUpsert';

export default class ProductionUnitHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataProductionUnitGetList() : AxiosPromise<Array<IProductionUnit>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ProductionUnit/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static masterDataProductionUnitBySubdivisionGetList(subdivisionId : number) : AxiosPromise<Array<IProductionUnit>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ProductionUnit/GetListBySubdivisionId`, {
            params: { subdivisionId },
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static productionUnitUpsert = (productionUnitUpsert : IProductionUnitUpsert) : AxiosPromise<IProductionUnit> => {
        return axios.post(`${API_URL}/MasterData/ProductionUnit/Upsert`, productionUnitUpsert);
    }

    public static productionUnitDelete = (productionUnitId : number) : AxiosPromise<IProductionUnit> => {
        return axios.delete(`${API_URL}/MasterData/ProductionUnit/Delete`, {
            params: { productionUnitId },
        });
    }
}