import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ISprayMethod } from '../../../@types/model/masterData/sprayMethod/splayMethod';
import { ISprayMethodUpsert } from '../../../@types/model/masterData/sprayMethod/sprayMethodUpsert';

export default class SprayMethodHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataSprayMethodGetList() : AxiosPromise<Array<ISprayMethod>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/SprayMethod/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static sprayMethodUpsert = (sprayMethodUpsert : ISprayMethodUpsert) : AxiosPromise<ISprayMethod> => {
        return axios.post(`${API_URL}/MasterData/SprayMethod/Upsert`, sprayMethodUpsert);
    }

    public static sprayMethodDelete = (sprayMethodId : number) : AxiosPromise<ISprayMethod> => {
        return axios.delete(`${API_URL}/MasterData/SprayMethod/Delete`, {
            params: { sprayMethodId },
        });
    }
}