import { createAction } from '@reduxjs/toolkit';
import { IConfig } from '../../@types/model/qcMasterData/config/config';
import { IProcess } from '../../@types/model/qcMasterData/process/process';
import { IQcAction } from '../../@types/model/qcMasterData/qcAction/qcAction';
import { IQcSite } from '../../@types/model/qcMasterData/qcSites/qcSite';
import { IQualifier } from '../../@types/model/qcMasterData/qualifier/qualifier';
import { IQualifierType } from '../../@types/model/qcMasterData/qualifierType/qualifierType';
import { withPayloadType } from '@zz2/zz2-ui';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';

export default class QcMasterDataActions {
    public static setIsLoading = createAction('QC_SET_LOADING', withPayloadType<boolean>());
    public static setConfigData = createAction('QC_SET_CONFIG_DATA', withPayloadType<Array<IConfig> | null>());
    public static setProcessData = createAction('QC_SET_PROCESS_DATA', withPayloadType<Array<IProcess> | null>());
    public static setQualifierTypeData = createAction('QC_SET_QUALIFIER_TYPE_DATA', withPayloadType<Array<IQualifierType> | null>());
    public static setQualifierData = createAction('QC_SET_QUALIFIER_DATA', withPayloadType<Array<IQualifier> | null>());
    public static setQcSiteData = createAction('QC_SET_SITES_DATA', withPayloadType<Array<IQcSite> | null>());
    public static setQcActionData = createAction('QC_SET_ACTION_DATA', withPayloadType<Array<IQcAction> | null>());

    public static setSyncedQCMasterData = createAction('QC_SET_SYNCED_MASTER_DATA', withPayloadType<IMasterDataSync>());

    public static setSelectedConfig = createAction('QC_SET_SELECTED_CONFIG', withPayloadType<IConfig | null>());
    public static setSelectedProcess = createAction('QC_SET_SELECTED_PROCESS', withPayloadType<IProcess | null>());

    public static reset = createAction('QC_RESET');
}
