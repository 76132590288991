import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ISafetyPrecautionPpe } from '../../../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpe';
import { ISafetyPrecautionPpeUpsert } from '../../../../@types/model/masterData/chemicals/safetyPrecautionPpe/safetyPrecautionPpeUpsert';

export default class SafetyPrecautionPpeHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataSafetyPrecautionPpeGetList() : AxiosPromise<Array<ISafetyPrecautionPpe>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/SafetyPrecautionPpe/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static safetyPrecautionPpeUpsert = (safetyPrecautionPpeUpsert : ISafetyPrecautionPpeUpsert) : AxiosPromise<ISafetyPrecautionPpe> => {
        return axios.post(`${API_URL}/MasterData/SafetyPrecautionPpe/Upsert`, safetyPrecautionPpeUpsert);
    }

    public static safetyPrecautionPpeDelete = (safetyPrecautionPpeId : number) : AxiosPromise<ISafetyPrecautionPpe> => {
        return axios.delete(`${API_URL}/MasterData/SafetyPrecautionPpe/Delete`, {
            params: { safetyPrecautionPpeId },
        });
    }
}