import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { useLocation } from 'react-router-dom';
import { VERSION } from '../../version';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';
import TicketCreationPopup from '../component/TicketCreationPopup';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketFormValues';
import FreshServiceThunks from '../../store/freshService/thunk';
import { Screen, ProjectDialog } from '@zz2/zz2-ui';

const NoRightsScreen = () : JSX.Element => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(x => x.freshService.isLoading);
    const path = useLocation().pathname;
    const currentUser = useAppSelector(x => x.auth.session?.user);
    const [isSupportTicketOpen, setIsSupportTicketOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const onTicketSubmit = async (values : IFreshServiceTicketFormValues) : Promise<void> => {
        const upsertValues = FreshServiceTicketModelHelper.createUpsert(values);
        const res = await dispatch(FreshServiceThunks.createFreshServiceTicket(upsertValues)).unwrap();
        
        if (res) {
            closeSupportTicketDialog();
        }
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const openSupportTicketDialog = () : void => {
        setIsSupportTicketOpen(true);
    };

    const closeSupportTicketDialog = () : void => {
        setIsSupportTicketOpen(false);
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const getInitialFormValues = useMemo(() => {
        const regex = new RegExp('/', 'g');
        const formattedLocation = ('Farm Website' + (path === '/' ? '/Home' : path) + '/' + VERSION.version).replace(regex, ' - ');

        return FreshServiceTicketModelHelper.createFormValues(formattedLocation, currentUser);
    }, [currentUser, path]);

    return (
        <Screen>
            <div className={'fdc hfill jcc aic'}>
                <img src={`${ASSET_BASE}/assets/images/no_rights.svg`}></img>
                <p className={'cgray3 fs37'}>Sorry!</p>
                <p className={'cgray3 fs25'}>You do not have access to this page.</p>
                <a onClick={openSupportTicketDialog} className='cursorPointer ul cg3'>Click here to open a ticket.</a>
            </div>
            <ProjectDialog
                title={'ZZ2FreshService Support Ticket'}
                isLoadingCircular={isLoading}
                isOpen={isSupportTicketOpen}
                fullWidth
                maxWidth={'xs'}
                onClose={closeSupportTicketDialog}>
                <TicketCreationPopup initialValues={getInitialFormValues} onSubmit={onTicketSubmit} onClose={closeSupportTicketDialog}/>
            </ProjectDialog >
        </Screen>
    );
};

export default NoRightsScreen;
