import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import qs from 'qs';
import { INursery } from '../../../@types/model/masterData/nursery/nursery';
import { INurseryUpsert } from '../../../@types/model/masterData/nursery/nurseryUpsert';

export default class NurseryHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataNurseryGetList() : AxiosPromise<Array<INursery>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/Nursery/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static nurseryUpsert = (nurseryUpsert : INurseryUpsert, commodityIds : Array<number>) : AxiosPromise<INursery> => {
        return axios.post(`${API_URL}/MasterData/Nursery/Upsert`, nurseryUpsert, {
            params: { commodityIds },
            paramsSerializer: qs.stringify,
        });
    }

    public static nurseryDelete = (nurseryId : number) : AxiosPromise<INursery> => {
        return axios.delete(`${API_URL}/MasterData/Nursery/Delete`, {
            params: { nurseryId },
        });
    }
}