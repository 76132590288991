import { createAction } from '@reduxjs/toolkit';
import { ICrop } from '../../@types/model/cropData/crop/crop';
import { IPlannedCrop } from '../../@types/model/cropData/plannedCrop/plannedCrop';
import { withPayloadType } from '@zz2/zz2-ui';

export default class CropActions {
    public static setIsLoading = createAction('CROP_SET_LOADING', withPayloadType<boolean>());
    public static setPlannedCropData = createAction('PLANNED_CROP_SET_LIST', withPayloadType<Array<IPlannedCrop> | null>());
    public static setCropData = createAction('CROP_SET_LIST', withPayloadType<Array<ICrop> | null>());
    public static setSelectedCrop = createAction('CROP_SET_SELECTED_CROP', withPayloadType<ICrop | null>());
    public static reset = createAction('CROP_RESET');
}
