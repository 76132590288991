import { createReducer } from '@reduxjs/toolkit';
import { IRight } from '../../@types/model/auth/right/right';
import { IRole } from '../../@types/model/auth/role/role';
import { IUser } from '../../@types/model/auth/user/user';
import RightActions from './actions';

export interface IRightState {
    isLoading : boolean;
    userData : Array<IUser>;
    rightData : Array<IRight>;
    roleData : Array<IRole>;
}

const initialState = {
    isLoading: false,
    userData: [],
    rightData: [],
    roleData: [],
};

const rightReducer = createReducer<IRightState>(initialState, (builder) => {
    builder.addCase(RightActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(RightActions.setUserData, (state, action) => {
        state.userData = action.payload;
    });
    builder.addCase(RightActions.setRightData, (state, action) => {
        state.rightData = action.payload;
    });
    builder.addCase(RightActions.setRoleData, (state, action) => {
        state.roleData = action.payload;
    });
    builder.addCase(RightActions.reset, () => {
        return initialState;
    });
});

export default rightReducer;
