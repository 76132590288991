import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IPlantsPerHectare } from '../../../@types/model/masterData/plantsPerHectare/plantsPerHectare';
import { IPlantsPerHectareUpsert } from '../../../@types/model/masterData/plantsPerHectare/plantsPerHectareUpsert';

export default class PlantsPerHectareHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataPlantsPerHectareGetList() : AxiosPromise<Array<IPlantsPerHectare>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/PlantsPerHectare/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static plantsPerHectareUpsert = (plantsPerHectareUpsert : IPlantsPerHectareUpsert) : AxiosPromise<IPlantsPerHectare> => {
        return axios.post(`${API_URL}/MasterData/PlantsPerHectare/Upsert`, plantsPerHectareUpsert);
    }

    public static plantsPerHectareDelete = (plantsPerHectareId : number) : AxiosPromise<IPlantsPerHectare> => {
        return axios.delete(`${API_URL}/MasterData/PlantsPerHectare/Delete`, {
            params: { plantsPerHectareId },
        });
    }
}