import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IChemicalCategory } from '../../../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategory';
import { IChemicalCategoryUpsert } from '../../../../@types/model/masterData/chemicals/chemicalCategory/chemicalCategoryUpsert';

export default class ChemicalCategoryHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataChemicalCategoryGetList() : AxiosPromise<Array<IChemicalCategory>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ChemicalCategory/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static chemicalCategoryUpsert = (chemicalCategoryUpsert : IChemicalCategoryUpsert) : AxiosPromise<IChemicalCategory> => {
        return axios.post(`${API_URL}/MasterData/ChemicalCategory/Upsert`, chemicalCategoryUpsert);
    }

    public static chemicalCategoryDelete = (chemicalCategoryId : number) : AxiosPromise<IChemicalCategory> => {
        return axios.delete(`${API_URL}/MasterData/ChemicalCategory/Delete`, {
            params: { chemicalCategoryId },
        });
    }
}