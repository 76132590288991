import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IUnitOfMeasure } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IUnitOfMeasureUpsert } from '../../../@types/model/masterData/unitOfMeasure/unitOfMeasureUpsert';

export default class UnitOfMeasureHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataUnitOfMeasureGetList() : AxiosPromise<Array<IUnitOfMeasure>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/UnitOfMeasure/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static unitOfMeasureUpsert = (unitOfMeasureUpsert : IUnitOfMeasureUpsert) : AxiosPromise<IUnitOfMeasure> => {
        return axios.post(`${API_URL}/MasterData/UnitOfMeasure/Upsert`, unitOfMeasureUpsert);
    }

    public static unitOfMeasureDelete = (unitOfMeasureId : number) : AxiosPromise<IUnitOfMeasure> => {
        return axios.delete(`${API_URL}/MasterData/UnitOfMeasure/Delete`, {
            params: { unitOfMeasureId },
        });
    }
}