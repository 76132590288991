import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IChemicalProductActiveIngredientBridge } from '../../../../@types/model/masterData/chemicals/chemicalProductActiveIngredient/chemicalProductActiveIngredient';
import { IChemicalProductActiveIngredientBridgeUpsert } from '../../../../@types/model/masterData/chemicals/chemicalProductActiveIngredient/chemicalProductActiveIngredientUpsert';

export default class ChemicalProductActiveIngredientHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataChemicalProductActiveIngredientBridgeGetList() : AxiosPromise<Array<IChemicalProductActiveIngredientBridge>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/MasterData/ChemicalProductActiveIngredient/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static chemicalProductActiveIngredientBridgeUpsert = (chemicalProductActiveIngredientUpsert : IChemicalProductActiveIngredientBridgeUpsert) : AxiosPromise<IChemicalProductActiveIngredientBridge> => {
        return axios.post(`${API_URL}/MasterData/ChemicalProductActiveIngredient/Upsert`, chemicalProductActiveIngredientUpsert);
    }

    public static chemicalProductActiveIngredientBridgeDelete = (chemicalProductActiveIngredientId : number) : AxiosPromise<IChemicalProductActiveIngredientBridge> => {
        return axios.delete(`${API_URL}/MasterData/ChemicalProductActiveIngredient/Delete`, {
            params: { chemicalProductActiveIngredientId },
        });
    }
}