import React from 'react';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketFormValues';
import { Form, FormikBag, FormikProps, withFormik } from 'formik';
import { Button } from '@mui/material';
import { PillButton, FormTextInput } from '@zz2/zz2-ui';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';

interface ITicketCreationPopupProps {
    initialValues : IFreshServiceTicketFormValues;
    onClose : () => void;
    onSubmit : (values : IFreshServiceTicketFormValues) => Promise<void>;
}

type TicketCreationPopupPropsType = ITicketCreationPopupProps & FormikProps<IFreshServiceTicketFormValues>;

const TicketCreationPopup = (props : TicketCreationPopupPropsType) : React.ReactElement => {
    return (
        <Form>
            <div className={'fdc p10 pt15 pb10'}>
                <div className={'fdr wfill'}>
                    <FormTextInput disabled className={'flx1 mt10'} label={'Subject'} name={'subject'}></FormTextInput>
                </div>
                <div className={'fdr wfill'}>
                    <FormTextInput multiline rows={4} className={'flx1 mt10 mb10'} label={'Description'} name={'description'}></FormTextInput>
                </div>
                <div className={'fdr flx1 aic jcfe'}>
                    <Button className={'cpd mr20'} type={'reset'} onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <PillButton
                        className={'pl30 pr30'}
                        text={'SUBMIT'}
                        size={'small'}
                        type={'submit'}
                        color={'secondary'}/>
                </div>
            </div>
        </Form>
    );
};

const handleSubmit = (values : IFreshServiceTicketFormValues, formikBag : FormikBag<ITicketCreationPopupProps, IFreshServiceTicketFormValues>) : void => {
    formikBag.props.onSubmit(values);
};

export default withFormik<ITicketCreationPopupProps, IFreshServiceTicketFormValues>({
    mapPropsToValues: props => props.initialValues,
    handleSubmit,
    enableReinitialize: true,
    validationSchema: FreshServiceTicketModelHelper.formSchema,
})(TicketCreationPopup);
